import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class UnlockCheckIcon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon {...props} ref={forwardedRef}>
      <path d="M12 24c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12zm0-22c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zM11 17c-.264 0-.519-.104-.707-.293l-4-4c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l3.185 3.185 5.308-7.078c.331-.44.958-.531 1.399-.2.442.331.532.958.2 1.4l-6 8c-.174.232-.44.377-.729.397l-.07.003z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <UnlockCheckIcon {...props} forwardedRef={ref} />)