import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock7Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M92.188,5H7.812C6.258,5 5,6.258 5,7.812v84.375C5,93.742 6.258,95 7.812,95h84.375C93.742,95 95,93.742 95,92.188V7.812C95,6.258 93.742,5 92.188,5zM10.625,10.625h36.562v36.562h-2.812c-1.555,0 -2.812,1.258 -2.812,2.812s1.258,2.812 2.812,2.812h2.812v36.562H10.625V10.625zM89.375,89.375H52.812V52.812h2.812c1.555,0 2.812,-1.258 2.812,-2.812s-1.258,-2.812 -2.812,-2.812h-2.812V10.625h36.562V89.375z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock7Icon {...props} forwardedRef={ref} />)