import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock32Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 96 96'} {...props} ref={forwardedRef}>
      <path d="M86,4H10A1.9988,1.9988 0,0 0,8 6V90a1.9988,1.9988 0,0 0,2 2H86a1.9988,1.9988 0,0 0,2 -2V6A1.9988,1.9988 0,0 0,86 4ZM12,8H46V88H12ZM84,88H50V8H84Z" />
      <path d="M22,20H36a2,2 0,0 0,0 -4H22a2,2 0,0 0,0 4Z" />
      <path d="M38,26a1.9988,1.9988 0,0 0,-2 -2H22a2,2 0,0 0,0 4H36A1.9988,1.9988 0,0 0,38 26Z" />
      <path d="M38,48a1.9988,1.9988 0,0 0,-2 2v4a2,2 0,0 0,4 0V50A1.9988,1.9988 0,0 0,38 48Z" />
      <path d="M60,20H74a2,2 0,0 0,0 -4H60a2,2 0,0 0,0 4Z" />
      <path d="M76,26a1.9988,1.9988 0,0 0,-2 -2H60a2,2 0,0 0,0 4H74A1.9988,1.9988 0,0 0,76 26Z" />
      <path d="M74,50v4a2,2 0,0 0,4 0V50a2,2 0,0 0,-4 0Z" />
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock32Icon {...props} forwardedRef={ref} />)