import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock15Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M80,25H45v-5h12.5c1.381,0,2.5-1.119,2.5-2.5v-10C60,6.119,58.881,5,57.5,5h-15C41.119,5,40,6.119,40,7.5V25H20  c-8.271,0-15,8.972-15,20v27.5C5,73.881,6.119,75,7.5,75h25H40v17.5c0,1.381,1.119,2.5,2.5,2.5h15c1.381,0,2.5-1.119,2.5-2.5V75  h32.5c1.381,0,2.5-1.119,2.5-2.5V45C95,33.972,88.271,25,80,25z M45,10h10v5H45V10z M30,70H10V45c0-8.131,4.579-15,10-15  s10,6.869,10,15V70z M55,90H45V75h10V90z M90,70H35V45c0-5.975-1.986-11.332-5.111-15H40v17.5c0,1.381,1.119,2.5,2.5,2.5  s2.5-1.119,2.5-2.5V30h35c5.421,0,10,6.869,10,15V70z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock15Icon {...props} forwardedRef={ref} />)