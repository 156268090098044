import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import ClearIcon from '@material-ui/icons/Clear';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import UnlockCheckIcon from '../assets/images/svgIcons/UnlockCheck'
import Link from '@material-ui/core/Link';

import RemoteUnlockNode from './RemoteUnlockNode'
import RemoteUnlockLockDialog from './RemoteUnlockLockDialog'

import { WebSocketRequestIds, LockState, HubRequestStatus } from '../utils/enums';
import { useSnackbar } from 'notistack';
import { remoteUnlock, remoteLock, updateLockStatusConflict } from '../utils/api'
import { useStateValue } from '../utils/state';
import { getPropertyValue, businessHoursValidNow, getErrorFromResponse } from '../utils/tableData'
import * as Constants from '../utils/constants'
import { useGetAllKeysQuery } from '../services/reduxNkApi'

const drawerWidth = 400
const remoteSuccessStatusDelay = 3000

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  hubContainerWrapper: {
    width: drawerWidth,
    backgroundColor: "#f7f7f7"
  },
  hubTitleWrapper: {
    borderBottom: "1px solid",
    borderBottomColor: "lightgray",
    backgroundColor: "white",
    minHeight: 64,
    display: "flex",
    alignItems: "center"
  }
}));

const REQUEST_ID_HUB_REQUEST = 2

const anchor = "left"

const RemoteUnlock = ({classes, ...props}) => {
  classes = {
    ...classes,
    ...useStyles()
  };
  const [{ currentUser }, dispatchState] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [adminKeys, setAdminKeys] = React.useState(null);
  const [confirmUnlock, setConfirmUnlock] = React.useState(false);
  const [remoteUnlockState, setRemoteUnlockState] = React.useState(null);
  const [lockState, setLockState] = React.useState(null);
  const [isAtLeastOneLockOffline] = React.useState(false);
  const { data, error } = useGetAllKeysQuery()

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDrawer(open);
  };

  const handleRemoteLockConfirmation = (lock, confirmed, resumeSchedule) => {
    if (confirmed) {
      handleRemoteUnlock(lock, false, resumeSchedule)
    }
    setConfirmUnlock(false)
  }

  // const handleRemoteClick = (lock) => {
  //   setConfirmUnlock(lock)
  // }

  const correctLockState = React.useCallback((lockId, newState) => {
    const payload = {lockId, state: newState}
    updateLockStatusConflict(currentUser, payload)
    setLockState(prevState => {
      return { ...prevState, [lockId]: newState };
    })
  }, [currentUser])

  const handleRemoteStatusUpdate = React.useCallback((lockId, hubRequestStatus, message, icon) => {
    setRemoteUnlockState(prevState => {
      return { ...prevState, [lockId]: {
        text: message,
        icon: icon || CircularProgress
      }};
    })
    console.log(`message`, message, `hubRequestStatus`, hubRequestStatus, `lockState`, lockState[lockId])
    if (hubRequestStatus === HubRequestStatus.REMOTELOCK_LOCKED_ALREADY) {
      if (!lockState[lockId] || lockState[lockId] === LockState.UNLOCKED) {
        correctLockState(lockId, LockState.LOCKED)
      }
    }
    else if (hubRequestStatus === HubRequestStatus.REMOTELOCK_UNLOCKED_ALREADY) {
      if (!lockState[lockId] || lockState[lockId] === LockState.LOCKED) {
        correctLockState(lockId, LockState.UNLOCKED)
      }
    }
  }, [correctLockState, lockState])

  const callRemoteLockAction = (lock, resumeSchedule) => {
    const lockId = getPropertyValue(lock, "lockId")
    const payload = {lockId}
    let functionName
    const isUnlocked = lockState[lockId] === LockState.UNLOCKED
    if (isUnlocked) {
      functionName = remoteLock
    }
    else {
      functionName = remoteUnlock
      if (businessHoursValidNow(lock)) {
        payload.resumeSchedule = resumeSchedule
      }
    }
    ;(functionName)(currentUser, payload)
      .then((updatedKey) => {
        dispatchState({
          type: 'changeCurrentEvent',
          newCurrentEvent: {
            category: 'Remote',
            action: isUnlocked ? 'Lock' : 'Unlock',
            value: parseInt(lockId)
          }
        })
      })
      .catch(({ message }) => {
        setRemoteUnlockState(prevState => {
          return { ...prevState, [lockId]: null };
        })
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        })
      })
  }

  const handleWebSockets = React.useCallback((lockObjectIds, lockObjectIdToLockIdMap) => {
    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URI)
    
    ws.onopen = () => {
      ws.send(JSON.stringify({
        op: "connect",
        applicationId: process.env.REACT_APP_PARSE_APP_ID
      }))
    }
    ws.onmessage = evt => {
      const message = JSON.parse(evt.data)
      console.log('!!!!! LockState onmessage', message)
      if (message.op === "connected") {
        ws.send(JSON.stringify({
          op: "subscribe",
          requestId: WebSocketRequestIds.REQUEST_ID_LOCK_STATE,
          query: {
            className: "LockState",
            where: {
              lock: {
                "$in": lockObjectIds
              }
            }
          },
        }))
      }
      else if (message.op === "create" || message.op === "update") {
        const status = getPropertyValue(message, "object.status")
        if (status) {
          switch (status) {
            case LockState.LOCKED:
            case LockState.UNLOCKED:
            case LockState.UNREACHABLE:
            default:
              const lockObjectId = getPropertyValue(message, "object.lock.objectId")
              if (lockObjectId) {
                setLockState(prevState => {
                  return { ...prevState, [lockObjectIdToLockIdMap[lockObjectId]]: status };
                })
                // setTimeout(() => {
                //   setRemoteUnlockState(prevState => {
                //     return { ...prevState, [lockObjectIdToLockIdMap[lockObjectId]]: null };
                //   })
                // }, remoteSuccessStatusDelay);
              }
              break;
          }
        }
      }
    }
    ws.onclose = () => {
      console.log('!!!!! LockState disconnected')
    }
  }, [])

  const handleRemoteUnlock = (lock, isUnlock, resumeSchedule, prompt) => {
    if (prompt) {
      setConfirmUnlock(lock)
    }
    else {
      const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URI)
      const lockId = getPropertyValue(lock, "lockId")
      
      ws.onopen = () => {
        ws.send(JSON.stringify({
          op: "connect",
          applicationId: process.env.REACT_APP_PARSE_APP_ID
        }))
      }
      ws.onmessage = evt => {
        const message = JSON.parse(evt.data)
        console.log('!!!!! HubRequest onmessage', message)
        if (message.op === "connected") {
          ws.send(JSON.stringify({
            op: "subscribe",
            requestId: REQUEST_ID_HUB_REQUEST,
            query: {
              className: "HubRequest",
              where: {
                lockId: {
                  "$in": [
                    lockId
                  ]
                },
                initiatedBy: {
                  "$in": [{
                    __type: "Pointer",
                    className: "_User",
                    objectId: currentUser.objectId
                  }]
                }
              }
            },
          }))
        }
        else if (message.op === "subscribed") {
          setRemoteUnlockState(prevState => {
            return { ...prevState, [lockId]: {
              text: "Loading...",
              icon: CircularProgress
            }};
          })
          callRemoteLockAction(lock, resumeSchedule)
        }
        else if (message.op === "create" || message.op === "update") {
          const status = message.hasOwnProperty("object") && message.object.hasOwnProperty("status") && message.object.status
          if (status) {
            switch (status) {
              case HubRequestStatus.ERROR_INITIATING_SRV:
              case HubRequestStatus.ERROR_RECEIVING_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_AUTHING_WITH_SERVER_TIMEOUT_HUB:
              case HubRequestStatus.ERROR_AUTHING_WITH_SERVER_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_SEARCHING_TIMEOUT_HUB:
              case HubRequestStatus.ERROR_SEARCHING_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_CONNECTING_TIMEOUT_HUB:
              case HubRequestStatus.ERROR_CONNECTING_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_AUTHING_WITH_LOCK_TIMEOUT_HUB:
              case HubRequestStatus.ERROR_AUTHING_WITH_LOCK_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_UNLOCKING_TIMEOUT_HUB:
              case HubRequestStatus.ERROR_UNLOCKING_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_RELOCKING_TIMEOUT_HUB:
              case HubRequestStatus.ERROR_RELOCKING_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_RETURNING_TO_IDLE_TIMEOUT_HUB:
              case HubRequestStatus.ERROR_RETURNING_TO_IDLE_TIMEOUT_SRV:
              case HubRequestStatus.ERROR_REMOTELOCK_RECEIVING_TIMEOUT_SRV:
                handleRemoteStatusUpdate(lockId, status, `There was an error unlocking (Code: ${message.object.status})`, 'error')
                ws.close()
                break;
              case HubRequestStatus.IDLE:
              case HubRequestStatus.INITIATED: // successPath: step 1/3 (status 10)
              case HubRequestStatus.REMOTELOCK_INITIATED:
                handleRemoteStatusUpdate(lockId, status, "Contacting lock")
                break;
              case HubRequestStatus.RECEIVED:
              case HubRequestStatus.REMOTELOCK_RECEIVED:
                handleRemoteStatusUpdate(lockId, status, "authenticating_with_hub")
                break;
              case HubRequestStatus.AUTHD_BY_SERVER:
              case HubRequestStatus.REMOTELOCK_AUTHD_BY_SERVER:
                handleRemoteStatusUpdate(lockId, status, "looking_for_lock")
                break;
              case HubRequestStatus.LOCK_FOUND:
              case HubRequestStatus.REMOTELOCK_LOCK_FOUND:
                handleRemoteStatusUpdate(lockId, status, "connecting_to_lock")
                break;
              case HubRequestStatus.CONNECTED:
              case HubRequestStatus.REMOTELOCK_CONNECTED:
                handleRemoteStatusUpdate(lockId, status, "authenticating_with_lock")
                break;
              case HubRequestStatus.AUTHD_BY_LOCK:
              case HubRequestStatus.REMOTELOCK_AUTHD_BY_LOCK:
                handleRemoteStatusUpdate(lockId, status, "Unlocking")
                break;
              case HubRequestStatus.UNLOCKED: // successPath: step 2/3 (status 70)
              case HubRequestStatus.REMOTELOCK_UNLOCKED:
                handleRemoteStatusUpdate(lockId, status, resumeSchedule ? `Unlocked until ${businessHoursValidNow(lock).endTime}` : `Unlocked`, UnlockCheckIcon)
                break;
              case HubRequestStatus.REMOTELOCK_LOCKED: // successPath: step 2/3 (status 70)
                handleRemoteStatusUpdate(lockId, status, `Locked`, UnlockCheckIcon)
                break;
              case HubRequestStatus.RELOCKED:
              case HubRequestStatus.REMOTELOCK_UNLOCKED_ALREADY:
                handleRemoteStatusUpdate(lockId, status, `Unlocked already`, UnlockCheckIcon)
                break;
              case HubRequestStatus.REMOTELOCK_LOCKED_ALREADY:
                handleRemoteStatusUpdate(lockId, status, `Locked already`, UnlockCheckIcon)
                break;
              case HubRequestStatus.RETURNED_TO_IDLE:  // successPath: step 3/3 (status 90)
              case HubRequestStatus.REMOTELOCK_RETURNED_TO_IDLE:
              default:
                const delayTime = (isUnlock && !resumeSchedule) ? (getPropertyValue(lock, "unlockTime") * 1000) : remoteSuccessStatusDelay
                console.log(`delayTime for ${lockId}`, delayTime)
                setTimeout(() => {
                  setRemoteUnlockState(prevState => {
                    return { ...prevState, [lockId]: null };
                  })
                }, delayTime);
                ws.close()
                break;
            }
          }
        }
      }
      ws.onclose = () => {
        console.log('!!!!! HubRequest disconnected')
      }
    }
  }

  React.useEffect(() => {
    if (data) {
      console.log("TTT [RemoteUnlock] useEffect", data)
      const keysToShow = data.filter(key => ["User","GroupUser"].indexOf(key.accessRole) === -1).filter(key => key.canRemoteUnlock === true)
      // const isAtLeastOneLockOffline = keysToShow.some(key => key.offline === true)
      // setIsAtLeastOneLockOffline(isAtLeastOneLockOffline) // commented in order to hide legend
      setAdminKeys(keysToShow)
      // disable eslint on next next line; otherwise it complains about the comma between true & a
      // eslint-disable-next-line no-sequences
      const lockState = keysToShow.reduce((a,b)=> (a[b.lockId]=b.lockState,a),{})
      // disable eslint on next next line; otherwise it complains about the comma between true & a
      // eslint-disable-next-line no-sequences
      const remoteUnlockState = keysToShow.reduce((a,b)=> (a[b.lockId]=null,a),{})
      // disable eslint on next next line; otherwise it complains about the comma between true & a
      // eslint-disable-next-line no-sequences
      const lockObjectIdToLockIdMap = keysToShow.reduce((a,b)=> (a[b.lockObjectId]=b.lockId,a),{})
      // TODO do we know the lock status from fetchKeys?
      //  lockState = 1 || 2
      //  how about unlockDuration
      setLockState(lockState)
      setRemoteUnlockState(remoteUnlockState)
      const locksToSubscribeTo = Object.keys(lockObjectIdToLockIdMap).map(lockObjectId => {
        return {
          __type: "Pointer",
          className: "Lock",
          objectId: lockObjectId
        }
      })
      handleWebSockets(locksToSubscribeTo, lockObjectIdToLockIdMap)
    }
  }, [data, handleWebSockets]);

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(getErrorFromResponse(error), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
    }
  }, [enqueueSnackbar, error])

  return adminKeys && adminKeys.length >= 1 && <React.Fragment>
      {/* <Box>
        data: {data && <pre>{JSON.stringify(data, null, 2)}</pre>}<br />
      </Box> */}
      <ListItem classes={{ root: classes.listItemRoot }} button disableGutters key={"Support"} onClick={toggleDrawer(true)}>
        <ListItemIcon classes={{ root: classes.listItemIconRoot }}><SettingsRemoteIcon /></ListItemIcon>
        <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} className={classes.listItemText} primary={"Remote"} />
      </ListItem>
      <Drawer anchor={anchor} open={openDrawer} onClose={toggleDrawer(false)}>
      <Box
        className={classes.hubContainerWrapper}
        role="presentation"
        mb={isAtLeastOneLockOffline ? 7 : 0}
      >
        <Box px={2} className={classes.hubTitleWrapper}>
          <Typography variant="h6" className={classes.title}>
            Remote
          </Typography>
          <IconButton onClick={toggleDrawer(false)}>
            <ClearIcon color="primary" />
          </IconButton>
        </Box>
        {adminKeys && adminKeys.map((adminKey, index) => (
          <RemoteUnlockNode
            key={index}
            lockState={lockState} 
            remoteUnlockState={remoteUnlockState} 
            handleRemoteUnlock={handleRemoteUnlock}
            lock={adminKey} />
        ))}
        {isAtLeastOneLockOffline && <Box bgcolor="white" display="flex" width={drawerWidth} position="fixed" alignItems="flex-start" bottom={0} p={2}>
          <Box pr={1}>
            <CloudOffIcon color="secondary" fontSize="small" />
          </Box>
          <Box>
            Not connected to the internet.{' '}
            <Link
              href={Constants.remoteLockSupportArticle}
              target="_support">
                See how to connect.
            </Link>
          </Box>
        </Box>}
      </Box>
    </Drawer>
    <RemoteUnlockLockDialog
      lock={confirmUnlock}
      open={confirmUnlock !== false}
      loading={false}
      onClose={(confirmed, resumeSchedule) => handleRemoteLockConfirmation(confirmUnlock, confirmed, resumeSchedule)}
      acknowledge={"Lock"}
    />
  </React.Fragment>
};

export default RemoteUnlock;
