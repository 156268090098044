import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import nexkeyLogo from '../assets/images/logo.png';

function getSteps() {
  return ['Register', 'Verify Phone Number', 'Enter Email', 'Verify Email'];
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiStepLabel-label.MuiStepLabel-completed': {
      color: theme.palette.common.white,
    },
    '.MuiStepLabel-label.MuiStepLabel-active': {
      color: theme.palette.common.white,
    },
    '.MuiStepLabel-label': {
      color: theme.palette.common.white,
    },
    '.MuiStepIcon-root.MuiStepIcon-active': {
      color: theme.palette.primary.main,
    },
    '.MuiStepIcon-root': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function RegistrationStepper({activeStep, steps}) {
  useStyles()
  steps = steps || getSteps()

  return (
    <Box position="relative" zIndex="999999">
      <Grid container>
        <Grid item xs={1}>
          <Box pl={2} pt={1}>
            <Link href="/"><img id="logo" width="100%" src={nexkeyLogo} alt="" /></Link>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Stepper activeStep={activeStep} alternativeLabel style={{background:"none"}}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
      </Grid>
    </Box>
  )
}
