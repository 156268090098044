import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class GroupIcon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon {...props} ref={forwardedRef}>
      {/* solid: modified thicker (below) SVG via https://boxy-svg.com/ */}
      <path fillRule="nonzero" d="M6.0239768 10.5211746C5.86707474 10.9045212 5.42911666 11.0880908 5.04577006 10.9311887 4.66242346 10.7742866 4.47885389 10.3363285 4.63575596 9.95298194 4.87204771 9.3756687 5.17552578 8.82909497 5.53887404 8.32459761 6.14401255 7.483722 6.90774907 6.76858978 7.7847272 6.22276823 8.13639165 6.00389614 8.5989029 6.11154562 8.81777498 6.46321007 9.03664707 6.81487452 8.92899759 7.27738577 8.57733314 7.49625786 7.86685296 7.93845291 7.24736836 8.51851343 6.75621277 9.20100297 6.46149347 9.61021129 6.21548046 10.0532886 6.0239768 10.5211746zM12.9147725 19.3438663C13.3238155 19.2786234 13.7083003 19.5573286 13.7735432 19.9663716 13.8387861 20.3754147 13.560081 20.7598995 13.1510379 20.8251424 12.7473134 20.8895369 12.3359136 20.9219826 11.9195862 20.9219826 11.6167491 20.9219826 11.3160565 20.9047449 11.0185019 20.8705203 9.98656231 20.7520131 8.99823519 20.4303372 8.10065194 19.9280341 7.73918917 19.7257532 7.6101467 19.2687488 7.81242754 18.907286 8.01470837 18.5458232 8.47171286 18.4167807 8.83317564 18.6190616 9.55852236 19.0249782 10.3562047 19.2846039 11.1897686 19.3803298 11.43056 19.4080255 11.6740259 19.4219826 11.9195862 19.4219826 12.256978 19.4219826 12.5893756 19.3957676 12.9147725 19.3438663zM16.9424187 9.01306778C16.6884335 8.68586079 16.7477915 8.21471146 17.0749985 7.9607263 17.4022055 7.70674113 17.8733548 7.76609915 18.12734 8.09330614 18.5000327 8.57344306 18.8170529 9.09530313 19.0711777 9.64839622 19.5011598 10.5829332 19.7470091 11.5973091 19.7905689 12.6456594 19.807765 13.0595159 19.4862082 13.408953 19.0723518 13.4261491 18.6584953 13.4433451 18.3090582 13.1217884 18.2918621 12.7079319 18.256447 11.8556003 18.0570723 11.0329791 17.7083291 10.2750088 17.5020837 9.82612382 17.244869 9.402712 16.9424187 9.01306778z"/>
      <path fillRule="nonzero" d="M 16.299 5.792 C 16.299 8.299 14.281 10.334 11.789 10.334 C 9.297 10.334 7.28 8.299 7.28 5.792 C 7.28 3.285 9.297 1.25 11.789 1.25 C 14.281 1.25 16.299 3.285 16.299 5.792 Z M 22.738 16.38 C 22.738 18.887 20.721 20.922 18.229 20.922 C 15.737 20.922 13.719 18.887 13.719 16.38 C 13.719 13.873 15.737 11.838 18.229 11.838 C 20.721 11.838 22.738 13.873 22.738 16.38 Z M 10.269 16.643 C 10.269 19.15 8.251 21.185 5.759 21.185 C 3.267 21.185 1.25 19.15 1.25 16.643 C 1.25 14.136 3.267 12.101 5.759 12.101 C 8.251 12.101 10.269 14.136 10.269 16.643 Z"/>
      {/* thicker: https://thenounproject.com/icon/2088450/  */}
      {/* <path fill-rule="nonzero" d="M6.0239768 10.5211746C5.86707474 10.9045212 5.42911666 11.0880908 5.04577006 10.9311887 4.66242346 10.7742866 4.47885389 10.3363285 4.63575596 9.95298194 4.87204771 9.3756687 5.17552578 8.82909497 5.53887404 8.32459761 6.14401255 7.483722 6.90774907 6.76858978 7.7847272 6.22276823 8.13639165 6.00389614 8.5989029 6.11154562 8.81777498 6.46321007 9.03664707 6.81487452 8.92899759 7.27738577 8.57733314 7.49625786 7.86685296 7.93845291 7.24736836 8.51851343 6.75621277 9.20100297 6.46149347 9.61021129 6.21548046 10.0532886 6.0239768 10.5211746zM12.9147725 19.3438663C13.3238155 19.2786234 13.7083003 19.5573286 13.7735432 19.9663716 13.8387861 20.3754147 13.560081 20.7598995 13.1510379 20.8251424 12.7473134 20.8895369 12.3359136 20.9219826 11.9195862 20.9219826 11.6167491 20.9219826 11.3160565 20.9047449 11.0185019 20.8705203 9.98656231 20.7520131 8.99823519 20.4303372 8.10065194 19.9280341 7.73918917 19.7257532 7.6101467 19.2687488 7.81242754 18.907286 8.01470837 18.5458232 8.47171286 18.4167807 8.83317564 18.6190616 9.55852236 19.0249782 10.3562047 19.2846039 11.1897686 19.3803298 11.43056 19.4080255 11.6740259 19.4219826 11.9195862 19.4219826 12.256978 19.4219826 12.5893756 19.3957676 12.9147725 19.3438663zM16.9424187 9.01306778C16.6884335 8.68586079 16.7477915 8.21471146 17.0749985 7.9607263 17.4022055 7.70674113 17.8733548 7.76609915 18.12734 8.09330614 18.5000327 8.57344306 18.8170529 9.09530313 19.0711777 9.64839622 19.5011598 10.5829332 19.7470091 11.5973091 19.7905689 12.6456594 19.807765 13.0595159 19.4862082 13.408953 19.0723518 13.4261491 18.6584953 13.4433451 18.3090582 13.1217884 18.2918621 12.7079319 18.256447 11.8556003 18.0570723 11.0329791 17.7083291 10.2750088 17.5020837 9.82612382 17.244869 9.402712 16.9424187 9.01306778z"></path>
      <path fill-rule="nonzero" d="M14.7987112 5.79208696C14.7987112 4.11054954 13.4498905 2.75 11.7893147 2.75 10.1287388 2.75 8.7799181 4.11054954 8.7799181 5.79208696 8.7799181 7.47362437 10.1287388 8.83417391 11.7893147 8.83417391 13.4498905 8.83417391 14.7987112 7.47362437 14.7987112 5.79208696zM16.2987112 5.79208696C16.2987112 8.29915823 14.2812388 10.3341739 11.7893147 10.3341739 9.29739054 10.3341739 7.2799181 8.29915823 7.2799181 5.79208696 7.2799181 3.28501568 9.29739054 1.25 11.7893147 1.25 14.2812388 1.25 16.2987112 3.28501568 16.2987112 5.79208696zM21.238069 16.3798913C21.238069 14.6983539 19.8892483 13.3378043 18.2286724 13.3378043 16.5680965 13.3378043 15.2192759 14.6983539 15.2192759 16.3798913 15.2192759 18.0614287 16.5680965 19.4219783 18.2286724 19.4219783 19.8892483 19.4219783 21.238069 18.0614287 21.238069 16.3798913zM22.738069 16.3798913C22.738069 18.8869626 20.7205965 20.9219783 18.2286724 20.9219783 15.7367483 20.9219783 13.7192759 18.8869626 13.7192759 16.3798913 13.7192759 13.87282 15.7367483 11.8378043 18.2286724 11.8378043 20.7205965 11.8378043 22.738069 13.87282 22.738069 16.3798913zM8.7687931 16.642713C8.7687931 14.9611756 7.41997244 13.6006261 5.75939655 13.6006261 4.09882066 13.6006261 2.75 14.9611756 2.75 16.642713 2.75 18.3242505 4.09882066 19.6848 5.75939655 19.6848 7.41997244 19.6848 8.7687931 18.3242505 8.7687931 16.642713zM10.2687931 16.642713C10.2687931 19.1497843 8.25132066 21.1848 5.75939655 21.1848 3.26747244 21.1848 1.25 19.1497843 1.25 16.642713 1.25 14.1356418 3.26747244 12.1006261 5.75939655 12.1006261 8.25132066 12.1006261 10.2687931 14.1356418 10.2687931 16.642713z"></path> */}
      {/* thinner: https://thenounproject.com/icon/2088515/ */}
      {/* <path fill-rule="nonzero" d="M5.79260666 10.4264758C5.68800528 10.6820402 5.39603323 10.8044199 5.14046883 10.6998185 4.88490443 10.5952172 4.76252472 10.3032451 4.8671261 10.0476807 5.09595317 9.48860535 5.38985373 8.95928102 5.74179103 8.47062739 6.32790519 7.65618724 7.06759972 6.96356697 7.91682819 6.4350165 8.15127116 6.28910178 8.45961199 6.3608681 8.60552671 6.59531106 8.75144144 6.82975403 8.67967512 7.13809486 8.44523215 7.28400958 7.70700231 7.74347572 7.06347573 8.34604819 6.55335018 9.05489763 6.24716553 9.48002524 5.991575 9.94035195 5.79260666 10.4264758zM12.95415 19.5907457C13.2268454 19.5472504 13.4831686 19.7330538 13.5266638 20.0057492 13.5701591 20.2784446 13.3843557 20.5347678 13.1116603 20.578263 12.7209905 20.6405754 12.3227577 20.6719826 11.9195862 20.6719826 11.6262952 20.6719826 11.3351404 20.6552916 11.0470243 20.6221526 10.0481694 20.5074449 9.09161639 20.1961107 8.22273923 19.709872 7.98176404 19.5750181 7.89573573 19.2703484 8.03058962 19.0293733 8.16544351 18.7883981 8.47011317 18.7023698 8.71108836 18.8372237 9.46514116 19.2592047 10.2945976 19.5291721 11.1612021 19.6286923 11.4114761 19.6574787 11.6644798 19.6719826 11.9195862 19.6719826 12.2701339 19.6719826 12.6156985 19.6447291 12.95415 19.5907457zM17.1399055 8.85977417C16.9705821 8.64163618 17.0101541 8.32753663 17.2282921 8.15821318 17.4464301 7.98888974 17.7605296 8.02846175 17.9298531 8.24659974 18.2908387 8.71165455 18.5978914 9.21710658 18.8440639 9.75289169 19.2604785 10.6579408 19.4985821 11.6403577 19.5407845 12.6560382 19.5522485 12.9319425 19.3378773 13.1649005 19.061973 13.1763646 18.7860687 13.1878286 18.5531106 12.9734575 18.5416466 12.6975531 18.504874 11.8125518 18.2977536 10.9579714 17.9354981 10.1706334 17.7212453 9.70432037 17.4540629 9.26450051 17.1399055 8.85977417z"></path>
      <path fill-rule="nonzero" d="M15.0487112 5.79208696C15.0487112 3.97296056 13.5884486 2.5 11.7893147 2.5 9.99018073 2.5 8.5299181 3.97296056 8.5299181 5.79208696 8.5299181 7.61121335 9.99018073 9.08417391 11.7893147 9.08417391 13.5884486 9.08417391 15.0487112 7.61121335 15.0487112 5.79208696zM16.0487112 5.79208696C16.0487112 8.16156926 14.1426807 10.0841739 11.7893147 10.0841739 9.43594858 10.0841739 7.5299181 8.16156926 7.5299181 5.79208696 7.5299181 3.42260466 9.43594858 1.5 11.7893147 1.5 14.1426807 1.5 16.0487112 3.42260466 16.0487112 5.79208696zM21.488069 16.3798913C21.488069 14.5607649 20.0278063 13.0878043 18.2286724 13.0878043 16.4295385 13.0878043 14.9692759 14.5607649 14.9692759 16.3798913 14.9692759 18.1990177 16.4295385 19.6719783 18.2286724 19.6719783 20.0278063 19.6719783 21.488069 18.1990177 21.488069 16.3798913zM22.488069 16.3798913C22.488069 18.7493736 20.5820385 20.6719783 18.2286724 20.6719783 15.8753063 20.6719783 13.9692759 18.7493736 13.9692759 16.3798913 13.9692759 14.010409 15.8753063 12.0878043 18.2286724 12.0878043 20.5820385 12.0878043 22.488069 14.010409 22.488069 16.3798913zM9.0187931 16.642713C9.0187931 14.8235866 7.55853048 13.3506261 5.75939655 13.3506261 3.96026263 13.3506261 2.5 14.8235866 2.5 16.642713 2.5 18.4618394 3.96026263 19.9348 5.75939655 19.9348 7.55853048 19.9348 9.0187931 18.4618394 9.0187931 16.642713zM10.0187931 16.642713C10.0187931 19.0121953 8.11276263 20.9348 5.75939655 20.9348 3.40603048 20.9348 1.5 19.0121953 1.5 16.642713 1.5 14.2732307 3.40603048 12.3506261 5.75939655 12.3506261 8.11276263 12.3506261 10.0187931 14.2732307 10.0187931 16.642713z"></path> */}
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <GroupIcon {...props} forwardedRef={ref} />)