import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core'

export default function Loading ({ text = 'Loading', speed = 300, height }) {
  const [content, setContent] = React.useState(text)

  React.useEffect(() => {
    const id = window.setInterval(() => {
      setContent((content) => {
        return content === `${text}...`
          ? text
          : `${content}.`
      })
    }, speed)

    return () => window.clearInterval(id)
  }, [text, speed])

  return (
    <React.Fragment>
      <Box height={height} display="flex" flexWrap="wrap">
          <Box display="flex" flexBasis="100%" justifyContent="center" alignItems="flex-end">
            <CircularProgress size={60} />
          </Box>
          <Box display="flex" flexBasis="100%" justifyContent="center" alignItems="flex-start">
          <p>
            {content}
          </p>
          </Box>
      </Box>
    </React.Fragment>
  )
}

Loading.propTypes = {
  text: PropTypes.string,
  speed: PropTypes.number,
}