import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock26con extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="m50.094,4.002c0.387,0.013 0.742,0.088 1.031,0.188l40,16c1.11,0.449 1.887,1.616 1.875,2.813l0,54c-0,1.186 -0.775,2.336 -1.875,2.781l-40,16c-0.713,0.288 -1.537,0.288 -2.25,0l-40,-16c-1.1,-0.445 -1.875,-1.595 -1.875,-2.781l0,-54c-0.012,-1.197 0.765,-2.364 1.875,-2.813l40,-16c0.412,-0.15 0.832,-0.2 1.219,-0.188zM50,10.221 L18.125,22.971 30,27.752 61.906,14.971 50,10.221zM70,18.221 L38.125,30.971 50,35.752 81.875,22.971 70,18.221zM87,27.44 L53,41.033 53,88.565 87,74.94 87,27.44zM13,27.44 L13,74.94 47,88.565 47,41.033 33,35.44 33,47.002a3,3 0,1 1,-6 0l0,-13.969 -14,-5.594z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock26con {...props} forwardedRef={ref} />)