import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock17Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M44,29.3c-12.7,0 -23,10.3 -23,23s10.3,23 23,23s23,-10.3 23,-23S56.7,29.3 44,29.3zM44,71.3c-10.5,0 -19,-8.5 -19,-19s8.5,-19 19,-19s19,8.5 19,19S54.5,71.3 44,71.3z"></path>
      <path d="M44,42.3c-5.5,0 -10,4.5 -10,10s4.5,10 10,10s10,-4.5 10,-10S49.5,42.3 44,42.3zM44,58.3c-3.3,0 -6,-2.7 -6,-6s2.7,-6 6,-6s6,2.7 6,6S47.3,58.3 44,58.3z"></path>
      <path d="M79,39.3h-6c-1.1,0 -2,0.9 -2,2s0.9,2 2,2h6c1.1,0 2,-0.9 2,-2S80.1,39.3 79,39.3z"></path>
      <path d="M78,23.3H61.1L60,20.2c-1.2,-5 -5.6,-8.4 -10.7,-8.4H37.7c-5.1,0 -9.5,3.5 -10.7,8.4l-1.1,3.1H22c-7.7,0 -14,6.3 -14,14v34c0,7.7 6.3,14 14,14h56c7.7,0 14,-6.3 14,-14v-34C92,29.5 85.7,23.3 78,23.3zM88,71.3c0,5.5 -4.5,10 -10,10H22c-5.5,0 -10,-4.5 -10,-10v-34c0,-5.5 4.5,-10 10,-10h5.5c1.1,0 2,-0.9 2,-2v-0.2l1.3,-3.7c0,-0.1 0,-0.1 0.1,-0.2c0.7,-3.2 3.5,-5.4 6.8,-5.4h11.7c3.3,0 6.1,2.2 6.8,5.4c0,0.1 0,0.1 0.1,0.2l1.3,3.7v0.2c0,1.1 0.9,2 2,2H78c5.5,0 10,4.5 10,10V71.3z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock17Icon {...props} forwardedRef={ref} />)