import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock30Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M85.2,78.5h-3.9l-3.9,-52c1.1,0 1.9,-0.9 1.9,-2v-5c0,-1.1 -0.9,-2 -2,-2H64.8c-1.1,0 -2,0.9 -2,2v5c0,1.1 0.9,1.9 1.9,2l-0.9,12.4l-0.5,-1.2c-0.3,-0.7 -1,-1.2 -1.8,-1.2H23.8c-0.8,0 -1.5,0.5 -1.8,1.2l-3,7c-0.1,0.3 -0.2,0.5 -0.2,0.8h-1c-1.1,0 -2,0.9 -2,2v5c0,1.1 0.9,2 2,2h1v24h-4c-1.1,0 -2,0.9 -2,2s0.9,2 2,2h70.5c1.1,0 2,-0.9 2,-2S86.4,78.5 85.2,78.5zM19.8,50.5v-1h45.7v1H19.8zM67.4,54.5c1.1,0 2,-0.9 2,-2v-5c0,-1.1 -0.9,-2 -2,-2h-0.1l1.4,-19h4.8L76,60.7h-9.6v-6.2H67.4zM66.4,64.7h9.9l0.3,4H66.4V64.7zM66.4,72.7h10.5l0.4,5.8H66.4V72.7zM66.8,21.5h8.7v1h-8.7V21.5zM25.1,40.5h35l2.1,5H23L25.1,40.5zM22.8,54.5h39.7v24h-13v-13c0,-1.1 -0.9,-2 -2,-2h-9.7c-1.1,0 -2,0.9 -2,2v13h-13V54.5zM45.4,78.5h-5.7v-11h5.7V78.5z"></path>
      <path d="M25.3,57h7.8v4h-7.8z"></path>
      <path d="M25.3,64h7.8v4h-7.8z"></path>
      <path d="M25.3,71h7.8v4h-7.8z"></path>
      <path d="M52.3,57h7.8v4h-7.8z"></path>
      <path d="M52.3,64h7.8v4h-7.8z"></path>
      <path d="M52.3,71h7.8v4h-7.8z"></path>
      <path d="M38.3,57h7.8v4h-7.8z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock30Icon {...props} forwardedRef={ref} />)