import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PeopleIcon from '@material-ui/icons/People';
import MessageIcon from '@material-ui/icons/Message';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import HelpIcon from '@material-ui/icons/Help';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import GroupIcon from '../assets/images/svgIcons/GroupIcon'

// import { useStateValue } from '../utils/state';

const useStyles = makeStyles(theme => ({
  // paper: {
  //   display: 'flex',
  //   width: '100%',
  //   height: '100vh',
  // },
  // grid: {
  //   height: 'auto',
  // },
  // title: {
  //   flex: '0 0 auto',
  //   paddingRight: '40px'
  // },
  listItemText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    // WebkitFontSmoothing: 'antialiased' // auto | none | antialiased | subpixel-antialiased
  },
  listItemRoot: {
    textAlign: 'center',
    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected .MuiListItemIcon-root': {
      background: '#00AAB4'
    },
    '&.MuiButtonBase-root': {
      display: 'block',
    }
  },
  listItemIconRoot: {
    minWidth: 'auto',
    color: 'white',
    // WebkitFontSmoothing: 'antialiased' // auto | none | antialiased | subpixel-antialiased
  }
}));

export default function TestNav () {
  const classes = useStyles()
  // const [{ currentUser, currentFeatures }, dispatchState] = useStateValue();
  const [activeItem, setActiveItem] = React.useState([false,false,false,false,false,false]);

  const navigationItems = []
  navigationItems.push({
    label: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard'
  })
  navigationItems.push({
    label: 'Keys',
    icon: <VpnKeyIcon />,
    path: '/keys'
  })
  navigationItems.push({
    label: 'People',
    icon: <PeopleIcon />,
    path: '/people'
  })
  navigationItems.push({
    label: 'Notifications',
    icon: <NotificationsIcon />,
    path: '/notifications'
  })
  navigationItems.push({
    label: 'Messaging',
    icon: <MessageIcon />,
    path: '/messaging'
  })
  navigationItems.push({
    label: 'Settings',
    icon: <SettingsIcon />,
    path: '/settings'
  })

  const handleListItemClick = (activeItemIndex, event, index) => {
    setActiveItem(prevState => {
      const activeItemsNew = [...prevState]
      activeItemsNew[activeItemIndex] = navigationItems[index].label
      return activeItemsNew
    })
    // const firstPathname = `/${window.location.pathname.split("/")[1]}`
    // let link = navigationItems[index].path
    // let _history = localStorage.getItem(localeStorageHistoryKey)
    // if (_history) {
    //   _history = JSON.parse(_history)
    //   if (firstPathname !== link && _history.hasOwnProperty(link)) {
    //     link = _history[link]
    //   }
    // }
    // history.push(link);
  }

  function randomNumber(min, max) {  
    // return Math.random() * (max - min) + min;
    return Math.floor(Math.random() * (max - min) + min);
  }

  const currentRandomNumber = randomNumber(100, 1500)
  
  return (
    // <p>ok</p>
    <Grid container spacing={0}>
      <Grid item style={{color: 'white'}} xs={1}>
        <div style={{position: 'fixed', width: '8.33%', background: 'black', height: '200vh'}}>
          <List>
            {navigationItems.map((navigationItem, index) => (
              <ListItem 
                classes={{ root: classes.listItemRoot }} 
                button 
                disableGutters 
                selected={activeItem[0] === navigationItem.label} 
                onClick={event => handleListItemClick(0, event, index)}
                key={navigationItem.label} 
                >
                <ListItemIcon 
                  classes={{ root: classes.listItemIconRoot }}
                  >{navigationItem.icon}</ListItemIcon>
                <ListItemText 
                  classes={{ primary:classes.listItemText }} 
                  primary={navigationItem.label}
                  style={{ WebkitFontSmoothing: "subpixel-antialiased" }}
                   />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item style={{color: 'white'}} xs={1}>
        <div style={{position: 'fixed', width: '8.33%', background: 'black', height: '200vh'}}>
          <List>
            {navigationItems.map((navigationItem, index) => (
              <ListItem 
                classes={{ root: classes.listItemRoot }} 
                button 
                disableGutters 
                selected={activeItem[1] === navigationItem.label} 
                onClick={event => handleListItemClick(1, event, index)}
                key={navigationItem.label} 
                >
                <ListItemIcon 
                  classes={{ root: classes.listItemIconRoot }}
                  >{navigationItem.icon}</ListItemIcon>
                <ListItemText 
                  classes={{ primary:classes.listItemText }} 
                  primary={navigationItem.label}
                  style={{ WebkitFontSmoothing: "antialiased" }}
                  />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item style={{color: 'white'}} xs={1}>
        <div style={{position: 'fixed', width: '8.33%', background: 'black', height: '200vh'}}>
          <List>
            {navigationItems.map((navigationItem, index) => (
              <ListItem 
                classes={{ root: classes.listItemRoot }} 
                button 
                disableGutters 
                selected={activeItem[2] === navigationItem.label} 
                onClick={event => handleListItemClick(2, event, index)}
                key={navigationItem.label} 
                >
                <ListItemIcon 
                  classes={{ root: classes.listItemIconRoot }}
                  >{navigationItem.icon}</ListItemIcon>
                <ListItemText 
                  classes={{ primary:classes.listItemText }} 
                  primary={navigationItem.label}
                  style={{ WebkitFontSmoothing: "none" }}
                  />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item style={{color: 'white'}} xs={1}>
        <div style={{background: 'black', height: '100vh'}}>
          <List>
            {navigationItems.map((navigationItem, index) => (
              <ListItem 
                classes={{ root: classes.listItemRoot }} 
                button 
                disableGutters 
                selected={activeItem[3] === navigationItem.label} 
                onClick={event => handleListItemClick(3, event, index)}
                key={navigationItem.label} 
                >
                <ListItemIcon 
                  classes={{ root: classes.listItemIconRoot }}
                  >{navigationItem.icon}</ListItemIcon>
                <ListItemText 
                  classes={{ primary:classes.listItemText }} 
                  primary={navigationItem.label}
                  style={{ WebkitFontSmoothing: "subpixel-antialiased" }}
                   />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item style={{color: 'white'}} xs={1}>
        <div style={{background: 'black', height: '100vh'}}>
          <List>
            {navigationItems.map((navigationItem, index) => (
              <ListItem 
                classes={{ root: classes.listItemRoot }} 
                button 
                disableGutters 
                selected={activeItem[4] === navigationItem.label} 
                onClick={event => handleListItemClick(4, event, index)}
                key={navigationItem.label} 
                >
                <ListItemIcon 
                  classes={{ root: classes.listItemIconRoot }}
                  >{navigationItem.icon}</ListItemIcon>
                <ListItemText 
                  classes={{ primary:classes.listItemText }} 
                  primary={navigationItem.label}
                  style={{ WebkitFontSmoothing: "antialiased" }}
                  />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item style={{color: 'white'}} xs={1}>
        <div style={{background: 'black', height: '100vh'}}>
          <List>
            {navigationItems.map((navigationItem, index) => (
              <ListItem 
                classes={{ root: classes.listItemRoot }} 
                button 
                disableGutters 
                selected={activeItem[5] === navigationItem.label} 
                onClick={event => handleListItemClick(5, event, index)}
                key={navigationItem.label} 
                >
                <ListItemIcon 
                  classes={{ root: classes.listItemIconRoot }}
                  >{navigationItem.icon}</ListItemIcon>
                <ListItemText 
                  classes={{ primary:classes.listItemText }} 
                  primary={navigationItem.label}
                  style={{ WebkitFontSmoothing: "none" }}
                  />
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div style={{background: 'blue'}}>
          {currentRandomNumber}<br />
          {Array(currentRandomNumber).fill(1).map((val, index) => {
            return <span key={index}>text{index} </span>
          })}
        </div>
      </Grid>
    </Grid>
  )
}