import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock6Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 32 32'} {...props} ref={forwardedRef}>
      <path d="M23,11.69h0a6.39,6.39 0,0 0,-3.54 -5.14L19.46,4.67h0.87L20.33,0.81L11.74,0.81L11.74,4.67L12.6,4.67L12.6,6.55a6.39,6.39 0,0 0,-3.54 5.14v2.69L9,14.38v1h0l0,8L9,23.38v1L9,24.38L9,27.7a3.63,3.63 0,0 0,3.75 3.49h6.45A3.63,3.63 0,0 0,23 27.7L23,12.16ZM12.74,1.81h6.55L19.29,3.67L12.74,3.67L12.74,1.81ZM10,12.16a5.38,5.38 0,0 1,3.19 -4.81l0.37,-0.19L13.56,4.67h4.81L18.37,6.86l0,0.34 0.29,0.13A5.38,5.38 0,0 1,22 12.16v2.21L10,14.37L10,12.16ZM10,15.37L22,15.37v8L10,23.37v-8ZM19.19,30.18L12.78,30.18A2.63,2.63 0,0 1,10 27.7L10,24.38L22,24.38L22,27.7A2.63,2.63 0,0 1,19.24 30.19Z"></path>
      <path d="M18.63,18.27l-1.93,0l0,-1.94l-1.38,0l0,1.94l-1.93,0l0,1.38l1.93,0l0,1.93l1.38,0l0,-1.93l1.93,0l0,-1.38z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock6Icon {...props} forwardedRef={ref} />)