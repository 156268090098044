import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom'
import { withTracker } from '../utils/withTracker'

import { useSnackbar } from 'notistack';
import { fetchLatestTermsOfService, acceptTermsOfService, deactivateUser } from '../utils/api'
import { useStateValue } from '../utils/state';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '50vh',
    },
  textField: {
    margin: theme.spacing(2),
    width: '200px',
  },
}));

function Terms(props) {
  const classes = useStyles()
  const [{ currentUser }, dispatchState] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();
  const defaultInputs = {
    _openConfirmDecline: false,
    _terms: null
  }

  const [inputs, setInputs] = React.useState(defaultInputs);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true)
    acceptTermsOfService(inputs._terms.version)
    .then((user) => {
      // success, update acceptedTosVersion for currentUser
      dispatchState({
        type: 'changeCurrentUser',
        newCurrentUser: {...currentUser, acceptedTosVersion: user.acceptedTosVersion}
      })
      enqueueSnackbar('Terms accepted.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
      setInputs(defaultInputs)
      setLoading(false)
      props.history.push('/')
    })
    .catch(({ message }) => {
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
      setLoading(false)
    })
  }

  const handleDecline = () => {
    setInputs(inputs => ({ 
      ...inputs,
      _openConfirmDecline: true
    }));
  }

  const handleClose = () => {
    setInputs(inputs => ({ 
      ...inputs,
      _openConfirmDecline: false
    }));
  }

  const handleConfirmDecline = () => {
    setLoading(true)
    setInputs(inputs => ({ 
      ...inputs,
      _openConfirmDecline: false
    }));
    deactivateUser(0)
    .then((user) => {
      enqueueSnackbar('Account deleted.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
      setInputs(defaultInputs)
      setLoading(false)
      dispatchState({
          type: 'changeCurrentUser',
          newCurrentUser: null
      })
      props.history.push('/')
    })
    .catch(({ message }) => {
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
      setLoading(false)
    })
  }

  React.useEffect(() => {
    fetchLatestTermsOfService()
      .then((terms) => {
          setInputs(inputs => ({ 
            ...inputs,
            _terms: terms
          }));
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        })
      })
  }, [enqueueSnackbar]);

  return (
    <React.Fragment>
      <Dialog open={true} fullWidth={true} maxWidth={'sm'} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Review Updated Terms</DialogTitle>
        <DialogContent>
          <Box className={classes.box}>
            {inputs._terms && <iframe width="100%" height="100%" src={inputs._terms.url} title="Terms of Use" sandbox="allow-same-origin allow-popups"></iframe>}
            {!inputs._terms && <p>Loading...</p>}
          </Box>
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress size={20} />}
          <Button 
            disabled={loading
              || (!inputs._terms) 
            }
            onClick={handleDecline} 
            color="primary">
            I do not accept these terms, delete my account
          </Button>
          <Button 
            disabled={loading
              || (!inputs._terms) 
            }
            onClick={handleSubmit} 
            variant="contained"
            color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={inputs._openConfirmDecline} maxWidth={'sm'} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm Decline</DialogTitle>
        <DialogContent>
          <p>
            Deleting your account will erase you from the Nexkey system. 
            All of your keys will be lost and you will not be able to recover your account. 
            Are you sure you want to do this?
          </p>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDecline} 
            color="primary">
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withRouter(withTracker(Terms))
