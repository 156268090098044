import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock1Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M97.9,38.33 L51.4,6.89a2.5,2.5 0,0 0,-2.8 0L2.1,38.33a2.5,2.5 0,1 0,2.8 4.14l5.6,-3.79L10.5,88.54L4.75,88.54a2.5,2.5 0,0 0,0 5h90.5a2.5,2.5 0,0 0,0 -5L89.5,88.54L89.5,38.69l5.6,3.79a2.5,2.5 0,1 0,2.8 -4.14ZM72.75,88.54L27.25,88.54L27.25,45.3a1.42,1.42 0,0 1,1.42 -1.42L71.33,43.88a1.42,1.42 0,0 1,1.42 1.42ZM84.5,88.54L77.75,88.54L77.75,45.3a6.42,6.42 0,0 0,-6.42 -6.42L28.67,38.88a6.42,6.42 0,0 0,-6.42 6.42L22.25,88.54L15.5,88.54L15.5,35.31L50,12 84.5,35.31Z"></path>
      <path d="M64.29,64H35.71a2.5,2.5 0,0 0,0 5H64.29a2.5,2.5 0,0 0,0 -5Z"></path>
      <path d="M64.29,51.67H35.71a2.5,2.5 0,0 0,0 5H64.29a2.5,2.5 0,0 0,0 -5Z"></path>
      <path d="M64.29,76.42H35.71a2.5,2.5 0,0 0,0 5H64.29a2.5,2.5 0,0 0,0 -5Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock1Icon {...props} forwardedRef={ref} />)