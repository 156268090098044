import React from 'react';
import Box from '@material-ui/core/Box';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useStateValue } from '../utils/state';
import { me, forgotPassword } from '../utils/api'
import { getPropertyValue } from '../utils/tableData'
import Cookies from "js-cookie";

import LegacyLogin from './auth/LegacyLogin'
import Login from './auth/Login'
import Mfa from './auth/Mfa'
import SignUp from './auth/SignUp'
import ForgotPassword from './auth/ForgotPassword'
import ResetPassword from './auth/ResetPassword'
import ChoosePassword from './ChoosePassword';
import ForceResetPrompt from './auth/ForceResetPrompt';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_POOL_REGION,
    userPoolId: process.env.REACT_APP_AUTH_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH' // USER_PASSWORD_AUTH / USER_SRP_AUTH / CUSTOM_AUTH
    // authenticationFlowType: 'USER_PASSWORD_AUTH' // USER_PASSWORD_AUTH / USER_SRP_AUTH / CUSTOM_AUTH
  }
});

function AuthWrapperCustom({ children }) {
  // const classes = useStyles();
  const [{ currentUser, currentConfig }, dispatchState] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthChecked, setIsAuthChecked] = React.useState(false);
  const [activeAuthComponent, setActiveAuthComponent] = React.useState(null);
  const [reset, setReset] = React.useState({
    open: false,
    content: null
  })
  const cognitoUser = React.useRef(false);
  // const [cognitoUser, setCognitoUser] = React.useState(null);

  const logInUser = React.useCallback((newPassword) => {
    me(newPassword).then(async(user) => {
      dispatchState({
        type: 'changeCurrentUser',
        newCurrentUser: user
      })
    })
    .catch(({ message }) => {
      enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
    })
    .finally(() => {
      setIsAuthChecked(true)
    })
  }, [dispatchState, enqueueSnackbar])
  
  React.useEffect(() => {
    const legacySessionToken = Cookies.get("sessionToken");
    if (typeof legacySessionToken !== 'undefined') {
        // store in sessionStorage for temporary, quick retrieval
      sessionStorage.setItem("legacySessionToken", legacySessionToken);
      logInUser()
    }
    else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          logInUser()
        })
        .catch((error) => {
          cognitoUser.current = null
          setActiveAuthComponent({
            component: "login"
          })
          setIsAuthChecked(true)
        })
    }
  }, [logInUser])

  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('This will run every second!');
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  const isAuthenticated = Boolean(cognitoUser) && Boolean(currentUser) && activeAuthComponent === null
  const useLegacyLogin = Boolean(currentConfig && currentConfig.useLegacyLogin)

  const handleMfaFailure = () => {
    cognitoUser.current = null
    setActiveAuthComponent({
      component: "login"
    })
    enqueueSnackbar(`Your session has expired.  Please login again.`, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    })
  }

  const handleLegacyLogin = () => {
    setActiveAuthComponent(null)
    logInUser()
  }

  const handleLogin = (user, success, newPassword) => {
    cognitoUser.current = user
    // setCognitoUser(user)
    // if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
    const challengeName = getPropertyValue(user, "challengeName")
    if (!success && challengeName) {
      if (challengeName === 'SMS_MFA') {
        const codeSentTo = getPropertyValue(user, "challengeParam.CODE_DELIVERY_DESTINATION")
        setActiveAuthComponent({
          component: "mfa_sms",
          message: codeSentTo && `A code has been sent to ${codeSentTo}.`,
          newPassword
        })
      }
    }
    else {
      setActiveAuthComponent(null)
      logInUser(newPassword)
    }
  }

  const handleClick = (component) => {
    cognitoUser.current = null
    // setCognitoUser(null)
    setActiveAuthComponent({
      component
    })
  }

  const handleForgotPassword = (email, user, required) => {
    const codeSentTo = getPropertyValue(user, "CodeDeliveryDetails.Destination")
    const message = required ? `Instructions have been sent to ${codeSentTo}` : 
      `A password reset link has been sent to ${email}`
    if (required) {
      handleOpenForceResetPrompt(message)
    }
    else {
      enqueueSnackbar(message, {
        variant: 'success',
        autoHideDuration: 20000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      })
      setActiveAuthComponent({
        component: "login"
      })
    }
  }

  const handlePasswordReset = async(username, newPassword) => {
    // auto-login (which, upon success will lead to MFA)
    await Auth.signIn(username, newPassword)
      .then((user) => {
        console.log("Auth.signIn user", JSON.stringify(user, null, 2))
        // if mfa_enabled, then the below prompts a MFA
        handleLogin(user, null, newPassword)
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      })
  }

  const handlePasswordResetRequired = async(username) => {
    await forgotPassword(username)
      .then((user) => {
        handleForgotPassword(username, user, true)
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      })
  }

  const handleOpenForceResetPrompt = (instructions) => {
    setReset({
      open: true,
      content: <React.Fragment>
        Your password no longer complies with Nexkey's password policy.<br /><br />
        {instructions}
      </React.Fragment>
    })
  }

  const handleCloseForceResetPrompt = () => {
    setReset({
      open: false,
      content: null
    })
    setActiveAuthComponent({
      component: "login"
    })
  }

  const {component: activeAuthComponentName, username, message: activeAuthComponentMessage, newPassword} = activeAuthComponent || {}

  return (<React.Fragment>
    {currentConfig && isAuthenticated && <>{children}</>}
    {currentConfig && isAuthChecked && !isAuthenticated && useLegacyLogin && <LegacyLogin onLogin={handleLegacyLogin} />}
    {currentConfig && isAuthChecked && !isAuthenticated && !useLegacyLogin && <Router>
      <Switch>
        <Route exact path='/choosePassword'>
          <ChoosePassword />
        </Route>
        <Route>
          <Box id="authWrapper">
            {activeAuthComponentName === "login" && <Login
              message={activeAuthComponentMessage}
              onLogin={handleLogin}
              onLegacyLogin={handleLegacyLogin}
              onPasswordResetRequired={handlePasswordResetRequired}
              onWeakPasswordResetRequired={handleForgotPassword}
              onClick={handleClick} />}
            {activeAuthComponentName === "signup" && <SignUp
              message={activeAuthComponentMessage}
              onLogin={handleLogin}
              onClick={handleClick} />}
            {activeAuthComponentName === "mfa_sms" && <Mfa
              message={activeAuthComponentMessage}
              type="SMS_MFA"
              cognitoUser={cognitoUser.current}
              newPassword={newPassword}
              onConfirm={handleLogin}
              onNotAuthorized={handleMfaFailure} />}
            {activeAuthComponentName === "forgotPassword" && <ForgotPassword
              message={activeAuthComponentMessage}
              onSuccess={handleForgotPassword}
              onClick={handleClick} />}
            {activeAuthComponentName === "passwordReset" && <ResetPassword
              message={activeAuthComponentMessage}
              username={username}
              onSuccess={handlePasswordReset} />}
            <ForceResetPrompt 
              reset={reset} 
              onOk={handleCloseForceResetPrompt}
            />
          </Box>
        </Route>
      </Switch>
    </Router>}
  </React.Fragment>)
}

export default AuthWrapperCustom;