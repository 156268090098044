import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

import queryString from 'query-string'
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';

import { changePassword } from '../utils/api'
import * as Constants from '../utils/constants'

import Footer from './Footer'

import { withRouter } from 'react-router-dom'
import { withTracker } from '../utils/withTracker'

import nexkeyLogo from '../assets/images/logo.png';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      color: theme.palette.common.white,
      background: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://images.squarespace-cdn.com/content/v1/5b075626506fbe9b5aba78ed/1559261768545-27USVUEAXVDOOUWCRY2Y/ke17ZwdGBToddI8pDm48kH29ZTyws1X0y1-htsih8-l7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UW2OpHjWwyUeAxEKDBsqn7eKS5fAaxMqUsTUWjFJA6yQMW9u6oXQZQicHHG1WEE6fg/Nexkey-Core-Web.png?format=2500w)',
      backgroundColor: theme.palette.common.black,
    },
  },
  chooserError: {
    color: 'red',
    position: 'relative',
    top: '-12px'
  },
  scheduleError: {
    color: 'red',
  },
  formControlLabel: {
    width: '100%',
    marginLeft: 0
  },
  label: {
    width: '100%',
    display: 'inline-block'
  },
  dialogContentText: {
    fontSize: 15,
    fontWeight: 'bold'
  }
}));

function ChoosePassword(props) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const queryObject = queryString.parse(props.location.search)

  const { watch, register, errors, handleSubmit, formState } = useForm();

  const onSubmitChoosePassword = async(data) => {
    const payload = {
      token: queryObject.token,
      username: queryObject.username,
      new_password: data.password
    }
    await changePassword(payload)
      .then((location) => {
        enqueueSnackbar(`Password has been changed.`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        props.history.push('/choosePassword?success=true')
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      })
  }

  const success = queryObject && queryObject.success === "true"

  return (
    <React.Fragment>
      <Box position="relative" zIndex="999999" align="center">
        <Box pt={1} width="35%" maxWidth="200px">
          <Link href="/"><img id="logo" width="100%" src={nexkeyLogo} alt="" /></Link>
        </Box>
      </Box>
      <Dialog open={true} fullWidth={true} maxWidth={'xs'} aria-labelledby="form-dialog-title">
        {!success && <form onSubmit={handleSubmit(onSubmitChoosePassword)}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            {/* {errors && <pre>{JSON.stringify(errors, null, 2)}</pre>} */}
            {/* {formState && <pre>{JSON.stringify(formState, null, 2)}</pre>} */}
            <TextField
              label="New Password"
              autoFocus
              style={{width: '100%'}}
              inputRef={register({ 
                required: {value: true, message: "New password is required."}, 
                pattern: {
                  value: Constants.passwordRegEx,
                  message: Constants.passwordRequirementsText // <p>error message</p>
                }
              })}
              placeholder="Enter new password"
              type="password"
              name="password"
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.message : Constants.passwordRequirementsText}
            /><br />
            <TextField
              label="Confirm New Password"
              style={{width: '100%'}}
              inputRef={register({
                validate: (value) => {
                  return value === watch('password') || "Passwords do not match."
                }
              })}
              placeholder="Enter new password again"
              type="password"
              name="password_confirm"
              error={Boolean(errors.password_confirm)}
              helperText={errors.password_confirm ? errors.password_confirm.message : ''}
            /><br />
          </DialogContent>
          <DialogActions>
            {formState.isSubmitting && <CircularProgress size={20} />}
            <Button 
              disabled={formState.isSubmitting}
              type="submit"
              variant="contained" 
              color="primary" 
              className={classes.buttonAdd}>
              Change Password
            </Button>
          </DialogActions>
        </form>}
        {success && <React.Fragment>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <span>Your password has been changed.</span>
          </DialogContent>
          <DialogActions>
            <CheckIcon color="primary" />
          </DialogActions>
        </React.Fragment>}
      </Dialog>
      <Box position="fixed" bottom="10px" zIndex="999999" width={1}>
        <Footer />
      </Box>
    </React.Fragment>
  )
}

export default withRouter(withTracker(ChoosePassword))
