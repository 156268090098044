import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock22Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 64 64'} {...props} ref={forwardedRef}>
      <path d="M56,53h-3V11h3c0.553,0 1,-0.448 1,-1s-0.447,-1 -1,-1H8c-0.552,0 -1,0.448 -1,1s0.448,1 1,1h3v42H8c-0.552,0 -1,0.447 -1,1s0.448,1 1,1h48c0.553,0 1,-0.447 1,-1S56.553,53 56,53zM51,53h-6V11h6V53zM29,43V25h14v18H29zM43,23h-6V11h6V23zM35,23h-6V11h6V23zM27,53h-6V11h6V53zM29,45h6v8h-6V45zM37,45h6v8h-6V45zM13,11h6v42h-6V11z"></path>
      <path d="M36,29c-1.654,0 -3,1.346 -3,3c0,1.302 0.839,2.401 2,2.815V38c0,0.553 0.447,1 1,1s1,-0.447 1,-1v-3.185c1.161,-0.414 2,-1.514 2,-2.815C39,30.346 37.654,29 36,29zM36,33c-0.552,0 -1,-0.449 -1,-1s0.448,-1 1,-1s1,0.449 1,1S36.552,33 36,33z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock22Icon {...props} forwardedRef={ref} />)