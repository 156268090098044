import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Footer from '../Footer'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      color: theme.palette.common.white,
      background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://images.squarespace-cdn.com/content/v1/5b075626506fbe9b5aba78ed/1559261768545-27USVUEAXVDOOUWCRY2Y/ke17ZwdGBToddI8pDm48kH29ZTyws1X0y1-htsih8-l7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UW2OpHjWwyUeAxEKDBsqn7eKS5fAaxMqUsTUWjFJA6yQMW9u6oXQZQicHHG1WEE6fg/Nexkey-Core-Web.png?format=2500w)',
      backgroundColor: theme.palette.common.black,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& form': {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      '& input': {
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiFormHelperText-root': {
        color: theme.palette.common.white,
      }
    },
    '& button': {
      margin: theme.spacing(3, 0, 2),
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
        opacity: .75,
      },
    },
    '& .MuiAlert-message': {
      padding: theme.spacing(1.25, 0),
    },
  }
}));

export default function AuthContainer({title,children}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img id="logo" width="100%" src="https://images.squarespace-cdn.com/content/5b075626506fbe9b5aba78ed/1531867365697-ME7WQNI6SFF79SJ8HJJP/nexkey-logo-white.png?content-type=image%2Fpng" alt="" />
        {title && <Typography component="h1" variant="h5">
          {title}
        </Typography>}
        {children}
      </div>
      <Box mt={8}>
        <Footer />
      </Box>
    </Container>
    </React.Fragment>
  );
}