import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock16Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M93.7,18.8a10,10 0,0 0,-10 -10L16.3,8.8a10,10 0,0 0,-10 10L6.3,81.2a10,10 0,0 0,10 10L83.7,91.2a10,10 0,0 0,10 -10ZM89.7,81.2a6,6 0,0 1,-6 6L16.3,87.2a6,6 0,0 1,-6 -6L10.3,18.8a6,6 0,0 1,6 -6L83.7,12.8a6,6 0,0 1,6 6Z"></path>
      <path d="M44.5,34.6A15.4,15.4 0,1 0,59.9 50,15.4 15.4,0 0,0 44.5,34.6ZM46.5,61.2L46.5,55.7h-4v5.5a11.4,11.4 0,0 1,-9.1 -8.8h5.8v-4h-6a11.4,11.4 0,0 1,9.3 -9.7v6.4h4L46.5,38.8a11.4,11.4 0,0 1,9.3 9.7h-6v4h5.8A11.4,11.4 0,0 1,46.5 61.2Z"></path>
      <path d="M81.6,28.4h-3L78.6,22.9a4,4 0,0 0,-4 -4L20.4,18.9a4,4 0,0 0,-4 4L16.4,77.1a4,4 0,0 0,4 4L74.6,81.1a4,4 0,0 0,4 -4L78.6,71.6h3a2,2 0,0 0,2 -2L83.6,56.4a2,2 0,0 0,-2 -2h-3L78.6,45.6h3a2,2 0,0 0,2 -2L83.6,30.4A2,2 0,0 0,81.6 28.4ZM79.6,67.6L73.9,67.6L73.9,58.4h5.7ZM74.6,54.4L71.9,54.4a2,2 0,0 0,-2 2L69.9,69.6a2,2 0,0 0,2 2h2.7v5.5L20.4,77.1L20.4,22.9L74.6,22.9v5.6L71.9,28.5a2,2 0,0 0,-2 2L69.9,43.6a2,2 0,0 0,2 2h2.7ZM79.6,41.6L73.9,41.6L73.9,32.4h5.7Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock16Icon {...props} forwardedRef={ref} />)