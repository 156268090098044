import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom'
import { withTracker } from '../utils/withTracker'

import { useSnackbar } from 'notistack';
import { getPendingContactInfo, verifyPassword, updateUserPassword, updateUserPhoneNumber, updateUserEmail } from '../utils/api'
import { formatPhoneNumber, getPropertyValue } from '../utils/tableData'
import { useStateValue } from '../utils/state';
import Cookies from "js-cookie";
import * as Constants from '../utils/constants'
import RegistrationStepper from './RegistrationStepper';
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      color: theme.palette.common.white,
      background: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://images.squarespace-cdn.com/content/v1/5b075626506fbe9b5aba78ed/1559261768545-27USVUEAXVDOOUWCRY2Y/ke17ZwdGBToddI8pDm48kH29ZTyws1X0y1-htsih8-l7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UW2OpHjWwyUeAxEKDBsqn7eKS5fAaxMqUsTUWjFJA6yQMW9u6oXQZQicHHG1WEE6fg/Nexkey-Core-Web.png?format=2500w)',
      backgroundColor: theme.palette.common.black,
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '50vh',
    },
  textField: {
    margin: theme.spacing(1),
    marginBottom: 0,
    width: '200px',
  },
  phoneField: {
    margin: theme.spacing(2),
    marginBottom: 0,
    width: '200px',
    '& div.react-phone-number-input__icon': {
      border: 'none'
    },
    '& div.react-phone-number-input__error': {
      display: 'none'
    },
    '& input.react-phone-number-input__phone': {
      borderBottom: '1px solid #FFFFFF',
      boxShadow: '0 1px 0 #949494'
    },
    '& input.react-phone-number-input__phone:hover': {
      borderBottom: '1px solid #474747',
      boxShadow: '0 1px 0 #474747'
    },
    '& input.react-phone-number-input__phone:focus': {
      borderBottom: '1px solid #00AAB4',
      boxShadow: '0 1px 0 #00AAB4'
    },
    '& input.react-phone-number-input__phone:focus::placeholder': {
      color: 'transparent'
    },
    '& input.react-phone-number-input__input--invalid, input.react-phone-number-input__input--invalid:hover, input.react-phone-number-input__input--invalid:focus': {
      borderBottom: '1px solid #f44336',
      boxShadow: '0 1px 0 #f44336'
    },
    '& input.react-phone-number-input__input--invalid::placeholder': {
      color: '#f44336'
    },
  },
}));

function ChangeUserInfo({type, label, ...props}) {
  const classes = useStyles()
  const [{ currentUser }, dispatchState] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();
  const [passwordVerifiedAt, setPasswordVerifiedAt] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [pendingValue, setPendingValue] = React.useState(null);
  const [meta] = React.useState(type === 'primaryPhone' ? {
    pendingKey: "pendingPhone",
    verificationUrl: "/verifyChangedPhoneNumber",
    updateEndpoint: updateUserPhoneNumber,
    steps: ['Verify Password', 'Enter Phone Number', 'Verify Phone Number']
  } : type === 'email' ? {
    pendingKey: "pendingEmail",
    verificationUrl: "/verifyChangedEmail",
    updateEndpoint: updateUserEmail,
    steps: ['Verify Password', 'Enter Email', 'Verify Email']
  } : {
    updateEndpoint: updateUserPassword,
    steps: []
  });

  const isPasswordVerified = Boolean(passwordVerifiedAt)

  React.useEffect(() => {
    getPendingContactInfo(currentUser)
      .then((pendingContactInfo) => {
        console.log("pendingContactInfo", JSON.stringify(pendingContactInfo, null, 2))
        if (pendingContactInfo && pendingContactInfo[meta.pendingKey]) {
          setPendingValue(pendingContactInfo[meta.pendingKey])
        }
      })
      .catch(({ message }) => {
        // dispatch({ type: 'login_error', message })
      })
      .finally(() => setLoading(false))
  }, [currentUser, meta.pendingKey])

  let inits = {initialValues: null, validationSchema: null}
  if (type === 'password') {
    inits = {
      initialValues: {password: '', new_password: ''},
      validationSchema: {
        password: Yup.string().trim()
          .required('Current Password is Required'),
        new_password: Yup.string().trim()
          .required('New Password is Required')
          .matches(Constants.passwordRegEx, Constants.passwordRequirementsText),
      }
    }
  }
  else if (!isPasswordVerified) {
    inits = {
      initialValues: {password: ''},
      validationSchema: {
        password: Yup.string().trim()
          .required('Password is Required'),
      }
    }
  }
  else if (type === 'email') {
    inits = {
      initialValues: {email: ''},
      validationSchema: {
        email: Yup.string().trim()
          .required('Email is Required')
          .email('Email invalid'),
      }
    }
  }
  else if (type === 'primaryPhone') {
    inits = {
      initialValues: {phoneNumber: ''},
      validationSchema: {
        phoneNumber: Yup.string().trim()
          .required('Phone Number is Required'),
      }
    }
  }

  return (
    <React.Fragment>
      {/* <pre>{JSON.stringify(currentUser)}</pre> */}
      <RegistrationStepper activeStep={!isPasswordVerified ? 0 : 1} steps={meta.steps} />
      {!loading && <Formik
          initialValues={inits.initialValues}
          validationSchema={Yup.object().shape(inits.validationSchema)}
          onSubmit={(values, { setSubmitting }) => {
            if (type === 'password') {
              (meta.updateEndpoint)(currentUser, {password: values.password, new_password: values.new_password})
              .then((user) => {
                const sessionToken = getPropertyValue(user, "sessionToken")
                if (sessionToken) {
                  dispatchState({
                    type: 'changeCurrentUser',
                    newCurrentUser: user
                  })
                  sessionStorage.setItem("legacySessionToken", sessionToken);
                  Cookies.set("sessionToken", sessionToken);
                }
                dispatchState({
                  type: 'changeCurrentEvent',
                  newCurrentEvent: {
                    category: 'Authentication',
                    action: 'Password Changed'
                  }
                })
                enqueueSnackbar('Password successfully changed.', {
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                })
                props.history.push('/settings')
              })
              .catch(({ message }) => {
                enqueueSnackbar(message, {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                })
              })
              .finally(() => setSubmitting(false))
            } else if (isPasswordVerified) {
              (meta.updateEndpoint)(currentUser, (type === 'email' ? {email: values.email.trim()} : {newPhone: values.phoneNumber.replace(/[^0-9+]/g,'')}))
              .then(() => {
                props.history.push(meta.verificationUrl)
              })
              .catch(({ message }) => {
                enqueueSnackbar(message, {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                })
              })
              .finally(() => setSubmitting(false))
            }
            else {
              verifyPassword(currentUser, {password: values.password})
              .then(() => {
                setPasswordVerifiedAt(Date.now())
              })
              .catch(({ message }) => {
                setPasswordVerifiedAt(null)
                enqueueSnackbar(message, {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                })
              })
              .finally(() => setSubmitting(false))
            }
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
      }) => (
        <Dialog open={true} fullWidth={true} maxWidth={'sm'} disableBackdropClick={true} aria-labelledby="form-dialog-title">
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Change {label}</DialogTitle>
            <DialogContent>
              <Box className={classes.box}>
                {type === 'password' && <React.Fragment>
                  <TextField
                    InputLabelProps={{ required: true }}
                    name="password"
                    label="Current Password"
                    className={classes.textField}
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password && touched.password}
                    helperText={(errors.password && touched.password) ? errors.password : ' '}
                  />
                  <TextField
                    InputLabelProps={{ required: true }}
                    name="new_password"
                    label="New Password"
                    className={classes.textField}
                    type="password"
                    value={values.new_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.new_password && touched.new_password}
                    helperText={(errors.new_password && touched.new_password) ? errors.new_password : Constants.passwordRequirementsText}
                  />
                </React.Fragment>}
                {!isPasswordVerified &&  type !== 'password' && <TextField
                  InputLabelProps={{ required: true }}
                  name="password"
                  label="Verify Password"
                  className={classes.textField}
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password}
                  helperText={(errors.password && touched.password) ? errors.password : ' '}
                />}
                {isPasswordVerified && type === 'email' && <TextField
                  InputLabelProps={{ required: true }}
                  name="email"
                  label="Email"
                  className={classes.textField}
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email && touched.email}
                  helperText={(errors.email && touched.email) ? errors.email : ' '}
                />}
                {isPasswordVerified && type === 'primaryPhone' && <div className={classes.phoneField}>
                  <PhoneInput
                    flagsPath="/images/flags/4x3/"
                    country="US"
                    placeholder="Phone Number *"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={ value => setFieldValue('phoneNumber', value || '', false) }
                    onBlur={ () => setFieldTouched('phoneNumber')}
                    error={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                  />
                  <p className="MuiFormHelperText-root Mui-error">{(errors.phoneNumber && touched.phoneNumber) ? errors.phoneNumber : ' '}</p>
                </div>}
                {pendingValue && <p>
                  You have a pending {label.toLowerCase()} change to {type === 'primaryPhone' ? formatPhoneNumber(pendingValue) : pendingValue}.<br />
                  <Link href={meta.verificationUrl}>Verify this {label.toLowerCase()}</Link> or enter a different {label.toLowerCase()} above.
                </p>}
              </Box>
            </DialogContent>
            <DialogActions>
              {isSubmitting && <CircularProgress size={20} />}
              <Button 
                disabled={isSubmitting}
                onClick={() => props.history.push('/settings')}
                color="primary">
                Cancel
              </Button>
              <Button 
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
      </Formik>}
      <Box position="fixed" bottom="10px" zIndex="999999" width={1}>
        <Footer />
      </Box>
    </React.Fragment>
  )
}

export default withRouter(withTracker(ChangeUserInfo))
