import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock3Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 64 64'} {...props} ref={forwardedRef}>
      <path d="M57,63.414L63.414,57l-12,-12l5,-5l-3,-3H53c-3.038,0 -5.87,0.866 -8.292,2.342l-7.771,-7.392L62.334,5.103C62.764,4.649 63,4.056 63,3.431C63,2.091 61.909,1 60.569,1c-0.625,0 -1.219,0.236 -1.671,0.665L31.924,27.182L5.104,1.669C4.65,1.237 4.056,1 3.429,1C2.09,1 1,2.09 1,3.429C1,4.056 1.237,4.65 1.669,5.104l25.384,26.685l-4.625,4.376C20.902,35.426 19.199,35 17.394,35c-3.101,0 -6.015,1.207 -8.207,3.399L7.586,40l5,5l-12,12L7,63.414l12,-12l5,5l1.601,-1.601C27.793,52.621 29,49.707 29,46.606c0,-1.805 -0.426,-3.509 -1.165,-5.034l4.25,-4.494l7.257,7.629C37.866,47.13 37,49.962 37,53v1c0,4.963 4.037,9 9,9h3.5c1.379,0 2.5,-1.121 2.5,-2.5S50.879,58 49.5,58H46c-2.072,0 -3.781,-1.584 -3.98,-3.605l2.98,-2.98L57,63.414zM51.586,48l-2.293,2.293l1.414,1.414L53,49.414L54.586,51l-2.293,2.293l1.414,1.414L56,52.414L57.586,54l-2.293,2.293l1.414,1.414L59,55.414L60.586,57L57,60.586L46.414,50L50,46.414L51.586,48zM60.274,3.117C60.523,2.879 61,3.082 61,3.431c0,0.11 -0.042,0.216 -0.118,0.296L35.488,30.571l-0.699,-0.664l-1.414,-1.345L60.274,3.117zM7,60.586L3.414,57L5,55.414l2.293,2.293l1.414,-1.414L6.414,54L8,52.414l2.293,2.293l1.414,-1.414L9.414,51L11,49.414l2.293,2.293l1.414,-1.414L12.414,48L14,46.414L17.586,50L7,60.586zM24.187,53.399L24,53.586L20.414,50L14,43.586L10.414,40l0.187,-0.187C12.415,37.999 14.827,37 17.394,37C22.69,37 27,41.31 27,46.606C27,49.173 26.001,51.585 24.187,53.399zM26.767,39.791c-0.715,-0.98 -1.577,-1.843 -2.558,-2.558l4.222,-3.995l2.273,2.39L26.767,39.791zM3.118,3.725C3.042,3.645 3,3.54 3,3.429C3,3.081 3.475,2.88 3.725,3.118l26.745,25.44l0,0l5.094,4.845l0,0l7.468,7.104c-0.932,0.745 -1.778,1.591 -2.523,2.523L3.118,3.725zM40,53.586V54c0,3.309 2.691,6 6,6h3.5c0.275,0 0.5,0.225 0.5,0.5S49.775,61 49.5,61H46c-3.859,0 -7,-3.141 -7,-7v-1c0,-7.583 6.061,-13.777 13.592,-13.994L53.586,40L50,43.586L43.586,50L40,53.586z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock3Icon {...props} forwardedRef={ref} />)