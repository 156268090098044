import React from 'react';

export const passwordRegEx = new RegExp(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,})|(.){15,}/)
export const passwordRequirementsText = <span>Min 8 characters. Must contain uppercase, lowercase, and numerical characters.</span>
export const timeStepInSeconds = 900 // 15 min
export const initialInvitationValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: ""
}
export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const daysOfWeekFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const keyAccessRolesThatCanAssignKeys = ['Administrator','Owner']
export const localeDateStringOptions = { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' }
export const localeDateStringOptionsBlackoutChange = { month: 'short', day: '2-digit' }
export const maxReinitializationAttempts = 5
export const reinitializationRetryDelay = 3000
export const pageSize = 100
export const pageSizeExporting = 500
export const pageSizeReporting = 15
export const threshold = 50
export const tinyBlankImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
export const loadingText = 'Loading...'
export const maxMessageLengthForAuditLog = 40
export const maxMessageLengthForActivityLog = 80
export const COLOR_ALL = '#00969D'
export const COLORS = ['#00ACB5','#0A8FCC','#0A58C2','#0ACCA0','#0AC263','#6DC79E','#E9E477','#EA8A4B','#93272F','#67C0AE','#C8BC90','#C79160','#BD504A','#666666','#f7347a','#ffc0cb','#420420','#ffe4e1','#008080','#ffd700','#ff7373','#e6e6fa','#ffa500','#00ffff','#40e0d0','#d3ffce','#f0f8ff','#b0e0e6','#0000ff','#c6e2ff','#faebd7','#003366','#fa8072','#7fffd4','#800000','#ffff00','#cccccc','#800080','#ffb6c1','#00ff00','#ffc3a0','#f08080','#20b2aa','#333333','#fff68f','#4ca3dd','#66cdaa','#c39797','#f6546a','#468499','#ff6666','#ffdab9','#ff00ff']
export const messagingLabelMap = {
  "immediate": "",
  "triggered": "standing",
}
export const CUBE_KEY_LOCK_OBJECT_ID = `i` // lockObjectId in API /getAllUserKeys
export const CUBE_KEY_GROUP_ID = `g` // groupId in API /getAllUserKeys
export const cubeToMaterialTableTypeMap = {
  // https://cube.dev/docs/types-and-formats#dimensions-types
  // https://github.com/mbrn/material-table/blob/2384f5a6271bbd1f8fd0f77b008804f4325606d4/src/prop-types.js#L75
  "time": "datetime",
  "string": "string",
  "number": "numeric",
  "boolean": "boolean",
}
export const cubeToXGridTypeMap = {
  // https://cube.dev/docs/types-and-formats#dimensions-types
  // https://material-ui.com/components/data-grid/columns/#column-types
  "time": "dateTime",
  "string": "string",
  "number": "number",
  "boolean": "number",
}
export const CUBE_TABLE_PREVIEW_LIBRARY_LIMIT = 10
export const CUBE_TABLE_PREVIEW_EDIT_LIMIT = 25
export const CUBE_GRID_COLUMNS = 6
export const CUBE_GRID_ROW_HEIGHT = 240
export const CUBE_GRID_ROW_PADDING = 10
export const CUBE_GRID_DEFAULT_COLUMN_SPAN = 6 // this should be a portion of 12
export const CUBE_GRID_DEFAULT_ROW_SPAN = 2
export const CUBE_GRID_DEFAULT_Y = 99999
export const CUBE_GRID_ROW_OFFSET = 70
export const CUBE_GRID_MIN_W = 1
export const CUBE_GRID_MIN_H = 1
export const CUBE_GRID_CONTAINER_PADDING = [0,0]
export const CUBE_DEFAULT_DASHBOARD_NAME = `Dashboard`
export const CUBE_PREVIEW_HEIGHT = (CUBE_GRID_ROW_HEIGHT*2) + CUBE_GRID_ROW_PADDING
export const CUBE_DEFAULT_GRANULARITY = -1
export const CUBE_DEFAULT_DATE_RANGE = -1
export const CUBE_GRANULARITIES = [
  {
    title: 'w/o grouping',
    value: CUBE_DEFAULT_GRANULARITY, // null, undefined, and "" do not work as expected
  },
  {value: 'hour'},
  {value: 'day'},
  {value: 'week'},
  {value: 'month'},
  {value: 'year'},
];
export const CUBE_DATE_RANGES = [
  {value: 'Today', compareTo: 'Yesterday'},
  {value: 'Yesterday'},
  {value: 'This week', compareTo: 'Last Week'},
  {value: 'Last week'},
  {value: 'This month', compareTo: 'Last Month'},
  {value: 'Last month'},
  {value: 'This quarter', compareTo: 'Last Quarter'},
  {value: 'Last quarter'},
  {value: 'This year', compareTo: 'Last Year'},
  {value: 'Last year'},

  {value: 'Last 24 hours'},
  {title: 'Last 7 days',value: 'from 7 days ago to now'},
  {title: 'Last 30 days',value: 'from 30 days ago to now'},

  // {
  //   title: 'All time',
  //   value: CUBE_DEFAULT_DATE_RANGE, // null, undefined, and "" do not work as expected
  // }
];
export const nkPowerUsers = new Set(["R0rTUJsUx1","xcvHC5dESg","CZn2XuFJf3"])
export const remoteLockSupportArticle = "https://www.nexkey.com/wifi"
export const disableBusinessHoursSupportArticle = "https://www.nexkey.com/disable-business-hours"
