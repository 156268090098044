import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock11Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M32.5,30H5v65h27.5V57.5H95v-15H32.5V30zM25,87.5H12.5v-50H25v5h-7.5v15H25V87.5zM22.5,52.5v-5h4.705l2.5,5H22.5zM90,47.5v5h-4.705l-2.5,-5H90zM77.205,47.5l2.5,5h-4.41l-2.5,-5H77.205zM67.205,47.5l2.5,5h-4.41l-2.5,-5H67.205zM57.205,47.5l2.5,5h-4.41l-2.5,-5H57.205zM47.205,47.5l2.5,5h-4.41l-2.5,-5H47.205zM37.205,47.5l2.5,5h-4.41l-2.5,-5H37.205z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock11Icon {...props} forwardedRef={ref} />)