import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock31Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M77.1,9.1H22.9v77.2H30v6h4v-6h32v6h4v-6h7.1V9.1zM26.9,33.4h46.2v16H26.9V33.4zM73.1,13.1v16.2H26.9V13.1H73.1zM26.9,82.4v-29h46.2v29H26.9z"></path>
      <path d="M45,39.1h10v4h-10z"></path>
      <path d="M45,19.2h10v4h-10z"></path>
      <path d="M45,60.1h10v4h-10z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock31Icon {...props} forwardedRef={ref} />)