import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class RemoteOnlineIcon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 18 18'} {...props} ref={forwardedRef}>
      <path d="M12.93,13.25H4.26a3.32,3.32 0,0 1,0 -6.64h0.08A4.49,4.49 0,0 1,12.6 5h0.33a4.13,4.13 0,0 1,0 8.26ZM4.26,7.48a2.45,2.45 0,1 0,0 4.9h8.67a3.27,3.27 0,1 0,-0.5 -6.48l-0.31,0L12,5.67A3.63,3.63 0,0 0,5.15 7.11l0,0.48 -0.47,-0.08Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <RemoteOnlineIcon {...props} forwardedRef={ref} />)