import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock27Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M61.2,43.88a3,3 0,0 0,-3 3v7a3,3 0,0 0,6 0v-7A3,3 0,0 0,61.2 43.88Z"></path>
      <path d="M81,85.88H76V11.13a3,3 0,0 0,-3 -3H27a3,3 0,0 0,-3 3V85.88H19a3,3 0,0 0,0 6H81a3,3 0,1 0,0 -6ZM30,14.13H70V85.88H30Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock27Icon {...props} forwardedRef={ref} />)