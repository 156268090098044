import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Auth } from 'aws-amplify';
import Cookies from "js-cookie";
import { logout } from '../utils/api'
import { useStateValue } from '../utils/state';

export default function Footer() {
  const [{ currentUser }, dispatchState] = useStateValue();

  const handleLogout = (event) => {
    event.preventDefault()
    const legacySessionToken = sessionStorage.getItem("legacySessionToken")
    if (legacySessionToken) {
      logout(legacySessionToken)
        .then(() => { /* expect success */ })
        .catch(({ message }) => { /* silent on error */ })
        .finally(() => {
          // always do this...
          Cookies.remove("sessionToken");
          sessionStorage.removeItem("legacySessionToken")
          dispatchState({
            type: 'changeCurrentUser',
            newCurrentUser: null
          })
          window.location.reload()
        })
    }
    Auth.signOut()
      .then((user) => {
        window.location.reload()
      })
      .catch((error) => {
        console.log("Auth.currentAuthenticatedUser error", JSON.stringify(error, null, 2))
      })
  }

  return (
    <React.Fragment>
      <Typography variant="body2" color="inherit" align="center">
        {currentUser && <Link color="primary" onClick={handleLogout} href="#">Logout</Link>}
        {' '}
        {'Copyright © '}
        <Link color="inherit" href="https://www.nexkey.com">
          Nexkey
        </Link>{' '}
        {new Date().getFullYear()}<br /><br />
        <Link color="primary" href="https://www.nexkey.com/support-form">Contact Support</Link>
      </Typography>
    </React.Fragment>
  )
}
