import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock13Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="m16,10c-4.397,0 -8,3.603 -8,8l0,8c0,4.397 3.603,8 8,8l68,0c4.397,0 8,-3.603 8,-8l0,-8c0,-4.397 -3.603,-8 -8,-8l-68,0zM16,14 L84,14c2.251,0 4,1.749 4,4l0,8c0,2.251 -1.749,4 -4,4l-68,0c-2.251,0 -4,-1.749 -4,-4l0,-8c0,-2.251 1.749,-4 4,-4zM21,19c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM33,19c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM45,19c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM16,38c-4.397,0 -8,3.603 -8,8l0,8c0,4.397 3.603,8 8,8l68,0c4.397,0 8,-3.603 8,-8l0,-8c0,-4.397 -3.603,-8 -8,-8l-68,0zM16,42 L84,42c2.251,0 4,1.749 4,4l0,8c0,2.251 -1.749,4 -4,4l-68,0c-2.251,0 -4,-1.749 -4,-4l0,-8c0,-2.251 1.749,-4 4,-4zM21,47c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM33,47c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM45,47c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM16,66c-4.397,0 -8,3.603 -8,8l0,8c0,4.397 3.603,8 8,8l68,0c4.397,0 8,-3.603 8,-8l0,-8c0,-4.397 -3.603,-8 -8,-8l-68,0zM16,70 L84,70c2.251,0 4,1.749 4,4l0,8c0,2.251 -1.749,4 -4,4l-68,0c-2.251,0 -4,-1.749 -4,-4l0,-8c0,-2.251 1.749,-4 4,-4zM21,75c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM33,75c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3zM45,75c-1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 1.657,0 3,-1.343 3,-3 0,-1.657 -1.343,-3 -3,-3z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock13Icon {...props} forwardedRef={ref} />)