import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock12Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 32 32'} {...props} ref={forwardedRef}>
      <path d="M29,11h-7V9c0,-1.6542 -1.3458,-3 -3,-3h-6c-1.6542,0 -3,1.3458 -3,3v2H3c-0.5522,0 -1,0.4477 -1,1v4c0,0.5522 0.4478,1 1,1v9c0,0.5522 0.4478,1 1,1h24c0.5523,0 1,-0.4478 1,-1v-9c0.5523,0 1,-0.4478 1,-1v-4C30,11.4477 29.5523,11 29,11zM11,9c0,-1.1046 0.8954,-2 2,-2h6c1.1046,0 2,0.8954 2,2v2h-1V9c0,-0.5523 -0.4477,-1 -1,-1h-6c-0.5523,0 -1,0.4477 -1,1v2h-1V9zM19,9v2h-6V9H19zM28,26H4v-9h10v1.5c0,1.1028 0.8972,2 2,2s2,-0.8972 2,-2V17h10V26zM17,18v0.5c0,0.5523 -0.4477,1 -1,1c-0.5522,0 -1,-0.4477 -1,-1V18H17zM15,17v-2h2v2H15zM29,16H18v-1c0,-0.5523 -0.4477,-1 -1,-1h-2c-0.5522,0 -1,0.4477 -1,1v1H3v-4h26V16z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock12Icon {...props} forwardedRef={ref} />)