import React from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StateProvider } from './utils/state';
import { SnackbarProvider } from 'notistack';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AuthWrapper from './components/AuthWrapperCustom';
import Config from './components/Config';
import Main from './components/Main';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { getPropertyValue } from './utils/tableData'
import { ApolloProvider } from '@apollo/client';
import client from './graphql/client';

const privateFieldNames = [
  'Password',
  'password',
  'new_password',
];

const requestSanitizer = request => {
  let hasMaskableFields = false
  if (getPropertyValue(request, "method") === "POST") {
    const requestBodyString = getPropertyValue(request, "body")
    if (requestBodyString && requestBodyString !== "{}") {
      const requestBodyObj = JSON.parse(requestBodyString)
      if (requestBodyObj) {
        privateFieldNames.forEach(privateFieldName => {
          const privateFieldNameValue = getPropertyValue(requestBodyObj, privateFieldName)
          if (privateFieldNameValue && typeof privateFieldNameValue === "string") {
            requestBodyObj[privateFieldName] = privateFieldNameValue.length === 0 ? "" : Array(privateFieldNameValue.length + 1).join("*")
            hasMaskableFields = true
          }
        })
        if (hasMaskableFields) {
          request.body = JSON.stringify(requestBodyObj)
        }
      }
    }
  }

  return request;
}

if ('REACT_APP_LOGROCKET' in process.env && process.env.REACT_APP_LOGROCKET !== '') {
  LogRocket.init(process.env.REACT_APP_LOGROCKET, {
    release: process.env.REACT_APP_VERSION,
    console: {
      shouldAggregateConsoleErrors: true,
    },
    network: { requestSanitizer }
  });
  console.log('LOGROCKET', process.env.REACT_APP_LOGROCKET)
}

if ('REACT_APP_HOTJAR_ID' in process.env && process.env.REACT_APP_HOTJAR_ID !== '') {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
    console.log('HOTJAR', process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION)
  }
  
if ('REACT_APP_GA' in process.env && process.env.REACT_APP_GA !== '') {
  console.log('GA', process.env.REACT_APP_GA)
  ReactGA.initialize(process.env.REACT_APP_GA, {
    debug: process.env.REACT_APP_STAGE === 'development',
    titleCase: false,
  })
}

const fontSize = 11

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#f7f7f7"
    },
    primary: {
      // light: '#ff0000',
      main: '#00969d',
      dark: '#00AAB4',
      contrastText: '#FFFFFF',
    },
    secondary: {
      // light: '#ff0000',
      main: '#474747',
      dark: '#c7c7c7',
      contrastText: '#FFFFFF',
    }
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: fontSize
      },
      footer: {
        fontSize: fontSize
      },
      body: {
        color: '#292929'
      }
    },
    MuiButton: {
      sizeSmall: {
        fontSize: fontSize,
        paddingLeft: 5,
        paddingRight: 5,
        minWidth: 42,
        whiteSpace: "nowrap"
      }
    },
    MuiInput: {
      root: {
        fontSize: fontSize
      }
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontSize: fontSize,
    h1: {
      fontFamily: 'Quicksand',
    },
    h2: {
      fontFamily: 'Quicksand',
    },
    h3: {
      fontFamily: 'Quicksand',
    },
    h4: {
      fontFamily: 'Quicksand',
    },
    h5: {
      fontFamily: 'Quicksand',
    },
    h6: {
      fontFamily: 'Quicksand',
      fontSize: 16,
      fontWeight: 600,
    },
    subtitle1: {
    },
    subtitle2: {
    },
    body1: {
      fontFamily: 'Montserrat',
      fontSize: fontSize,
    },
    body2: {
    },
    button: {
      fontFamily: 'Montserrat',
      fontSize: fontSize,
    },
    caption: {
    },
    overline: {
    },
  },
});

const useStyles = makeStyles({
  info: {
    backgroundColor: '#00969d'
  },
});

export default function App() {
  const classes = useStyles()
  const initialState = {
    currentEvent: null,
    currentConfig: null,
    currentUser: null,
    currentFeatures: null
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeCurrentEvent':
        // GA event tracking is sent from here
        ReactGA.event(action.newCurrentEvent);
        return {
          ...state,
          currentEvent: action.newCurrentEvent
        };
      case 'changeCurrentConfig':
        return {
          ...state,
          currentConfig: action.newCurrentConfig
        };
      case 'changeCurrentUser':
        if (action.newCurrentUser) {
          ReactGA.set({ userId: action.newCurrentUser.objectId });
          // if ('REACT_APP_LOGROCKET' in process.env && process.env.REACT_APP_LOGROCKET !== '') {
          //   LogRocket.identify(action.newCurrentUser.objectId, {
          //     name: (action.newCurrentUser.firstName + ' ' + action.newCurrentUser.lastName).trim(),
          //     email: action.newCurrentUser.primaryEmail,
          //     phoneNumber: action.newCurrentUser.primaryPhone,
          //   });
          // }
        }
        const newState = {
          ...state,
          currentUser: action.newCurrentUser
        }
        const advancedFeatures = getPropertyValue(action, "newCurrentUser.advancedFeatures")
        if (advancedFeatures) {
          newState.currentFeatures = advancedFeatures
        }
        return newState;
      case 'changeCurrentFeatures':
        return {
          ...state,
          currentFeatures: action.newCurrentFeatures
        };
      default:
        return state;
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <ApolloProvider client={client}>
          <SnackbarProvider
            maxSnack={3}
            iconVariant={{
              info: <NotificationsIcon style={{paddingRight: 7}} />
            }}
            classes={{
              variantInfo: classes.info,
            }}
          >
            <CssBaseline />
            <Config>
              <AuthWrapper>
                <Main />
              </AuthWrapper>
            </Config>
          </SnackbarProvider>
        </ApolloProvider>
      </StateProvider>
    </MuiThemeProvider>
  );
}
