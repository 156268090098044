import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

function ForceResetPrompt({reset,onOk}) {
  const handleOk = () => {
    onOk()
  }

  return (
    <React.Fragment>
      <Dialog open={reset.open} fullWidth={true} maxWidth={'xs'} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New password required</DialogTitle>
        <DialogContent>
          {reset.content}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleOk} 
            variant="contained"
            color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ForceResetPrompt
