import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock4Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 90 90'} {...props} ref={forwardedRef}>
      <path d="M77.3,19H61.7c-6.1,0 -11,5 -11,11v23.4c0,3.5 2.9,6.4 6.4,6.4h1.4v23.3c0,3.5 2.9,6.4 6.4,6.4c1.8,0 3.5,-0.7 4.7,-2.1c1.2,1.3 2.9,2.1 4.7,2.1c3.5,0 6.4,-2.9 6.4,-6.4V59.8h1.4c3.5,0 6.4,-2.9 6.4,-6.4V30.1C88.3,24 83.4,19 77.3,19zM84.9,53.5c0,1.7 -1.3,3 -3,3h-1.4V33.2c0,-0.9 -0.8,-1.7 -1.7,-1.7s-1.7,0.8 -1.7,1.7v49.8c0,1.5 -1,2.9 -2.5,3.1c-1.9,0.3 -3.5,-1.1 -3.5,-2.9v-25c0,-0.9 -0.8,-1.7 -1.7,-1.7h0c-0.9,0 -1.7,0.8 -1.7,1.7v25c0,1.8 -1.7,3.2 -3.5,2.9c-1.5,-0.3 -2.5,-1.6 -2.5,-3.1V33.2c0,-0.9 -0.8,-1.7 -1.7,-1.7s-1.7,0.8 -1.7,1.7v23.3H57c-1.7,0 -3,-1.3 -3,-3V30.1c0,-4.2 3.4,-7.7 7.7,-7.7h15.6c4.2,0 7.7,3.4 7.7,7.7V53.5z"></path>
      <path d="M69.5,17.9c4.8,0 8.7,-3.9 8.7,-8.7c0,-4.8 -3.9,-8.7 -8.7,-8.7c-4.8,0 -8.7,3.9 -8.7,8.7C60.8,14 64.7,17.9 69.5,17.9zM69.5,3.9c2.9,0 5.3,2.4 5.3,5.3c0,2.9 -2.4,5.3 -5.3,5.3c-2.9,0 -5.3,-2.4 -5.3,-5.3C64.1,6.3 66.5,3.9 69.5,3.9z"></path>
      <path d="M37.9,27.5L37.9,27.5L37.9,27.5c-1.2,-5 -5.6,-8.5 -10.7,-8.5h-9.4c-5.1,0 -9.5,3.5 -10.7,8.4L1.4,50.8c-0.8,3.4 1.3,6.9 4.7,7.7l1.4,0.3L6,65.5c0,0.1 0,0.2 0,0.2c0,0.2 0,0.4 0,0.6C6,66.6 6.1,66.8 6.3,67c0.2,0.2 0.3,0.4 0.6,0.5c0.2,0.1 0.4,0.1 0.6,0.2c0.1,0 0.2,0 0.2,0h3.8v15.5c0,3.5 2.9,6.4 6.4,6.4c1.8,0 3.5,-0.7 4.7,-2.1c1.2,1.3 2.9,2.1 4.7,2.1c3.5,0 6.4,-2.9 6.4,-6.4V67.6h3.7c0.1,0 0.1,0 0.2,0c0.2,0 0.4,-0.1 0.6,-0.2c0.2,-0.1 0.4,-0.3 0.6,-0.5c0.2,-0.2 0.3,-0.4 0.3,-0.7c0,-0.2 0.1,-0.4 0,-0.6c0,-0.1 0,-0.1 0,-0.2l-1.6,-6.7l1.5,-0.4c1.6,-0.4 3,-1.4 3.9,-2.9c0.9,-1.4 1.2,-3.1 0.8,-4.8L37.9,27.5zM39.8,53.9c-0.4,0.7 -1.1,1.2 -1.9,1.3l-1.5,0.4L31,32.7c-0.2,-0.9 -1.1,-1.5 -2,-1.2v0c-0.9,0.2 -1.5,1.1 -1.2,2L35,64.2h-5l0,3.3V83c0,1.5 -1,2.9 -2.5,3.1c-1.9,0.3 -3.5,-1.1 -3.5,-2.9V66c0,-0.9 -0.8,-1.7 -1.7,-1.7S20.7,65 20.7,66v17.2c0,1.8 -1.7,3.2 -3.5,2.9c-1.5,-0.3 -2.5,-1.7 -2.5,-3.1V64.2h-5l7.3,-30.7c0.2,-0.9 -0.3,-1.8 -1.3,-2c-0.9,-0.2 -1.8,0.3 -2,1.3L8.3,55.5l-1.4,-0.3c-0.8,-0.2 -1.4,-0.7 -1.9,-1.3c-0.4,-0.7 -0.6,-1.5 -0.4,-2.3l5.6,-23.3l0.4,-1c1.1,-3 3.9,-4.9 7.1,-4.9h9.4c3.6,0 6.6,2.4 7.5,5.9l5.6,23.3l0,0C40.3,52.4 40.2,53.2 39.8,53.9z"></path>
      <path d="M22.4,17.9c4.8,0 8.7,-3.9 8.7,-8.7c0,-4.8 -3.9,-8.7 -8.7,-8.7s-8.7,3.9 -8.7,8.7C13.7,14 17.6,17.9 22.4,17.9zM22.4,3.9c2.9,0 5.3,2.4 5.3,5.3c0,2.9 -2.4,5.3 -5.3,5.3s-5.3,-2.4 -5.3,-5.3C17.1,6.3 19.5,3.9 22.4,3.9z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock4Icon {...props} forwardedRef={ref} />)