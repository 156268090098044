import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock33Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 64 64'} {...props} ref={forwardedRef}>
      <path d="M3,62H21a1,1 0,0 0,1 -1V46.54a4,4 0,0 0,-1.68 -3.25L15,39.51 16.78,7.05a4.79,4.79 0,1 0,-9.56 0L9,39.51l-5.3,3.78A4,4 0,0 0,2 46.54V61A1,1 0,0 0,3 62ZM4,46.54a2,2 0,0 1,0.84 -1.62l5.74,-4.11A1,1 0,0 0,11 40l-1.79,-33A2.75,2.75 0,0 1,10 4.87a2.85,2.85 0,0 1,4 0,2.75 2.75,0 0,1 0.77,2.07L13,40a1,1 0,0 0,0.42 0.86l5.74,4.11A2,2 0,0 1,20 46.54V60H4Z"></path>
      <path d="M58,38.61 L59.78,7.05a4.79,4.79 0,1 0,-9.56 0L52,38.61l-2.81,2.8A4.06,4.06 0,0 0,48 44.24V61a1,1 0,0 0,1 1h2a1,1 0,0 0,1 -0.79L54.81,48h0.38L58,61.21A1,1 0,0 0,59 62h2a1,1 0,0 0,1 -1V44.24a4.06,4.06 0,0 0,-1.17 -2.83ZM60,60h-0.19L57,46.79A1,1 0,0 0,56 46H54a1,1 0,0 0,-1 0.79L50.19,60H50V44.24a2,2 0,0 1,0.59 -1.41l3.12,-3.12a1,1 0,0 0,0.29 -0.77l-1.78,-32A2.78,2.78 0,0 1,53 4.87a2.85,2.85 0,0 1,4 0,2.78 2.78,0 0,1 0.76,2.07L56,38.94a1,1 0,0 0,0.29 0.77l3.12,3.12A2,2 0,0 1,60 44.24Z"></path>
      <path d="M38,40.24 L39.79,7.05a4.8,4.8 0,1 0,-9.58 0L32,40.24a11.31,11.31 0,0 0,-6.49 5.26,11 11,0 0,0 8.25,16.43c0.42,0 0.84,0.07 1.25,0.07A11,11 0,0 0,46 51,11.45 11.45,0 0,0 38,40.24ZM41,57.72A9,9 0,0 1,27.21 46.49a9.2,9.2 0,0 1,6 -4.51A1,1 0,0 0,34 41l-1.79,-34a2.79,2.79 0,1 1,5.58 0L36,41a1,1 0,0 0,0.79 1A9.37,9.37 0,0 1,44 51,9 9,0 0,1 41,57.72Z"></path>
      <path d="M28,51a7,7 0,0 0,7 7V56a5,5 0,0 1,0 -10V44A7,7 0,0 0,28 51Z"></path>
      <path d="M11,48h2v10h-2z"></path>
      <path d="M6,48h2v10h-2z"></path>
      <path d="M16,48h2v10h-2z"></path>
      <path d="M11,6h2v2h-2z"></path>
      <path d="M34,6h2v2h-2z"></path>
      <path d="M54,6h2v2h-2z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock33Icon {...props} forwardedRef={ref} />)