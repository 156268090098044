import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SchemaLink } from 'apollo-link-schema';
import { makeExecutableSchema } from 'graphql-tools';
import { getDashboards, updateDashboardItems } from '../utils/api'
import { getPropertyValue } from '../utils/tableData'
import { dashboardItemLibrary } from '../components/shared/DashboardLibraryItems'
import * as Constants from '../utils/constants'
const cache = new InMemoryCache();

const arrayToObject = (arr, propertyToBecomeKey) => {
  // disable eslint on next next line; otherwise it complains about the comma between true & a
  // eslint-disable-next-line no-sequences
  return arr.reduce((a,b)=> (a[b[propertyToBecomeKey]]=b,a),{})
}

const getDashboardItems = async(user, dashboardObjectId) => {
  const dashboard = await getDashboards(user)
    .then((dashboards) => {
      return dashboardObjectId ? dashboards.find(dashboard => dashboard.objectId === dashboardObjectId) : dashboards[0]
    })
    .catch(({ message }) => {
      alert(`ERROR: ${message}`)
    })
  return getPropertyValue(dashboard, "items", false)
}

const setDashboardItems = async(user, dashboardObjectId, items) => {
  // // BEGIN ADDED BEGIN ADDED BEGIN ADDED BEGIN ADDED
  await updateDashboardItems(user, {dashboardObjectId, items})
    .then((dashboard) => {
      // alert("TODO we need to update the dashboards")
      // ACTUALLY, maybe not? GET_DASHBOARD_ITEMS is called as refetchQueries
      // SO, to what extent do we need to see items from dashboard obj?
      // setDashboards(prevState => {
      //   prevState[dashboard.objectId] = dashboard
      // })
    })
    .catch(({ message }) => {
      alert(`ERROR: ${message}`)
    })
  // // END END END END END END END END END END END ADDED
}

const nextId = (dashboardItems) => {
  let currentId = 0
  if (dashboardItems && dashboardItems.length >= 1) {
    currentId = Math.max.apply(Math, dashboardItems.map(function(o) { return parseInt(o.itemId, 10); }))
  }
  return (currentId+1).toString();
};

const toApolloItem = (i) => ({ ...i, __typename: 'DashboardItem' });

const typeDefs = `
  type DashboardItem {
    itemId: String!
    layout: String
    vizState: String
    name: String
    libraryItemObjectId: String
    extra: String
  }

  input User {
    objectId: String!
  }

  input DashboardItemInput {
    layout: String
    vizState: String
    name: String
    libraryItemObjectId: String
    extra: String
  }

  input DashboardItemInputFull {
    itemId: String
    layout: String
    vizState: String
    name: String
    libraryItemObjectId: String
    extra: String
  }

  type Query {
    dashboardItems(user: User!, dashboardObjectId: String!): [DashboardItem]
    dashboardItem(user: User!, dashboardObjectId: String!, itemId: String!): DashboardItem
  }

  type Mutation {
    createDashboardItem(user: User!, dashboardObjectId: String!, input: DashboardItemInput): DashboardItem
    updateDashboardItem(user: User!, dashboardObjectId: String!, itemId: String!, input: DashboardItemInput): DashboardItem
    updateDashboardLayout(user: User!, dashboardObjectId: String!, input: [DashboardItemInputFull]): [DashboardItem]
    deleteDashboardItem(user: User!, dashboardObjectId: String!, itemId: String!): DashboardItem
  }
`;

const getPlacementCoordinates = (requiredWidth, dashboardItems) => {
  console.log("dashboardItems", JSON.stringify(dashboardItems, null, 2))
  let coordinates
  // let existingLayout = []
  // // TODO FIX - this should go through and find next available spot.
  // for (const dashboardItem of dashboardItems) {
  //   const layout = JSON.parse(getPropertyValue(dashboardItem, "layout"))
  //   if (layout) { // {x: 2, y: 8, w: 2, h: 8}
  //     const rowNumber = layout.y / 8
  //     if (!existingLayout[rowNumber] || !Array.isArray(existingLayout[rowNumber])) {
  //       existingLayout[rowNumber] = new Array(4).fill(false)
  //     }
  //     console.log("rowNumber", rowNumber, "layout", {...layout})
  //     const starting = layout.x
  //     const ending = layout.x + layout.w
  //     for (let i = starting; i<ending; i++) {
  //       existingLayout[rowNumber][i] = true
  //     }
  //     console.log("existingLayout", JSON.stringify(existingLayout))
  //     // we need to mark true
  //     // layout[0][0],layout[0][0],layout[0][0],layout[0][0]
  //   }
  // }
  // // now we have existingLayout; for each row, find layout.w continuous buckets.  if none, return
  // console.log("requiredWidth", requiredWidth)
  // for (let i = 0; i<existingLayout.length; i++) {
  //   const key = new Array(requiredWidth).fill(false).toString()
  //   const where = existingLayout[i].toString().indexOf(key)
  //   if (where >= 0) {
  //     console.log("PLACE IN POSITION!", where)
  //     coordinates = {x: where, y: i}
  //     break
  //   }
  // }
  // console.log("coordinates", coordinates)
  // Infinity gets converted to null on stringify so we'll use a large integer as defined in CUBE_GRID_DEFAULT_Y
  return coordinates || {x:0, y:Constants.CUBE_GRID_DEFAULT_Y}
}

const schema = makeExecutableSchema({
  typeDefs,
  resolvers: {
    Query: {
      async dashboardItems(_, { user, dashboardObjectId }) {
        const dashboardItems = await getDashboardItems(user, dashboardObjectId);
        const dashboardItemLibrarySet = new Set(dashboardItemLibrary.map(item => item.objectId)) // Set for quick lookup
        const dashboardItemsToRemove = dashboardItems.filter(item => !dashboardItemLibrarySet.has(item.libraryItemObjectId))
        let dashboardItemsFinal = dashboardItems
        if (dashboardItemsToRemove.length >= 1) {
          // here we are actively deleting unsupported (removed/disabled) dashboardLibraryItems
          dashboardItemsFinal = dashboardItems.filter(item => dashboardItemLibrarySet.has(item.libraryItemObjectId))
          await setDashboardItems(user, dashboardObjectId, dashboardItemsFinal)
        }
        return dashboardItemsFinal ? dashboardItemsFinal.map(toApolloItem) : false;
      },

      async dashboardItem(_, { user, dashboardObjectId, itemId }) {
        const dashboardItems = await getDashboardItems(user, dashboardObjectId);
        return toApolloItem(dashboardItems.find((i) => i.itemId.toString() === itemId));
      },
    },
    Mutation: {
      createDashboardItem: async(_, { user, dashboardObjectId, input: { ...item } }) => {
        const dashboardItems = await getDashboardItems(user, dashboardObjectId);
        const layout = JSON.parse(getPropertyValue(item, "layout", "{}"))
        const coordinates = getPlacementCoordinates(layout.w, dashboardItems)
        item = { ...item, itemId: nextId(dashboardItems), layout: JSON.stringify({...layout, ...coordinates}) };
        dashboardItems.push(item);
        await setDashboardItems(user, dashboardObjectId, dashboardItems);
        return toApolloItem(item);
      },
      updateDashboardItem: async(_, { user, dashboardObjectId, itemId, input: { ...item } }) => {
        const dashboardItems = await getDashboardItems(user, dashboardObjectId);
        item = Object.keys(item)
          .filter((k) => !!item[k])
          .map((k) => ({
            [k]: item[k],
          }))
          .reduce((a, b) => ({ ...a, ...b }), {});
        const index = dashboardItems.findIndex((i) => i.itemId.toString() === itemId);
        dashboardItems[index] = { ...dashboardItems[index], ...item };
        await setDashboardItems(user, dashboardObjectId, dashboardItems);
        return toApolloItem(dashboardItems[index]);
      },
      updateDashboardLayout: async(_, { user, dashboardObjectId, input: [ ...items ] }) => {
        const dashboardItems = await getDashboardItems(user, dashboardObjectId);
        console.log("[updateDashboardLayout] items", [...items])
        console.log("[updateDashboardLayout] dashboardItems", [...dashboardItems])
        const dashboardItemsObj = arrayToObject(dashboardItems, "itemId")
        for (const item of items) {
          dashboardItemsObj[item.itemId] = {
            ...dashboardItemsObj[item.itemId],
            layout: item.layout
          }
        }
        const dashboardItemsNew = Object.values(dashboardItemsObj)
        console.log("[updateDashboardLayout] dashboardItemsNew", [...dashboardItemsNew])
        await setDashboardItems(user, dashboardObjectId, dashboardItemsNew);
        return dashboardItemsNew.map(toApolloItem)
      },
      // updateDashboardLayout: async(_, { user, dashboardObjectId, input: [ ...items ] }) => {
      //   const dashboardItems = await getDashboardItems(user, dashboardObjectId);
      //   console.log("[updateDashboardLayout] dashboardItems", [...dashboardItems])
      //   const dashboardItemsObj = arrayToObject(dashboardItems, "libraryItemObjectId")
      //   for (const item of items) {
      //     // dashboardItemsNew.push()
      //     dashboardItemsObj[item.libraryItemObjectId] = {
      //       ...dashboardItemsObj[item.libraryItemObjectId],
      //       layout: item.layout
      //     }
      //   }
      //   const dashboardItemsNew = Object.values(dashboardItemsObj)
      //   console.log("[updateDashboardLayout] dashboardItemsNew", [...dashboardItemsNew])
      //   await setDashboardItems(user, dashboardObjectId, dashboardItemsNew);
      //   return dashboardItemsNew.map(toApolloItem)
      // },
      deleteDashboardItem: async(_, { user, dashboardObjectId, itemId }) => {
        const dashboardItems = await getDashboardItems(user, dashboardObjectId);
        const index = dashboardItems.findIndex((i) => i.itemId.toString() === itemId);
        const [removedItem] = dashboardItems.splice(index, 1);
        await setDashboardItems(user, dashboardObjectId, dashboardItems);
        return toApolloItem(removedItem);
      },
    },
  },
});
export default new ApolloClient({
  cache,
  link: new SchemaLink({
    schema,
  }),
});
