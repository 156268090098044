import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";

export const ConnectForm = ({ children }) => {
  const methods = useFormContext();
  
  return children({ ...methods });
};

const exitAfterUnlockChoices = [
  {value: 60, label: "1 hour"},
  {value: 120, label: "2 hours"},
  {value: 180, label: "3 hours"},
  {value: 240, label: "4 hours"},
  {value: 300, label: "5 hours"},
  {value: 360, label: "6 hours"},
  {value: 420, label: "7 hours"},
  {value: 480, label: "8 hours"},
  {value: 540, label: "9 hours"},
  {value: 600, label: "10 hours"}
]

export const dashboardItemExtraConfig = {
  maxCapacity: {
    component: ({extraField, className}) => { return <ConnectForm>
      {({ register, errors }) => <TextField
        inputRef={register({
          validate: {
            checkMin: value => parseInt(value, 10) >= 1 || 'Minimum value is 1.',
          }
        })}
        label="Max Capacity"
        type="text"
        name={extraField}
        className={className}
        error={Boolean(errors[extraField])}
        helperText={errors[extraField] ? errors[extraField]["message"] : ``}
      />}
    </ConnectForm>}
  },
  minutesToExitAfterUnlock: {
    component: ({extraField, className}) => { return <ConnectForm>
      {({ control }) => <Controller
        render={({ onChange, value }) => (<FormControl className={className}>
          <InputLabel id="demo-simple-select-label">Average Visit Length</InputLabel>
            <Select
              value={value}
              onChange={(e,value) => {
                return onChange(e)
              }}
              displayEmpty
            >
              {exitAfterUnlockChoices.map(exitAfterUnlockChoice => (
                <MenuItem value={exitAfterUnlockChoice.value} key={exitAfterUnlockChoice.value}>{exitAfterUnlockChoice.label}</MenuItem>
              ))}
            </Select>
          </FormControl>)}
        name={extraField}
        // onChange={(e) => e[1]}
        control={control}
      />}
    </ConnectForm>}
  },
  // https://react-hook-form.com/advanced-usage/#ConnectForm
  barStacked: {
    component: ({extraField, className}) => { return <ConnectForm>
      {({ control }) => <FormControlLabel
        control={
          <Controller
            name={extraField}
            control={control}
            render={({ onChange, value }) => (
              <Checkbox
                checked={value}
                onChange={(e,value) => {
                  return onChange(value)
                }}
              />
            )}
          />
        }
        label="Stacked"
      />}
    </ConnectForm>}
  },
}
export const dashboardItemLibrary = [
  {
    "title": "Unlocks Over Time",
    "timeZone": "USER",
    "description": "This will show you the unlocks over time.",
    "rules": {
      "maxLocksAllowed": Infinity,
    },
    "vizState": {
      "query": {
        "measures": [
          "Unlock.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Unlock.actionDateUTC",
            "granularity": "day",
            "dateRange": "from 7 days ago to now"
          }
        ],
        "dimensions": [
          // "Lock.lockId"
          "Lock.shortName"
        ],
        "filters": [],
        "order": {
          "Unlock.actionDateUTC": "asc",
          "Lock.lockId": "desc"
        }
      },
      "chartType": "BarCustom",
      "orderMembers": [
        {
          "id": "Unlock.count",
          "title": "Unlock Count",
          "order": "none"
        },
        {
          "id": "Lock.lockId",
          "title": "Lock Lock Short Name",
          "order": "none"
        },
        {
          "id": "Unlock.actionDateUTC",
          "title": "Unlock Action Date",
          "order": "desc"
        }
      ],
      "pivotConfig": {
        "x": [
          "Lock.shortName",
          "Unlock.actionDateUTC.month"
        ],
        "y": [
          "measures"
        ],
        "fillMissingDates": true,
        "joinDateRange": false
      },
      "shouldApplyHeuristicOrder": true,
    },
    "extra": {
      "barStacked": true
    },
    "objectId": "m3SryEYnyj",
  },
  {
    "title": "Traffic",
    "timeZone": "USER",
    "description": "This will show total unlocks by lock.",
    "rules": {
      "maxLocksAllowed": Infinity,
      "hideFrequency": true
    },
    "vizState": {
      "query": {
        "measures": [
          "Unlock.count"
        ],
        "timeDimensions": [
          {
            "dimension": "Unlock.actionDateUTC",
            "granularity": null,
            "dateRange": "from 7 days ago to now"
          }
        ],
        "dimensions": [
          // "Lock.lockId"
          "Lock.shortName"
        ],
        // filters could work but not ideal B/C relative dates
        // "filters": [
        //   {
        //     "dimension": "Unlock.actionDateUTC",
        //     "operator": "gte",
        //     "values": [
        //       "2021-01-20T14:08:49.000"
        //     ]
        //   }
        // ],
        "order": {
          "Unlock.count": "desc",
          "Lock.lockId": "desc"
        }
      },
      "chartType": "Pie",
      "orderMembers": [
        {
          "id": "Unlock.count",
          "title": "Unlock Count",
          "order": "none"
        }
      ],
      "pivotConfig": {
        "x": [],
        "y": [
          "measures"
        ],
        "fillMissingDates": true
      },
      "shouldApplyHeuristicOrder": true
    },
    "extra": {},
    "objectId": "WTEpny1OSV",
  },
  {
    "title": "Space Utilization",
    "timeZone": "UTC",
    "description": "This will show the room density.",
    "rules": {
      "maxLocksAllowed": Infinity,
      "hideFrequency": true
    },
    "vizState": {
      "query": {
        "dimensions": [
          "Unlock.objectId",
          "Lock.displayName",
          "Lock.portalLink",
          "User.displayName",
          "User.portalLink",
          "Unlock.actionDateUTC"
        ],
        // "timeDimensions": [],
        "timeDimensions": [
          {
            "dimension": "Unlock.actionDateUTC",
            "granularity": "day",
            "dateRange": "Last week"
          }
        ],
        "order": {
          "KeyRelationship.countKeys": "desc"
        }
      },
      // "chartType": "scatter",
      "chartType": "Areasplinerange",
      // "chartType": "table",
      "orderMembers": [
        {
          "id": "Unlock.objectId",
          "title": "Unlock ID",
          "order": "none"
        },
        {
          "id": "Lock.displayName",
          "title": "Lock Display Name",
          "order": "none"
        },
        {
          "id": "Lock.portalLink",
          "title": "Lock Portal Link",
          "order": "none"
        },
        {
          "id": "User.displayName",
          "title": "User Display Name",
          "order": "none"
        },
        {
          "id": "User.portalLink",
          "title": "User Portal Link",
          "order": "none"
        },
        {
          "id": "Unlock.actionDateUTC",
          "title": "Unlock Action Date",
          "order": "desc"
        }
      ],
      "pivotConfig": {
        "x": [
          "Unlock.objectId",
          "Lock.displayName",
          "Lock.portalLink",
          "User.displayName",
          "User.portalLink",
          "Unlock.actionDateUTC"
        ],
        "y": [],
        "fillMissingDates": true,
        "joinDateRange": false
      },
      "shouldApplyHeuristicOrder": true
    },
    "extra": {},
    "objectId": "gibGZJgqmd",
  },
  {
    "title": "Occupancy",
    "timeZone": "UTC",
    "description": "This will show the room occupants.",
    "rules": {
      "maxLocksAllowed": Infinity,
      "hideFrequency": true,
      "allowedTimeDimensions": [
        {title: 'Last 24 hours',value: 'from 1 days ago to now'},
        {title: 'Last 2 days',value: 'from 2 days ago to now'},
        {title: 'Last 3 days',value: 'from 3 days ago to now'},
        {title: 'Last 4 days',value: 'from 4 days ago to now'},
        {title: 'Last 5 days',value: 'from 5 days ago to now'},
        {title: 'Last 6 days',value: 'from 6 days ago to now'},
        {title: 'Last 7 days',value: 'from 7 days ago to now'},
      ],
    },
    "vizState": {
      "query": {
        "dimensions": [
          "Unlock.objectId",
          "Lock.displayName",
          "Lock.portalLink",
          "User.displayName",
          "User.portalLink",
          "Unlock.actionDateUTC"
        ],
        // "timeDimensions": [],
        "timeDimensions": [
          {
            "dimension": "Unlock.actionDateUTC",
            // "granularity": "",
            "dateRange": "from 1 days ago to now"
          }
        ],
        "order": {
          "Unlock.actionDateUTC": "asc"
        }
      },
      "chartType": "Cumulative",
      "orderMembers": [
        {
          "id": "Unlock.objectId",
          "title": "Unlock ID",
          "order": "none"
        },
        {
          "id": "Lock.displayName",
          "title": "Lock Display Name",
          "order": "none"
        },
        {
          "id": "Lock.portalLink",
          "title": "Lock Portal Link",
          "order": "none"
        },
        {
          "id": "User.displayName",
          "title": "User Display Name",
          "order": "none"
        },
        {
          "id": "User.portalLink",
          "title": "User Portal Link",
          "order": "none"
        },
        {
          "id": "Unlock.actionDateUTC",
          "title": "Unlock Action Date",
          "order": "desc"
        }
      ],
      "pivotConfig": {
        "x": [
          "Unlock.objectId",
          "Lock.displayName",
          "Lock.portalLink",
          "User.displayName",
          "User.portalLink",
          "Unlock.actionDateUTC"
        ],
        "y": [],
        "fillMissingDates": true,
        "joinDateRange": false
      },
      "shouldApplyHeuristicOrder": true
    },
    "extra": {
      "maxCapacity": 20,
      "minutesToExitAfterUnlock": 120
    },
    "objectId": "O60AdvS1Ii",
  },
  // {
  //   "title": "Users With Access And No Unlocks",
  //   "timeZone": "USER",
  //   "description": "This will show users' keys that have not been used the past thirty days.",
  //   "rules": {
  //     "maxLocksAllowed": Infinity
  //   },
  //   "vizState": {
  //     "query": {
  //       "dimensions": [
  //         "Lock.shortName"
  //       ],
  //       "timeDimensions": [],
  //       "measures": [
  //         "KeyRelationship.countKeys"
  //       ],
  //       "filters": [
  //         {
  //           "dimension": "MostRecentUnlock.daysAgo",
  //           "operator": "gte",
  //           "values": [
  //             "30"
  //           ]
  //         }
  //       ],
  //       "order": {
  //         "KeyRelationship.countKeys": "desc"
  //       }
  //     },
  //     "chartType": "pie",
  //     "orderMembers": [
  //       {
  //         "id": "KeyRelationship.countKeys",
  //         "order": "desc"
  //       },
  //       {
  //         "id": "Lock.shortName",
  //         "order": "none"
  //       }
  //     ],
  //     "pivotConfig": {
  //       "x": [
  //         "Lock.shortName"
  //       ],
  //       "y": [
  //         "measures"
  //       ],
  //       "fillMissingDates": true,
  //       "joinDateRange": false
  //     },
  //     "shouldApplyHeuristicOrder": true
  //   },
  //   "extra": {},
  //   "objectId": "jZSnFMFuhG",
  // },
  // {
  //   "title": "Recent Unlocks",
  //   "timeZone": "USER",
  //   "maxLocksAllowed": Infinity,
  //   "description": "This will show most recent unlocks.",
  //   "vizState": {
  //     "query": {
  //       "dimensions": [
  //         "Unlock.objectId",
  //         "Lock.displayName",
  //         "Lock.portalLink",
  //         "User.displayName",
  //         "User.portalLink",
  //         "Role.name",
  //         "Unlock.type",
  //         "Unlock.createdAtUTC",
  //         "Unlock.actionDateUTC"
  //       ],
  //       "filters": [
  //         {
  //           "dimension": "Lock.lockId",
  //           "operator": "equals",
  //           "values": [
  //             "6316"
  //           ]
  //         }
  //       ],
  //       // https://cube.dev/docs/query-format#time-dimensions-format
  //       "timeDimensions": [
  //         {
  //           "dimension": "Unlock.actionDateUTC",
  //           "dateRange": [
  //             // "2021-01-20"
  //             "2021-01-20T00:00:01.000", "2021-01-20T23:59:59.999"
  //             // "2021-01-20","2021-01-21"
  //           ]
  //         }
  //       ],
  //       "order": {
  //         "Unlock.actionDateUTC": "desc"
  //       }
  //     },
  //     "chartType": "table",
  //     "orderMembers": [
  //       {
  //         "id": "Unlock.objectId",
  //         "title": "Unlock ID",
  //         "order": "none"
  //       },
  //       {
  //         "id": "Lock.displayName",
  //         "title": "Lock Display Name",
  //         "order": "none"
  //       },
  //       {
  //         "id": "Lock.portalLink",
  //         "title": "Lock Portal Link",
  //         "order": "none"
  //       },
  //       {
  //         "id": "User.displayName",
  //         "title": "User Display Name",
  //         "order": "none"
  //       },
  //       {
  //         "id": "User.portalLink",
  //         "title": "User Portal Link",
  //         "order": "none"
  //       },
  //       {
  //         "id": "Role.name",
  //         "title": "Role Name",
  //         "order": "none"
  //       },
  //       {
  //         "id": "Unlock.type",
  //         "title": "Unlock Unlock Type",
  //         "order": "none"
  //       },
  //       {
  //         "id": "Unlock.createdAtUTC",
  //         "title": "Unlock Created Date",
  //         "order": "none"
  //       },
  //       {
  //         "id": "Unlock.actionDateUTC",
  //         "title": "Unlock Action Date",
  //         "order": "desc"
  //       }
  //     ],
  //     "pivotConfig": {
  //       "x": [
  //         "Unlock.objectId",
  //         "Lock.displayName",
  //         "Lock.portalLink",
  //         "User.displayName",
  //         "User.portalLink",
  //         "Role.name",
  //         "Unlock.type",
  //         "Unlock.createdAtUTC",
  //         "Unlock.actionDateUTC"
  //       ],
  //       "y": [],
  //       "fillMissingDates": true,
  //       "joinDateRange": false
  //     },
  //     "shouldApplyHeuristicOrder": true
  //   },
  //   "extra": {},
  //   "objectId": "naoF2uMmdQ",
  // },
  {
    "title": "People",
    "timeZone": "USER",
    "description": "This will show total unique people using your locks.",
    "rules": {
      "maxLocksAllowed": Infinity,
      "compareDates": true,
      "hideFrequency": true
    },
    "vizState": {
      "query": {
        "measures": [
          "Unlock.uniqueUserCount"
        ],
        "timeDimensions": [
          {
            "dimension": "Unlock.actionDateUTC",
            "granularity": "month",
            "compareDateRange": ['This week', 'Last week']
          }
        ],
        "order": {}
      },
      "chartType": "Number",
      "orderMembers": [
        {
          "id": "Unlock.uniqueUserCount",
          "title": "Unlock Unique User Count",
          "order": "none"
        }
      ],
      "pivotConfig": {
        "x": [],
        "y": [
          "measures"
        ],
        "fillMissingDates": true
      },
      "shouldApplyHeuristicOrder": true
    },
    "extra": {},
    "objectId": "YRYRlmRaWp",
  }
]