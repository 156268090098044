import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock14Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 48 48'} {...props} ref={forwardedRef}>
      <path d="M40.5,27.2h-2l-11.9,-7.3c0.1,-0.3 0.1,-0.6 0.1,-0.9c0,-0.6 -0.4,-1 -1,-1s-1,0.4 -1,1c0,0.7 -0.6,1.3 -1.3,1.3S22,19.7 22,19s0.6,-1.3 1.3,-1.3c0.6,0 1,-0.4 1,-1v-1.6h1.6c0.5,0 0.9,-0.3 1,-0.7l2.2,-8.7c0.2,-0.6 -0.3,-1.3 -1,-1.3h-3.9V1c0,-0.6 -0.4,-1 -1,-1s-1,0.4 -1,1v3.4h-3.9c-0.7,0 -1.1,0.6 -1,1.3l2.2,8.7c0.1,0.4 0.5,0.7 1,0.7h1.6v0.7C21,16.2 20,17.5 20,19c0,0.3 0.1,0.6 0.1,0.9L8.2,27.2H7.5c-1.6,0 -2.9,1.3 -2.9,2.9v15c0,1.6 1.3,2.9 2.9,2.9h33c1.6,0 2.9,-1.3 2.9,-2.9v-15C43.4,28.5 42.1,27.2 40.5,27.2zM26.9,6.4l-0.7,2.7h-5.7l-0.7,-2.7H26.9zM21,11.1h4.7l-0.5,2h-3.7L21,11.1zM24.3,22.1c0.4,-0.1 0.8,-0.3 1.1,-0.6l9.2,5.7H24.3V22.1zM21.2,21.5c0.3,0.3 0.7,0.5 1.1,0.6v5.1H12L21.2,21.5zM41.4,45.1c0,0.5 -0.4,0.9 -0.9,0.9h-33c-0.5,0 -0.9,-0.4 -0.9,-0.9v-15c0,-0.5 0.4,-0.9 0.9,-0.9c12.2,0 20.7,0 33,0c0.5,0 0.9,0.4 0.9,0.9V45.1z"></path>
      <path d="M36,31.7c-0.6,0 -1,0.4 -1,1v9.8c0,0.6 0.4,1 1,1s1,-0.4 1,-1v-9.8C37,32.1 36.6,31.7 36,31.7z"></path>
      <path d="M28,31.7c-0.6,0 -1,0.4 -1,1v9.8c0,0.6 0.4,1 1,1s1,-0.4 1,-1v-9.8C29,32.1 28.6,31.7 28,31.7z"></path>
      <path d="M20,31.7c-0.6,0 -1,0.4 -1,1v9.8c0,0.6 0.4,1 1,1s1,-0.4 1,-1v-9.8C21,32.1 20.5,31.7 20,31.7z"></path>
      <path d="M12,31.7c-0.6,0 -1,0.4 -1,1v9.8c0,0.6 0.4,1 1,1s1,-0.4 1,-1v-9.8C13,32.1 12.5,31.7 12,31.7z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock14Icon {...props} forwardedRef={ref} />)