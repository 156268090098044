import React from 'react';
import Box from '@material-ui/core/Box';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useSnackbar } from 'notistack';
import { useStateValue } from '../utils/state';
import * as Constants from '../utils/constants'
import { fetchConfig } from '../utils/api'

import Loading from './Loading'

function Config({ children }) {
  // const classes = useStyles();
  const [{ currentConfig }, dispatchState] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const [retries, setRetries] = React.useState(0);

  const maxRetriesHit = retries >= Constants.maxReinitializationAttempts

  React.useEffect(() => {
    console.log(process.env.REACT_APP_STAGE, 'want to check /api/config', {...currentConfig})
    if (!currentConfig) {
      fetchConfig()
      .then((config) => {
        // config.termsOfServiceVersion = 99
        dispatchState({
          type: 'changeCurrentConfig',
          newCurrentConfig: config
        })
      })
      .catch(({ message }) => {
        if (retries < Constants.maxReinitializationAttempts) {
          setTimeout(() => setRetries(retries + 1), Constants.reinitializationRetryDelay)
        }
        else {
          enqueueSnackbar(message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }
      })
    }
  }, [enqueueSnackbar, currentConfig, dispatchState, retries])

  return (<React.Fragment>
    {!currentConfig && maxRetriesHit && <Box display="flex" height="100vh" flexBasis="100%" justifyContent="center" alignItems="center">
        <Box align="center">
          <ErrorOutlineIcon color="primary" fontSize="large" /><br />
          <p>Application failed to load.</p>
        </Box>
      </Box>}
      {!currentConfig && !maxRetriesHit && <Loading height="100vh" text='Initializing' />}
      {currentConfig && <>{children}</>}
  </React.Fragment>)
}

export default Config;