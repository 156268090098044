import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock24Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 59 68'} {...props} ref={forwardedRef}>
      <path d="M13.0098932 16L52.9901068 16C54.1032982 16 55 16.8955246 55 18.009763L55 19.990237C55 21.100707 54.1008696 22 52.9901068 22L13.0098932 22C11.8967018 22 11 21.1044754 11 19.990237L11 18.009763C11 16.899293 11.8991304 16 13.0098932 16zM7 19.990237C7 23.3147915 9.6887382 26 13.0098932 26L52.9901068 26C56.3101705 26 59 23.3096841 59 19.990237L59 18.009763C59 14.6852085 56.3112618 12 52.9901068 12L13.0098932 12C9.68982953 12 7 14.6903159 7 18.009763L7 19.990237zM16.1700916 49.3607329C17.2712691 48.6482156 18 47.4092099 18 46 18 43.790861 16.209139 42 14 42 11.790861 42 10 43.790861 10 46 10 47.4092099 10.7287309 48.6482156 11.8299084 49.3607329L11 56 17 56 16.1700916 49.3607329 16.1700916 49.3607329z"></path>
      <path d="M24,53.9986811 C24,59.5228471 19.5237146,64 14,64 C8.47761673,64 4,59.5219389 4,53.9996487 L4,14.0003513 C4,8.47675581 8.47624925,4 14,4 C19.2210031,4 24,8.2310067 24,12 C24,13.1045695 24.8954305,14 26,14 C27.1045695,14 28,13.1045695 28,12 C28,5.90605372 21.3290078,0 14,0 C6.26700892,0 0,6.26771813 0,14.0003513 L0,53.9996487 C0,61.730989 6.26838886,68 14,68 C21.7330272,68 28,61.7318126 28,53.9986811 L28,26 C28,24.8954305 27.1045695,24 26,24 C24.8954305,24 24,24.8954305 24,26 L24,53.9986811 Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock24Icon {...props} forwardedRef={ref} />)