import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock8Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="m23.098,6c-2.806,0 -5.098,2.327 -5.098,5.123l0,77.763c0,2.793 2.292,5.114 5.098,5.114l53.803,0c2.807,0 5.099,-2.321 5.099,-5.114l0,-77.763c0,-2.797 -2.292,-5.123 -5.099,-5.123l-53.803,0zM23.098,10 L76.901,10c0.61,0 1.099,0.487 1.099,1.124l0,77.763c0,0.62 -0.489,1.114 -1.099,1.114l-53.803,0c-0.61,0 -1.099,-0.493 -1.099,-1.114l0,-77.763c0,-0.637 0.489,-1.124 1.099,-1.124z"></path>
      <path d="m28.056,16.057a2,2 0,0 0,-2 2l0,63.875a2,2 0,0 0,2 2l21.813,0 0.125,0 0.188,0 21.75,0a2,2 0,0 0,2 -2l0,-63.875a2,2 0,0 0,-2 -2l-21.937,0 -21.938,0zM30.056,20.057 L47.993,20.057 47.993,47.995 30.056,47.995 30.056,20.057zM51.993,20.057 L69.931,20.057 69.931,47.995 51.993,47.995 51.993,20.057zM30.056,51.995 L47.993,51.995 47.993,79.932 30.056,79.932 30.056,51.995zM51.993,51.995 L69.931,51.995 69.931,79.932 51.993,79.932 51.993,51.995z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock8Icon {...props} forwardedRef={ref} />)