import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock18Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="m16.25,7.063 l-13,24c-0.158,0.286 -0.245,0.611 -0.25,0.938l0,5c0,4.647 2.912,8.643 7,10.25l0,44.75c0,1.047 0.953,2 2,2l76,0c1.047,-0 2,-0.953 2,-2l0,-44.75c4.088,-1.607 7,-5.603 7,-10.25l0,-5c-0.005,-0.327 -0.092,-0.652 -0.25,-0.938l-13,-24c-0.336,-0.633 -1.034,-1.056 -1.75,-1.063l-64,0c-0.818,0.001 -1.325,0.506 -1.75,1.063zM19.187,10 L28.219,10 21.531,30 8.344,30zM32.437,10 L41.719,10 39.219,30 25.781,30zM45.781,10 L54.219,10 56.719,30 43.281,30zM58.281,10 L67.563,10 74.219,30 60.781,30zM71.781,10 L80.813,10 91.656,30 78.469,30zM7,34 L21,34 21,37c0,3.913 -3.087,7 -7,7 -3.913,0 -7,-3.087 -7,-7zM25,34 L39,34 39,37c0,3.913 -3.087,7 -7,7 -3.913,0 -7,-3.087 -7,-7zM43,34 L57,34 57,37c0,3.913 -3.087,7 -7,7 -3.913,0 -7,-3.087 -7,-7zM61,34 L75,34 75,37c0,3.913 -3.087,7 -7,7 -3.913,0 -7,-3.087 -7,-7zM79,34 L93,34 93,37c0,3.913 -3.087,7 -7,7 -3.913,0 -7,-3.087 -7,-7zM23,43.313c1.993,2.835 5.284,4.688 9,4.688 3.716,0 7.007,-1.853 9,-4.688 1.993,2.835 5.284,4.688 9,4.688 3.716,0 7.007,-1.853 9,-4.688 1.993,2.835 5.284,4.688 9,4.688 3.716,0 7.007,-1.853 9,-4.688 1.993,2.835 5.284,4.688 9,4.688l0,42 -40,0 0,-30c-0,-1.047 -0.953,-2 -2,-2l-20,0c-1.047,0 -2,0.953 -2,2l0,30 -8,0 0,-42c3.716,0 7.007,-1.853 9,-4.688zM53.813,58c-0.983,0.093 -1.817,1.013 -1.813,2l0,14c0,1.047 0.953,2 2,2l22,0c1.047,-0 2,-0.953 2,-2l0,-14c-0,-1.047 -0.953,-2 -2,-2l-22,0c-0.063,0 -0.125,0 -0.188,0zM26,62 L42,62 42,90 26,90zM56,62 L74,62 74,72 56,72zM38,74c-1.105,0 -2,0.895 -2,2 0,1.105 0.895,2 2,2 1.105,0 2,-0.896 2,-2 0,-1.105 -0.895,-2 -2,-2z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock18Icon {...props} forwardedRef={ref} />)