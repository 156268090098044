import React, { useEffect } from "react";
import ReactGA from "react-ga";

// if ('REACT_APP_GA' in process.env && process.env.REACT_APP_GA!== '') {
//   ReactGA.initialize(process.env.REACT_APP_GA, {
//     debug: true,
//     titleCase: false,
//   })
//   console.log('GA', process.env.REACT_APP_GA)
// }

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};