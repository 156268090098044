import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock21Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M17.47,66.54h3.67a2,2 0,1 0,0 -4H17.47a2,2 0,0 0,0 4Z"></path>
      <path d="M21.13,72.54H17.47a2,2 0,0 0,0 4h3.67a2,2 0,1 0,0 -4Z"></path>
      <path d="M21.13,82.54H17.47a2,2 0,0 0,0 4h3.67a2,2 0,1 0,0 -4Z"></path>
      <path d="M37.3,41.58a2,2 0,0 0,4 0V35.25a2,2 0,1 0,-4 0Z"></path>
      <path d="M49.3,43.58a2,2 0,0 0,2 -2V35.25a2,2 0,1 0,-4 0v6.33A2,2 0,0 0,49.3 43.58Z"></path>
      <path d="M59.3,43.58a2,2 0,0 0,2 -2V35.25a2,2 0,1 0,-4 0v6.33A2,2 0,0 0,59.3 43.58Z"></path>
      <path d="M39.3,53.25a2,2 0,0 0,-2 2v6.33a2,2 0,0 0,4 0V55.25A2,2 0,0 0,39.3 53.25Z"></path>
      <path d="M49.3,63.58a2,2 0,0 0,2 -2V55.25a2,2 0,1 0,-4 0v6.33A2,2 0,0 0,49.3 63.58Z"></path>
      <path d="M59.3,63.58a2,2 0,0 0,2 -2V55.25a2,2 0,1 0,-4 0v6.33A2,2 0,0 0,59.3 63.58Z"></path>
      <path d="M39.3,73.25a2,2 0,0 0,-2 2v6.33a2,2 0,0 0,4 0V75.25A2,2 0,0 0,39.3 73.25Z"></path>
      <path d="M49.3,83.58a2,2 0,0 0,2 -2V75.25a2,2 0,0 0,-4 0v6.33A2,2 0,0 0,49.3 83.58Z"></path>
      <path d="M59.3,83.58a2,2 0,0 0,2 -2V75.25a2,2 0,0 0,-4 0v6.33A2,2 0,0 0,59.3 83.58Z"></path>
      <path d="M50,2.58a2,2 0,0 0,-2 2L48,19.42L37.65,19.42a10,10 0,0 0,-10 10L27.65,52.79h-11a10,10 0,0 0,-10 10L6.65,93.42L5,93.42a2,2 0,0 0,0 4L95,97.42a2,2 0,0 0,0 -4L92.35,93.42v-40a6,6 0,0 0,-6 -6h-4.1v-5a6,6 0,0 0,-6 -6h-5.6v-7a10,10 0,0 0,-10 -10L52,19.42L52,4.58A2,2 0,0 0,50 2.58ZM10.65,62.79a6,6 0,0 1,6 -6h11L27.65,93.42h-17ZM76.25,40.42a2,2 0,0 1,2 2v7a2,2 0,0 0,2 2h6.1a2,2 0,0 1,2 2v40L70.65,93.42v-7.5L78.8,85.92a2,2 0,0 0,0 -4L70.65,81.92v-6L78.8,75.92a2,2 0,0 0,0 -4L70.65,71.92v-6L78.8,65.92a2,2 0,0 0,0 -4L70.65,61.92L70.65,40.42ZM66.65,29.42v64h-35v-64a6,6 0,0 1,6 -6h23A6,6 0,0 1,66.65 29.42Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock21Icon {...props} forwardedRef={ref} />)