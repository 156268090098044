import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import { useForm } from "react-hook-form";
import { formatPhoneNumberToE164 } from '../../utils/tableData'
import * as Constants from '../../utils/constants'
import { fetchLatestTermsOfService } from '../../utils/api'

import AuthContainer from './AuthContainer'
import Terms from './Terms';

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line no-mixed-operators
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export default function SignUp({onLogin,onClick}) {
  const { enqueueSnackbar } = useSnackbar();

  const [terms, setTerms] = React.useState({
    loading: true,
    open: false,
    agreed: false,
    terms: null
  })
  const { register, handleSubmit, errors, setValue, formState } = useForm();
  
  React.useEffect(() => {
    fetchLatestTermsOfService()
      .then((terms) => {
        setValue("attributes.custom:acceptedTosVersion", terms.version, false)
        setTerms(prevState => {
          return { ...prevState, loading: false, url: terms.url };
        })
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        })
      })
  }, [enqueueSnackbar, setValue]);

  const showTerms = async() => {
    if (!terms.agreed) {
      setTerms(prevState => {
        return { ...prevState, open: true };
      })  
    }
    else {
      await handleSubmit(signUp)()
    }
  }

  const handleDecline = () => {
    setTerms(prevState => {
      return { ...prevState, open: false };
    })
  }

  const handleAgree = () => {
    setTerms(prevState => {
      return { ...prevState, open: false, agreed: true };
    })
    handleSubmit(signUp)()
  }

  const signUp = async(values) => {
    values.attributes.phone_number = formatPhoneNumberToE164(values.attributes.phone_number)
    await Auth.signUp(values)
      .then(async(userReg) => {
        if (userReg) {
          // here we need to save the initial password, which is not available unless it
          // this user has migrated.
          await Auth.signIn(values.attributes.phone_number, values.password)
            .then((user) => {
              // this is the first signin... we need to send the correct password to
              onLogin(user, null, values.password)
            })
            .catch(({ message }) => {
              enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              })
            })
        }
      })
      .catch(({ message }) => {
        if (message.includes("ERROR 0:") || message.includes("ERROR 29:")) {
          enqueueSnackbar(`This Phone Number is already registered.`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }
        else {
          enqueueSnackbar(message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }
      })
  }

  const handleClickResetPassword = (event) => {
    event.preventDefault();
    onClick("forgotPassword")
  }

  const handleClickSignin = (event) => {
    event.preventDefault();
    onClick("login")
  }

  return (
    <React.Fragment>
      <AuthContainer title="Sign Up">
        <form onSubmit={handleSubmit(showTerms)} noValidate>
          <input 
            type="hidden"
            name="attributes.custom:acceptedTosVersion"
            ref={register}
            />
          <input 
            type="hidden"
            name="username"
            value={uuidv4()}
            ref={register}
            />
          <TextField
            autoFocus
            variant="filled"
            margin="normal"
            fullWidth
            inputRef={register({ 
              required: {value: true, message: "First Name is required."}
            })}
            name="attributes.given_name"
            label="First Name"
            autoComplete="given-name"
            error={Boolean(errors.attributes) && Boolean(errors.attributes.given_name)}
            helperText={errors.attributes && errors.attributes.given_name ? errors.attributes.given_name.message : ``}
          />
          <TextField
            variant="filled"
            margin="normal"
            fullWidth
            inputRef={register({ 
              required: {value: true, message: "Last Name is required."}
            })}
            name="attributes.family_name"
            label="Last Name"
            autoComplete="family-name"
            error={Boolean(errors.attributes) && Boolean(errors.attributes.family_name)}
            helperText={errors.attributes && errors.attributes.family_name ? errors.attributes.family_name.message : ``}
          />
          <TextField
            variant="filled"
            margin="normal"
            fullWidth
            inputRef={register({ 
              validate: value => formatPhoneNumberToE164(value) !== "" || 'A valid phone is required.'
            })}
            label="Phone"
            name="attributes.phone_number"
            autoComplete="tel"
            error={Boolean(errors.attributes) && Boolean(errors.attributes.phone_number)}
            helperText={errors.attributes && errors.attributes.phone_number ? errors.attributes.phone_number.message : `Your Phone (ex. +1 415 333-4444)`}
          />
          <TextField
            variant="filled"
            margin="normal"
            fullWidth
            inputRef={register({ 
              required: {value: true, message: "New password is required."}, 
              pattern: {
                value: Constants.passwordRegEx,
                message: Constants.passwordRequirementsText // <p>error message</p>
              }
            })}
            name="password"
            autoComplete="new-password"
            label="Password"
            type="password"
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password.message : Constants.passwordRequirementsText}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={terms.loading || terms.open || formState.isSubmitting}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" onClick={handleClickResetPassword} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" onClick={handleClickSignin} variant="body2">
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </AuthContainer>
      <Terms terms={terms} onReject={handleDecline} onAgree={handleAgree} />
    </React.Fragment>
  );
}
