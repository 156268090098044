import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '50vh',
  },
}));

function Terms({terms,onReject,onAgree}) {
  const classes = useStyles()

  const handleReject = () => {
    onReject()
  }

  const handleAgree = () => {
    onAgree()
  }

  return (
    <React.Fragment>
      <Dialog open={terms.open} fullWidth={true} maxWidth={'sm'} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Review Terms</DialogTitle> */}
        <DialogContent>
          <Box className={classes.box}>
            <iframe width="100%" height="100%" src={terms.url} title="Terms of Use" sandbox="allow-same-origin allow-popups"></iframe>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleReject} 
            color="primary">
            Reject
          </Button>
          <Button 
            onClick={handleAgree} 
            variant="contained"
            color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default Terms
