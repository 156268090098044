import React from 'react'
import { Auth } from 'aws-amplify';
import Cookies from "js-cookie";

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PeopleIcon from '@material-ui/icons/People';
import MessageIcon from '@material-ui/icons/Message';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logout } from '../utils/api'

import GroupIcon from '../assets/images/svgIcons/GroupIcon'

import { useStateValue } from '../utils/state';
import * as Constants from '../utils/constants'

import RemoteUnlock from './RemoteUnlock';

const useStyles = makeStyles(theme => ({
  listItemText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '@media (max-height: 800px)' : {
      fontSize: 12,
      lineHeight: 1,
    },
    '@media (max-height: 700px)' : {
      fontSize: 10,
      lineHeight: .8,
    },
    '@media (max-height: 640px)' : {
      display: 'none',
    }
  },
  listItemTextRoot: {
    // marginTop: 2,
    // marginBottom: 2
  },
  listItemRoot: {
    textAlign: 'center',
    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected .MuiListItemIcon-root': {
      background: '#00AAB4'
    },
    '&.MuiButtonBase-root': {
      display: 'block',
    },
    '@media (max-height: 640px)' : {
      // paddingTop: 4,
      paddingBottom: 1
    }
  },
  listItemIconRoot: {
    minWidth: 'auto',
    color: 'white',
  }
}));

const initialHistory = {
  '/people': '/people',
  '/keys': '/keys',
  '/dashboard': '/dashboard',
  '/key-report': '/key-report',
  '/groups': '/groups',
}

export default function Navigation ({ activeItem, history }) {
  const classes = useStyles()
  const [{ currentUser, currentFeatures }, dispatchState] = useStateValue();

  const userObjectId = currentUser && currentUser.hasOwnProperty("objectId") && currentUser.objectId
  const hideDashboard = userObjectId && Constants.nkPowerUsers.has(userObjectId)

  const localeStorageHistoryKey = (currentUser && currentUser.hasOwnProperty("objectId")) ? `_history_${currentUser.objectId}` : "_history"
  const navigationItems = []
  if (!hideDashboard) {
    navigationItems.push({
      label: 'Dashboard',
      icon: <DashboardIcon />,
      path: '/dashboard'
    })  
  }
  navigationItems.push({
    label: 'Keys',
    icon: <VpnKeyIcon />,
    path: '/keys'
  })
  navigationItems.push({
    label: 'People',
    icon: <PeopleIcon />,
    path: '/people'
  })
  if (currentFeatures && currentFeatures.hasOwnProperty("groups") && currentFeatures.groups) {
    navigationItems.push({
      label: 'Groups',
      icon: <GroupIcon fontSize="large" />,
      path: '/groups'
    })
  }
  navigationItems.push({
    label: 'Notifications',
    icon: <NotificationsIcon />,
    path: '/notifications'
  })
  navigationItems.push({
    label: 'Messaging',
    icon: <MessageIcon />,
    path: '/messaging'
  })
  const navigationItemsBottom = []
  navigationItemsBottom.push({
    label: 'Settings',
    icon: <SettingsIcon />,
    path: '/settings'
  })

  const handleListItemClick = (event, index, navigationItems) => {
    const firstPathname = `/${window.location.pathname.split("/")[1]}`
    let link = navigationItems[index].path
    let _history = localStorage.getItem(localeStorageHistoryKey)
    if (_history) {
      _history = JSON.parse(_history)
      if (firstPathname !== link && _history.hasOwnProperty(link)) {
        link = _history[link]
      }
    }
    history.push(link);
  }

  React.useEffect(() => {
    let _history = localStorage.getItem(localeStorageHistoryKey)
    if (!_history) {
      _history = initialHistory
      localStorage.setItem(localeStorageHistoryKey, JSON.stringify(_history));
    }
    else {
      _history = {...initialHistory, ...JSON.parse(_history)}
    }

    const stopListening = history.listen(location => {
      const firstPathname = `/${location.pathname.split("/")[1]}`
      if (_history && _history.hasOwnProperty(firstPathname)) {
        _history[firstPathname] = location.pathname
        if (location.search) {
          _history[firstPathname] += `${location.search}`
        }
        localStorage.setItem(localeStorageHistoryKey, JSON.stringify(_history));  
      }
    })
    return stopListening
  }, [history, localeStorageHistoryKey])

  const handleLogout = () => {
    const legacySessionToken = sessionStorage.getItem("legacySessionToken")
    if (legacySessionToken) {
      logout(legacySessionToken)
        .then(() => { /* expect success */ })
        .catch(({ message }) => { /* silent on error */ })
        .finally(() => {
          // always do this...
          Cookies.remove("sessionToken");
          sessionStorage.removeItem("legacySessionToken")
          dispatchState({
            type: 'changeCurrentUser',
            newCurrentUser: null
          })
          window.location.reload()
        })
    }
    else {
      Auth.signOut()
      .then((user) => {
        window.location.reload()
      })
      .catch((error) => {
        console.log("Auth.currentAuthenticatedUser error", JSON.stringify(error, null, 2))
      })
    }
  }
  
  return (
    <React.Fragment>
      {/* {Cookies.get("_history") && <pre style={{color: 'red'}}>{JSON.stringify(JSON.parse(Cookies.get("_history")), null, 2)}</pre>} */}
    <List>
          {navigationItems.map((navigationItem, index) => (
            <ListItem classes={{ root: classes.listItemRoot }} button disableGutters selected={activeItem === navigationItem.label} key={navigationItem.label} onClick={event => handleListItemClick(event, index, navigationItems)}>
              <ListItemIcon classes={{ root: classes.listItemIconRoot }}>{navigationItem.icon}</ListItemIcon>
              <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} primary={navigationItem.label} />
            </ListItem>
          ))}
          <RemoteUnlock classes={classes} />
    </List>
    <List style={{position: 'fixed', bottom: '0', width: '8.33%'}}>
      {navigationItemsBottom.map((navigationItem, index) => (
        <ListItem classes={{ root: classes.listItemRoot }} button disableGutters selected={activeItem === navigationItem.label} key={navigationItem.label} onClick={event => handleListItemClick(event, index, navigationItemsBottom)}>
          <ListItemIcon classes={{ root: classes.listItemIconRoot }}>{navigationItem.icon}</ListItemIcon>
          <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} primary={navigationItem.label} />
        </ListItem>
      ))}
      <ListItem classes={{ root: classes.listItemRoot }} button disableGutters selected={activeItem === "Support"} key={"Support"} onClick={() => history.push('/support', 'params')}>
        <ListItemIcon classes={{ root: classes.listItemIconRoot }}><HelpIcon /></ListItemIcon>
        <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} primary={"Support"} />
      </ListItem>
      <ListItem classes={{ root: classes.listItemRoot }} button disableGutters key={"Logout"} onClick={handleLogout}>
        <ListItemIcon classes={{ root: classes.listItemIconRoot }}><ExitToAppIcon /></ListItemIcon>
        <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} primary={"Logout"} />
      </ListItem>
    </List>
    </React.Fragment>
  )
}
