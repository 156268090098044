import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock29Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 64 64'} {...props} ref={forwardedRef}>
      <polyline style={{fill: "none", stroke: "#000000", strokeWidth: "2px", strokeMiterlimit: "10px"}} points="16,57.8 16,1 48,1 48,58 41,58  "></polyline>
      <path d="M17,2.5l23,5.1v53.9l-23,-5.1V2.5M15,0v58l27,6V6L15,0L15,0z"></path>
      <ellipse style={{fill: "none", stroke: "#000000", strokeWidth: "2px", strokeMiterlimit: "10px"}} cx="35" cy="32" rx="1.7" ry="1.9"></ellipse>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock29Icon {...props} forwardedRef={ref} />)