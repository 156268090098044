import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify';
import Login from './auth/Login'
import Loading from './Loading'
import Navigation from './Navigation';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useStateValue } from '../utils/state';
import { useSnackbar } from 'notistack';
import * as Constants from '../utils/constants'
import nexkeyLogo from '../assets/images/logo.png';
import { withTracker } from '../utils/withTracker'
import { WebSocketRequestIds } from '../utils/enums';
import Cookies from "js-cookie";
import { updateUserAdvancedFeatures } from '../utils/api'

import ChoosePassword from './ChoosePassword';
import Terms from './Terms';
import Verify from './Verify';
import VerifyChanged from './VerifyChanged';
import ChangeUserInfo from './ChangeUserInfo';
import TestNav from './TestNav';

// const KeyWrapper = React.lazy(() => import('./KeyWrapper'))
const KeysMain = React.lazy(() => import('./KeysMain'))
const ContactsMain = React.lazy(() => import('./ContactsMain'))
const GroupsMain = React.lazy(() => import('./GroupsMain'))
const MessagingMain = React.lazy(() => import('./MessagingMain'))
const Activity = React.lazy(() => import('./Activity'))
const DashboardWrapper = React.lazy(() => import('./DashboardWrapper'))
const UserSettings = React.lazy(() => import('./UserSettings'))
const Support = React.lazy(() => import('./Support'))
const Logout = React.lazy(() => import('./Logout'))

const GlobalCss = withStyles(theme => ({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiContainer-root': {
      padding: '0',
      height: '100vh'
    },
    // '.MuiGrid-container': {
    //   height: '100vh'
    // },
    '.MuiGrid-grid-xs-11': {
      padding: '0 10px',
    },
    '.MuiDialog-paperScrollPaper': {
      maxHeight: 'none'
    },
    '.MuiCardHeader-title': {
      fontSize: '.85rem',
      fontWeight: 'bold',
      color: theme.palette.secondary.main
    },
    // '.MuiCard-root': {
    //   overflow: 'visible'
    // },
    '.role': {
      padding: '2px 6px',
      borderRadius: '4px',
      display: 'inline-block',
      background: 'gray',
      color: 'white',
      fontSize: '.75em',
      textTransform: 'uppercase',
      minWidth: '5em',
      textAlign: 'center',
      border: '1px solid white',
    },
    '.role-Owner': {
      background: '#422784',
      borderColor: '#422784',
    },
    '.role-Administrator': {
      background: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '.role-User': {
      background: 'lightgray',
      borderColor: 'lightgray',
    },
    '.role-GroupAdmin': {
      background: 'white',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '.role-GroupUser': {
      background: 'white',
      color: '#474747',
      borderColor: '#c7c7c7',
    },
    '.role-GroupOwner': {
      background: 'white',
      color: '#422784',
      borderColor: '#422784',
    },
    '.ReactVirtualized__Grid, .ReactVirtualized__Table__row': {
      outline: 'none'
    },
    '.MuiChip-label': {
      padding: '0 8px'
    }
  },
}))(() => null);

export default function Main() {
  const [{ currentConfig, currentUser, currentFeatures }, dispatchState] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const _beta = Cookies.get("_beta")
  const [betaJson, setBetaJson] = React.useState(_beta && JSON.parse(_beta));

  const localeStorageHistoryKey = (currentUser && currentUser.hasOwnProperty("objectId")) ? `_history_${currentUser.objectId}` : "_history"

  let _history = localStorage.getItem(localeStorageHistoryKey)
  if (_history) {
    _history = JSON.parse(_history)
  }
  const defaultHomePage = (currentFeatures && currentFeatures.hasOwnProperty("dashboard") && currentFeatures.dashboard) ? ((_history && _history.hasOwnProperty("/dashboard")) ? _history["/dashboard"] : "/dashboard") : ((_history && _history.hasOwnProperty("/keys")) ? _history["/keys"] : "/keys")

  const handleWebSocket = React.useCallback(async() => {
    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URI)
    
    ws.onopen = () => {
      ws.send(JSON.stringify({
        op: "connect",
        applicationId: process.env.REACT_APP_PARSE_APP_ID
      }))
    }
    ws.onmessage = async(evt) => {
      const message = JSON.parse(evt.data)
      console.log("!!!!! OP", message.op, "MESSAGE", {...message})
  
      if (message.op === "connected") {
        const subscribePayload = {
          op: "subscribe",
          requestId: WebSocketRequestIds.REQUEST_ID_NOTIFICATIONS,
          query: {
            className: "Notification",
            where: {
              toUser: {
                "$in": [{
                  __type: "Pointer",
                  className: "_User",
                  objectId: currentUser.objectId
                }]
              }
            }
          },
        }
        const legacySessionToken = sessionStorage.getItem("legacySessionToken")
        if (legacySessionToken) {
          console.log("!!!!! LOOKING FOR", currentUser.objectId)
          ws.send(JSON.stringify({
            ...subscribePayload,
            sessionToken: legacySessionToken
          }))
        }
        else {
          const session = await Auth.currentSession()
          const accessToken = session.getAccessToken().getJwtToken()
          if (accessToken) {
            console.log("!!!!! LOOKING FOR", currentUser.objectId)
            ws.send(JSON.stringify({
              ...subscribePayload,
              jwtToken: accessToken
            }))
          }
        }
      }
      else if (message.op === "create") {
        console.log("!!!!! ALERT", message.object.message)
        enqueueSnackbar(message.object.message, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }
    }
    ws.onclose = () => {
      console.log('!!!!! disconnected')
    }
  }, [currentUser, enqueueSnackbar])
  
  React.useEffect(() => {
    if (currentUser) handleWebSocket()
  }, [handleWebSocket, currentUser])

  React.useEffect(() => {
    // this is a one-time sync, designed to transfer preference from cookie to backend
    const syncLocalBetaSettings = async(betaJson) => {
      if (betaJson.groups === true) { // only sync when groups is set to true
        const user = await updateUserAdvancedFeatures(currentUser, {groups: true})
        if (user.advancedFeatures) {
          dispatchState({
            type: 'changeCurrentFeatures',
            newCurrentFeatures: user.advancedFeatures
          })
        }
      }
      // step 2: clear _beta cookie
      Cookies.remove("_beta")
      // step 3: clear betaJson
      setBetaJson(null)
    }
    if (betaJson) {
      syncLocalBetaSettings(betaJson)
    }
  }, [betaJson, currentUser, dispatchState])

  const userObjectId = currentUser && currentUser.hasOwnProperty("objectId") && currentUser.objectId
  const hideDashboard = userObjectId && Constants.nkPowerUsers.has(userObjectId)

  return (
    <React.Fragment>
      {currentConfig && <Router>
        <Switch>
          <Route exact path='/choosePassword' render={() => <ChoosePassword />} />
          <Route exact path='/terms'>
            {!currentUser && <Login />}
            {currentUser && currentUser.acceptedTosVersion === currentConfig.termsOfServiceVersion && <Redirect to='/' />}
            {currentUser && <Terms />}
          </Route>
          <Route exact path='/verify'>
            {!currentUser && <Login />}
            {currentUser && <Verify type="primaryPhone" label="Phone Number" />}
          </Route>
          <Route exact path='/verifyEmail'>
            {!currentUser && <Login />}
            {currentUser && <Verify type="email" label="Email" />}
          </Route>
          <Route exact path='/changePassword'>
            {!currentUser && <Login />}
            {currentUser && <ChangeUserInfo type="password" label="Password" />}
          </Route>
          <Route exact path='/changePhoneNumber'>
            {!currentUser && <Login />}
            {currentUser && <ChangeUserInfo type="primaryPhone" label="Phone Number" />}
          </Route>
          <Route exact path='/verifyChangedPhoneNumber'>
            {!currentUser && <Login />}
            {currentUser && <VerifyChanged type="primaryPhone" label="Phone Number" />}
          </Route>
          <Route exact path='/changeEmail'>
            {!currentUser && <Login />}
            {currentUser && <ChangeUserInfo type="email" label="Email" />}
          </Route>
          <Route exact path='/verifyChangedEmail'>
            {!currentUser && <Login />}
            {currentUser && <VerifyChanged type="email" label="Email" />}
          </Route>
          <Route exact path='/testNav'>
            {!currentUser && <Login />}
            {currentUser && <TestNav />}
          </Route>
          <Route>
            {!currentUser && <Login />}
            {(currentUser && (!('acceptedTosVersion' in currentUser) || currentUser.acceptedTosVersion !== currentConfig.termsOfServiceVersion)) && <Redirect to='/terms' />}
            {(currentUser && !currentUser.verified) && <Redirect to='/verify' />}
            {(currentUser && currentUser.verified && !currentUser.emailVerified) && <Redirect to='/verifyEmail' />}
            {(currentUser && currentUser.verified && currentUser.emailVerified) && <Router>
                <GlobalCss />
                <Container maxWidth={false}>
                <Grid container spacing={0}>
                    <Grid item style={{color: 'white'}} xs={1}>
                      <div style={{position: 'fixed', width: '8.33%', background: 'black', height: '200vh'}}>
                        <img id="logo" width="100%" src={nexkeyLogo} alt="" />
                        <Switch>
                          <Route path='/keys' render={(props) => <Navigation activeItem={'Keys'} {...props} />} />
                          <Route path='/people' render={(props) => <Navigation activeItem={'People'} {...props} />} />
                          <Route path='/groups' render={(props) => <Navigation activeItem={'Groups'} {...props} />} />
                          <Route path='/notifications' render={(props) => <Navigation activeItem={'Notifications'} {...props} />} />
                          <Route path='/messaging' render={(props) => <Navigation activeItem={'Messaging'} {...props} />} />
                          <Route path='/settings' render={(props) => <Navigation activeItem={'Settings'} {...props} />} />
                          <Route path='/support' render={(props) => <Navigation activeItem={'Support'} {...props} />} />
                          <Route path='/dashboard' render={(props) => <Navigation activeItem={'Dashboard'} {...props} />} />
                          <Route component={Navigation} />
                        </Switch>
                      </div>
                    </Grid>
                    <Grid item xs={11}>
                      <React.Suspense fallback={<Loading height="100vh" text={'Loading'} />} >
                        <Switch>
                          {!hideDashboard && <Route path='/dashboard'>
                            <Switch>
                              <Route exact path='/dashboard/:dashboardId/create' render={(props) => <DashboardWrapper view={"create"} entityId={props.match.params.dashboardId} />} />
                              <Route exact path='/dashboard/:dashboardId' render={(props) => <DashboardWrapper view={"main"} entityId={props.match.params.dashboardId} />} />
                              <Route render={(props) => <DashboardWrapper view={"main"} />} />
                            </Switch>
                          </Route>}
                          <Route path='/keys'>
                            <Switch>
                              <Route exact path='/keys/:lockId' render={(props) => <KeysMain entityId={props.match.params.lockId} />} />
                              <Route render={(props) => <KeysMain />} />
                            </Switch>
                          </Route>
                          <Route path='/people'>
                            <Switch>
                              <Route exact path='/people/:userId' render={(props) => <ContactsMain entityId={props.match.params.userId} />} />
                              <Route render={(props) => <ContactsMain />} />
                            </Switch>
                          </Route>
                          <Route path='/groups'>
                            <Switch>
                              <Route exact path='/groups/:groupId' render={(props) => <GroupsMain entityId={props.match.params.groupId} />} />
                              <Route render={(props) => <GroupsMain />} />
                            </Switch>
                          </Route>
                          <Route exact path='/messaging' component={withTracker(MessagingMain)} />
                          <Route exact path='/notifications' component={withTracker(Activity)} />
                          <Route exact path='/settings' component={withTracker(UserSettings)} />
                          <Route exact path='/support' component={withTracker(Support)} />
                          <Route path='/logout' render={(props) => <Logout {...props} />} />
                          <Route path='/'>
                            <Redirect to={defaultHomePage} />
                          </Route>
                          <Route render={() => <h1>TBD</h1>} />
                        </Switch>
                      </React.Suspense>
                    </Grid>
                </Grid>
                </Container>
            </Router>}
          </Route>
        </Switch>
      </Router>}
    </React.Fragment>
  );
}
