import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom'
import { withTracker } from '../utils/withTracker'

import { useSnackbar } from 'notistack';
import { verify, resendVerification, me } from '../utils/api'
import { useStateValue } from '../utils/state';
import { formatPhoneNumber } from '../utils/tableData'
import RegistrationStepper from './RegistrationStepper';
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      color: theme.palette.common.white,
      background: 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://images.squarespace-cdn.com/content/v1/5b075626506fbe9b5aba78ed/1559261768545-27USVUEAXVDOOUWCRY2Y/ke17ZwdGBToddI8pDm48kH29ZTyws1X0y1-htsih8-l7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UW2OpHjWwyUeAxEKDBsqn7eKS5fAaxMqUsTUWjFJA6yQMW9u6oXQZQicHHG1WEE6fg/Nexkey-Core-Web.png?format=2500w)',
      backgroundColor: theme.palette.common.black,
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '50vh',
  },
  textField: {
    margin: theme.spacing(2),
    width: '200px',
    "input &:invalid": {
      border: "red solid 2px"
    }
  },
  phoneField: {
    margin: theme.spacing(2),
    marginBottom: 0,
    width: '200px',
    '& div.react-phone-number-input__icon': {
      border: 'none'
    },
    '& div.react-phone-number-input__error': {
      display: 'none'
    },
    '& input.react-phone-number-input__phone': {
      borderBottom: '1px solid #FFFFFF',
      boxShadow: '0 1px 0 #949494'
    },
    '& input.react-phone-number-input__phone:hover': {
      borderBottom: '1px solid #474747',
      boxShadow: '0 1px 0 #474747'
    },
    '& input.react-phone-number-input__phone:focus': {
      borderBottom: '1px solid #00AAB4',
      boxShadow: '0 1px 0 #00AAB4'
    },
    '& input.react-phone-number-input__phone:focus::placeholder': {
      color: 'transparent'
    },
    '& input.react-phone-number-input__input--invalid, input.react-phone-number-input__input--invalid:hover, input.react-phone-number-input__input--invalid:focus': {
      borderBottom: '1px solid #f44336',
      boxShadow: '0 1px 0 #f44336'
    },
    '& input.react-phone-number-input__input--invalid::placeholder': {
      color: '#f44336'
    },
  },
}));

function Verify({type, label, ...props}) {
  const classes = useStyles()
  const [{ currentUser }, dispatchState] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const verificationFormRef = React.useRef(null)
  const changeFormRef = React.useRef(null)

  const [open, setOpen] = React.useState(false);

  const handleClickResendSms = (setSubmitting) => {
    verificationFormRef.current.setSubmitting(true)
    resendVerification(currentUser, type)
      .then(() => {
        enqueueSnackbar(`6-digit verification code re-sent to ${(type === 'primaryPhone') ? formatPhoneNumber(currentUser[type]) : currentUser[type]}`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        })
        verificationFormRef.current.setSubmitting(false)
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        })
        verificationFormRef.current.setSubmitting(false)
    })
  }

  const handleClickChange = () => {
    setOpen(true)
    verificationFormRef.current.resetForm()
  }

  const handleClose = () => {
    setOpen(false)
    changeFormRef.current.resetForm()
  }

  return (
    <React.Fragment>
      <RegistrationStepper activeStep={type === "primaryPhone" ? 2 : currentUser[type] === '' ? 2 : 3} />
      <Formik
          ref={verificationFormRef}
          initialValues={{verificationCode: ''}}
          validationSchema={Yup.object().shape({
            verificationCode: Yup.string().trim()
            .required('6 digit code required.')
            .matches(/^[0-9]{6}$/, '6 digit code required.'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log('onSubmit....', {...values})
            verify(currentUser, type, String(values.verificationCode))
            .then((user) => {
              enqueueSnackbar('Verification successful.', {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              })
              
              dispatchState({
                type: 'changeCurrentEvent',
                newCurrentEvent: {
                  category: 'My Account',
                  action: 'Verified',
                  value: (type === 'primaryPhone') ? 0 : 1
                }
              })
              dispatchState({
                type: 'changeCurrentUser',
                newCurrentUser: {...currentUser, ...user} // this is accounting for the absence of sessionToken in /verifyEmail response 
              })
              setSubmitting(false)
              props.history.push('/')
            })
            .catch(({ message }) => {
              enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              })
              setSubmitting(false)
            })
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
      }) => (
        <Dialog open={currentUser[type] !== ''} fullWidth={true} maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Verify {label}</DialogTitle>
            <DialogContent>
              <Box className={classes.box} align="center">
                <TextField
                  InputLabelProps={{ required: true }}
                  name="verificationCode"
                  label="Verification Code"
                  className={classes.textField}
                  type="number"
                  value={values.verificationCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.verificationCode && touched.verificationCode}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{(type === 'email') ? 'B' : 'A'}-</InputAdornment>,
                  }}
                  helperText={`We sent a 6-digit verification code to ${(type === 'primaryPhone') ? formatPhoneNumber(currentUser[type]) : currentUser[type]}.  Please enter that code.`}
                />
                <p>
                  Didn't receive the verification code?<br />
                  <b><Button variant="contained" color="default" size="small" onClick={(setSubmitting) => handleClickResendSms(setSubmitting)}>Re-send it</Button></b>
                </p>
                <p>
                  Entered the wrong {label.toLowerCase()}?<br />
                  <b><Button variant="contained" color="default" size="small" onClick={handleClickChange}>Change it</Button></b>
                </p>
              </Box>
            </DialogContent>
            <DialogActions>
              {isSubmitting && !open && <CircularProgress size={20} />}
              <Button 
                disabled={(isSubmitting && !open)}
                type="submit"
                color="primary">
                Verify
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
      </Formik>
      <Formik
        ref={changeFormRef}
        initialValues={
          (type === 'email') ? {email: currentUser.email} : {phoneNumber: ''}
        }
        validationSchema={Yup.object().shape(
          (type === 'email') ? {
            email: Yup.string().trim()
              .required('Email is Required')
              .email('Email invalid'),
          } : {
            phoneNumber: Yup.string().trim()
              .required('Phone Number is Required'),
          }
        )}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log('SUBMITTING')
          resendVerification(currentUser, type, values[((type === 'primaryPhone') ? 'phoneNumber' : type)].trim())
            .then(() => {
              setOpen(false)
              me()
                .then((user) => {
                    dispatchState({
                      type: 'changeCurrentUser',
                      newCurrentUser: user
                    })
                    enqueueSnackbar(`6-digit verification code sent to ${(type === 'primaryPhone') ? formatPhoneNumber(user[type]) : user[type]}`, {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                    })
                    setSubmitting(false)
                    resetForm()
                })
                .catch(({ message }) => {
                  enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                  })
                  setSubmitting(false)
                })
            })
            .catch(({ message }) => {
              enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              })
              setSubmitting(false)
            })
        }}
      >{({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched
      }) => (
        <Dialog open={(currentUser[type] === '') || open} maxWidth={'sm'} onClose={handleClose} aria-labelledby="form-dialog-title">
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">{currentUser[type] === '' ? 'Enter' : 'Change'} {label}</DialogTitle>
            <DialogContent>
              {type === 'email' && <TextField
                InputLabelProps={{ required: true }}
                name="email"
                label="Email"
                className={classes.textField}
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                helperText={(errors.email && touched.email) ? errors.email : ' '}
              />}
              {type === 'primaryPhone' && <div className={classes.phoneField}>
                <PhoneInput
                  flagsPath="/images/flags/4x3/"
                  country="US"
                  placeholder="Phone Number *"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={ value => setFieldValue('phoneNumber', value || '', false) }
                  onBlur={ () => setFieldTouched('phoneNumber')}
                  error={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                />
                <p className="MuiFormHelperText-root Mui-error">{(errors.phoneNumber && touched.phoneNumber) ? errors.phoneNumber : ' '}</p>
              </div>}
            </DialogContent>
            <DialogActions>
              {isSubmitting && <CircularProgress size={20} />}
              <Button 
                disabled={isSubmitting}
                onClick={handleClose} 
                color="primary">
                Cancel
              </Button>
              <Button 
                disabled={isSubmitting}
                type="submit"
                color="primary">
                {currentUser[type] === '' ? 'Submit' : 'Change'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
      </Formik>
      <Box position="fixed" bottom="10px" zIndex="999999" width={1}>
        <Footer />
      </Box>
    </React.Fragment>
  )
}

export default withRouter(withTracker(Verify))
