import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class RemoteOfflineIcon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 18 18'} {...props} ref={forwardedRef}>
      <path d="M3.68,12.31a2.45,2.45 0,0 1,0.58 -4.83l0.39,0 0.47,0.08 0,-0.48a3.63,3.63 0,0 1,6.12 -2.38l0.61,-0.62a4.49,4.49 0,0 0,-7.54 2.5H4.26A3.32,3.32 0,0 0,3 13Z"></path>
      <path d="M12.93,5H12.6l-0.15,-0.23 -0.63,0.63c0.06,0.09 0.11,0.18 0.16,0.27l0.14,0.27 0.31,0a3.24,3.24 0,0 1,0.5 -0.05,3.27 3.27,0 1,1 0,6.53H4.84L4,13.24h9a4.13,4.13 0,0 0,0 -8.26Z"></path>
      <path d="M3.11,15.15A0.48,0.48 0,0 1,2.8 15a0.43,0.43 0,0 1,0 -0.61L14.23,3a0.43,0.43 0,0 1,0.61 0.61L3.41,15A0.43,0.43 0,0 1,3.11 15.15Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <RemoteOfflineIcon {...props} forwardedRef={ref} />)