import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock34Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M36.764,69.648H26.679c-1.104,0 -2,-0.896 -2,-2V32.352c0,-1.104 0.896,-2 2,-2h10.085c1.104,0 2,0.896 2,2v35.296C38.764,68.752 37.869,69.648 36.764,69.648zM28.679,65.648h6.085V34.352h-6.085V65.648z" />
      <path d="M26.679,65.236H16.595c-1.104,0 -2,-0.896 -2,-2V36.764c0,-1.104 0.896,-2 2,-2h10.084c1.104,0 2,0.896 2,2v26.472C28.679,64.34 27.784,65.236 26.679,65.236zM18.595,61.236h6.084V38.764h-6.084V61.236z" />
      <path d="M63.236,57.042H36.764c-1.104,0 -2,-0.896 -2,-2V44.958c0,-1.104 0.896,-2 2,-2h26.472c1.104,0 2,0.896 2,2v10.085C65.236,56.147 64.34,57.042 63.236,57.042zM38.764,53.042h22.472v-6.085H38.764V53.042z" />
      <path d="M92.909,57.042h-9.504c-1.104,0 -2,-0.896 -2,-2V44.958c0,-1.104 0.896,-2 2,-2h9.504c1.104,0 2,0.896 2,2v10.085C94.909,56.147 94.014,57.042 92.909,57.042zM85.405,53.042h5.504v-6.085h-5.504V53.042z" />
      <path d="M16.595,57.042H7.091c-1.104,0 -2,-0.896 -2,-2V44.958c0,-1.104 0.896,-2 2,-2h9.504c1.104,0 2,0.896 2,2v10.085C18.595,56.147 17.7,57.042 16.595,57.042zM9.091,53.042h5.504v-6.085H9.091V53.042z" />
      <path d="M73.321,69.648H63.236c-1.104,0 -2,-0.896 -2,-2V32.352c0,-1.104 0.896,-2 2,-2h10.085c1.104,0 2,0.896 2,2v35.296C75.321,68.752 74.425,69.648 73.321,69.648zM65.236,65.648h6.085V34.352h-6.085V65.648z" />
      <path d="M83.405,65.236H73.321c-1.104,0 -2,-0.896 -2,-2V36.764c0,-1.104 0.896,-2 2,-2h10.084c1.104,0 2,0.896 2,2v26.472C85.405,64.34 84.509,65.236 83.405,65.236zM75.321,61.236h6.084V38.764h-6.084V61.236z" />
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock34Icon {...props} forwardedRef={ref} />)