import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import { useForm } from "react-hook-form";
import { trimUserIdentifier, getPropertyValue } from '../../utils/tableData'
import { migrateUser, logInUser } from '../../utils/api'
import Cookies from "js-cookie";

import AuthContainer from './AuthContainer'

const isLegacyUser = (userIdentifier) => {
  const domains = '@fireipad.org|@nexkey.net'
  if (userIdentifier && new RegExp(`(${domains})$`, 'igm').test(userIdentifier)) {
    return true
  }
  return false
}

export default function SignIn({onLogin,onLegacyLogin,onPasswordResetRequired,onWeakPasswordResetRequired,onClick}) {
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit, errors, formState } = useForm();

  const logIn = async(values) => {
    const userCredentials = {...values}
    userCredentials.userIdentifier = trimUserIdentifier(userCredentials.userIdentifier)
    
    if (isLegacyUser(userCredentials.userIdentifier)) {
      // TODO perhaps set legacy mode?
      await logInUser(userCredentials.userIdentifier, userCredentials.password)
      .then((user) => {
        Cookies.set("sessionToken", user.sessionToken);
        sessionStorage.setItem("legacySessionToken", user.sessionToken);
        onLegacyLogin()
      })
      .catch(({ code, message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      })
    }
    else {
      await Auth.signIn(userCredentials.userIdentifier, userCredentials.password)
      .then((user) => {
        onLogin(user)
      })
      .catch(async({ code, message }) => {
        console.log("code", code)
        if (code === "PasswordResetRequiredException") {
          await onPasswordResetRequired(userCredentials.userIdentifier)
        }
        else if (code === "NotAuthorizedException") {
          // this failed, so we can call parse sign in.
          // parse sign in:
          //  - success: migrate user via backend; call Auth.signIn again :)
          //  - failure: cannot
          await migrateUser(userCredentials.userIdentifier, userCredentials.password)
            .then(async(user) => {
              const codeSentTo = getPropertyValue(user, "CodeDeliveryDetails.Destination")
              if (codeSentTo) { // the user was migrated, but had a weak password.
                onWeakPasswordResetRequired(userCredentials.userIdentifier, user, true)
              }
              else {
                await Auth.signIn(userCredentials.userIdentifier, userCredentials.password)
                .then((user) => {
                  onLogin(user)
                })
                .catch(({ code, message }) => {
                  console.log("code", code)
                  enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  })
                })
              }
            })
            .catch(({ message }) => {
              enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              })
            })
        }
        else {
          enqueueSnackbar(message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }
      })
    }
  }
  
  const handleClickResetPassword = (event) => {
    event.preventDefault();
    onClick("forgotPassword")
  }

  const handleClickSignup = (event) => {
    event.preventDefault();
    onClick("signup")
  }

  const signUpEnabled = 'REACT_APP_ENABLE_REGISTRATION' in process.env && process.env.REACT_APP_ENABLE_REGISTRATION === 'true'

  return (
    <AuthContainer title="Sign In">
      <form onSubmit={handleSubmit(logIn)} noValidate>
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            validate: value => trimUserIdentifier(value) !== "" || 'A valid phone or email address is required.'
          })}
          label="Phone or Email Address"
          name="userIdentifier"
          autoComplete="tel"
          error={Boolean(errors.userIdentifier)}
          helperText={errors.userIdentifier ? errors.userIdentifier.message : `Your Email or Phone (ex. +1 415 333-4444)`}
          autoFocus
        />
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            required: {value: true, message: "A password is required"}, 
          })}
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          error={Boolean(errors.password)}
          helperText={errors.password ? errors.password.message : ``}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={formState.isSubmitting}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="#" onClick={handleClickResetPassword} variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            {signUpEnabled && <Link href="#" onClick={handleClickSignup} variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>}
          </Grid>
        </Grid>
      </form>
    </AuthContainer>
  );
}