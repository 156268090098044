import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock19Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M93.8,86.9L93.8,60.1c0,-0.3 -0.1,-0.6 -0.2,-0.8l-9.8,-23.6L83.8,19.1c0,-4.4 -3.6,-8 -8,-8L24.2,11.1c-4.4,0 -8,3.6 -8,8v16.6L6.4,59.3c-0.1,0.2 -0.2,0.5 -0.2,0.8v26.8c0,1.1 0.9,2 2,2s2,-0.9 2,-2v-9.7h79.6L89.8,87c0,1.1 0.9,2 2,2S93.8,88.1 93.8,86.9L93.8,86.9zM24.2,15.1h51.6c2.2,0 4,1.8 4,4v15L75,34.1v-5.8c0,-1.1 -0.9,-2 -2,-2L55.9,26.3c-1.1,0 -2,0.9 -2,2v5.8h-7.8v-5.8c0,-1.1 -0.9,-2 -2,-2L27,26.3c-1.1,0 -2,0.9 -2,2v5.8h-4.8v-15C20.2,16.9 22,15.1 24.2,15.1zM71,30.3v11.2L57.9,41.5L57.9,30.3L71,30.3zM42.1,30.3v11.2L29,41.5L29,30.3L42.1,30.3zM19.5,38.1L25,38.1v5.4c0,1.1 0.9,2 2,2h17.1c1.1,0 2,-0.9 2,-2v-5.4h7.8v5.4c0,1.1 0.9,2 2,2L73,45.5c1.1,0 2,-0.9 2,-2v-5.4h5.4l8.3,20L11.2,58.1L19.5,38.1zM10.2,73.2L10.2,62.1h79.6v11.1L10.2,73.2z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock19Icon {...props} forwardedRef={ref} />)