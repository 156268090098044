import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock10Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M-192,0h185v99h-185z"></path>
      <path d="M0,-36h100v30h-100z"></path>
      <path d="M8,-14.5L18,-14.5"></path>
      <path d="M-179,16.5L-162,16.5"></path>
      <path d="M-170.802,31.318h8.721v8.642h-8.721z"></path>
      <path d="M-164.455,42.312h4.747v-4.703h-4.747V42.312zM-159.266,42.749h-5.63V37.17h5.63V42.749L-159.266,42.749zM-166.221,44.062h8.279v-8.203h-8.279V44.062L-166.221,44.062zM-157.5,44.5h-9.163v-9.079h9.163V44.5L-157.5,44.5z"></path>
      <path d="M-166.149,44.133l-0.143,-0.142l8.279,-8.204l0.142,0.142z"></path>
      <path d="M-126.514,34.815h10.261v10.185h-10.261z"></path>
      <path d="M-126.477,31.766h0.522v2.337h-0.522z"></path>
      <path d="M-116.813,31.766h0.523v2.337h-0.523z"></path>
      <path d="M-127,32.337h11.233v0.572h-11.233z"></path>
      <path d="M-83.805,33.844h10.305v10.156h-10.305z"></path>
      <path d="M-76.809,28.707h3.308v3.261h-3.308z"></path>
      <path d="M-178.5,22.5h30v30h-30z"></path>
      <path d="M-136.5,22.5h30v30h-30z"></path>
      <path d="M-93.5,22.5h30v30h-30z"></path>
      <path d="M-49.5,22.5h30v30h-30z"></path>
      <path d="M56.055,61.534l-1.306,-1.047l-4.467,-3.582c-2.237,0.663 -4.551,0.998 -6.908,0.998c-5.477,0 -10.85,-1.889 -15.13,-5.319c-5.052,-4.049 -8.225,-9.822 -8.933,-16.256c-0.304,-2.758 -0.136,-5.496 0.47,-8.122c-4.141,5.671 -5.225,13.332 -2.153,20.201c3.107,6.947 9.646,11.27 16.722,11.891l3.243,7.25l8.388,3.148l-2.499,6.422l6.45,2.418l-2.499,6.426l6.562,2.459l-2.605,6.688l0.083,0.098l11.3,4.23c1.289,0.488 2.155,0.16 2.706,-1.254l3.525,-9.043l-12.37,-27.672L56.055,61.534z"></path>
      <path d="M36.694,27.896"></path>
      <path d="M20.919,1C14.8,1 9.327,3.903 5.717,8.447c-2.79,3.508 -4.467,7.996 -4.467,12.88c0,7.834 4.32,14.636 10.622,18.027c0.062,-1.849 0.334,-3.696 0.827,-5.51c-3.81,-2.742 -6.316,-7.328 -6.316,-12.517c0,-3.548 1.22,-6.776 3.178,-9.365c2.665,-3.524 6.741,-5.83 11.359,-5.83c8.015,0 14.538,6.816 14.538,15.195c0,2.014 -0.582,3.901 -1.118,5.659c-0.372,1.219 0.002,0.001 0.002,0.001c-0.415,1.038 -0.139,2.267 0.776,3.001c0.589,0.472 1.207,0.57 1.62,0.57c0.801,0 1.546,-0.355 2.042,-0.974c0.255,-0.318 0.414,-0.679 0.499,-1.052c0.832,-2.242 1.31,-4.665 1.31,-7.206C40.588,10.118 31.764,1 20.919,1z"></path>
      <path d="M96.056,71.865l-1,-9.656l-31.68,-25.393c1.083,-6.93 -1.453,-14.229 -7.32,-18.934c-3.764,-3.017 -8.275,-4.461 -12.75,-4.438c0.827,2.474 1.282,5.124 1.282,7.882c0,2.845 -0.499,5.667 -1.484,8.392c-0.24,0.874 -0.644,1.668 -1.203,2.367c-1.259,1.572 -3.141,2.473 -5.164,2.473c-1.512,0 -2.937,-0.5 -4.123,-1.45c-1.799,-1.443 -2.692,-3.671 -2.415,-5.935c0.022,-0.24 0.066,-0.492 0.135,-0.756l0.009,-0.043l0.011,-0.036c0.051,-0.199 0.11,-0.397 0.179,-0.591c0.475,-1.558 0.922,-3.03 0.922,-4.421c0,-1.242 -0.199,-2.433 -0.552,-3.55c-1.19,0.938 -2.303,2.007 -3.291,3.24c-6.993,8.723 -5.588,21.457 3.132,28.446c5.937,4.758 13.73,5.62 20.311,2.936l6.195,4.969l8.9,-1.039l0.708,6.855l6.847,-0.797l0.711,6.855l6.963,-0.813l0.738,7.145l0.121,0.043l11.98,-1.4C95.592,74.057 96.212,73.379 96.056,71.865z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock10Icon {...props} forwardedRef={ref} />)