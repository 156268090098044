import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock2Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M92.5,20V7.5C92.5,6.119 91.381,5 90,5H80c-1.381,0 -2.5,1.119 -2.5,2.5V20c-1.381,0 -2.5,1.119 -2.5,2.5V25H62.5v-2.5c0,-1.381 -1.119,-2.5 -2.5,-2.5H25c-1.381,0 -2.5,1.119 -2.5,2.5V25H15C9.486,25 5,29.486 5,35v10c0,5.514 4.486,10 10,10h7.5v2.5c0,1.381 1.119,2.5 2.5,2.5h35c1.381,0 2.5,-1.119 2.5,-2.5V55H75v2.5c0,1.381 1.119,2.5 2.5,2.5v30c-1.381,0 -2.5,1.119 -2.5,2.5s1.119,2.5 2.5,2.5h15c1.381,0 2.5,-1.119 2.5,-2.5S93.881,90 92.5,90V60c1.381,0 2.5,-1.119 2.5,-2.5v-35C95,21.119 93.881,20 92.5,20zM22.5,50H15c-2.757,0 -5,-2.243 -5,-5V35c0,-2.757 2.243,-5 5,-5h7.5V50zM57.5,55h-30V25h30V55zM75,50H62.5V30H75V50zM82.5,10h5v10h-5V10zM87.5,90h-5V60h5V90zM90,55H80v-2.5v-25V25h10V55z"></path>
      <path d="M36.768,41.768L40,38.535V47.5c0,1.381 1.119,2.5 2.5,2.5s2.5,-1.119 2.5,-2.5v-8.965l3.232,3.232C48.721,42.256 49.36,42.5 50,42.5s1.279,-0.244 1.768,-0.732c0.977,-0.977 0.977,-2.559 0,-3.535l-7.5,-7.5c-0.977,-0.977 -2.559,-0.977 -3.535,0l-7.5,7.5c-0.977,0.977 -0.977,2.559 0,3.535S35.791,42.744 36.768,41.768z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock2Icon {...props} forwardedRef={ref} />)