import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock9Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M73.5,50.8c-7,0 -12.7,5.7 -12.7,12.7s5.7,12.7 12.7,12.7s12.7,-5.7 12.7,-12.7S80.5,50.8 73.5,50.8zM73.5,72.2c-4.8,0 -8.7,-3.9 -8.7,-8.7s3.9,-8.7 8.7,-8.7s8.7,3.9 8.7,8.7S78.3,72.2 73.5,72.2z"></path>
      <path d="M62.3,21.5H52.1c-0.8,0 -1.6,0.5 -1.9,1.3l-4,10.3c-0.2,0.6 -0.2,1.3 0.2,1.9s1,0.9 1.7,0.9h14.2c1.1,0 2,-0.9 2,-2V23.5C64.3,22.4 63.4,21.5 62.3,21.5zM60.3,31.8H51l2.4,-6.3h6.8V31.8z"></path>
      <path d="M73.5,42.7C73.5,42.7 73.5,42.7 73.5,42.7l0,-3c0,0 0,0 0,0V17.7c0,-1.1 -0.9,-2 -2,-2H43.4c-0.8,0 -1.6,0.5 -1.9,1.3L34,37.6H19.8V21.7c0,-1.1 -0.9,-2 -2,-2h-7.5c-1.1,0 -2,0.9 -2,2s0.9,2 2,2h5.5v13.9H7.7c-1.1,0 -2,0.9 -2,2v25.7c0,1.1 0.9,2 2,2h4.3c-0.2,0.9 -0.3,1.9 -0.3,2.8c0,7.8 6.4,14.2 14.2,14.2S40,78 40,70.2c0,-1 -0.1,-1.9 -0.3,-2.8H53c1.8,9.7 10.3,17 20.5,17c11.5,0 20.8,-9.3 20.8,-20.8S85,42.7 73.5,42.7zM44.8,19.7h24.7v17.9H38.3L44.8,19.7zM25.8,80.3c-5.6,0 -10.2,-4.6 -10.2,-10.2c0,-5.6 4.6,-10.2 10.2,-10.2C31.4,60 36,64.6 36,70.2C36,75.8 31.4,80.3 25.8,80.3zM25.8,56c-5.3,0 -10,3 -12.4,7.3H9.7V41.6h25.7h34.1v1.5C60,44.9 52.7,53.3 52.7,63.3H38.2C35.8,59 31.2,56 25.8,56zM73.5,80.3c-9.3,0 -16.8,-7.6 -16.8,-16.8s7.6,-16.8 16.8,-16.8c9.3,0 16.8,7.6 16.8,16.8S82.8,80.3 73.5,80.3z"></path>
      <path d="M24.4,68.8c-0.4,0.4 -0.6,0.9 -0.6,1.4c0,0.5 0.2,1 0.6,1.4c0.4,0.4 0.9,0.6 1.4,0.6c0.5,0 1,-0.2 1.4,-0.6c0.4,-0.4 0.6,-0.9 0.6,-1.4c0,-0.5 -0.2,-1 -0.6,-1.4C26.5,68 25.2,68 24.4,68.8z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock9Icon {...props} forwardedRef={ref} />)