import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock23Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M57.679,53.391c-0.106,0.966 -0.639,2.207 -2.669,2.255H43.88c-0.912,0 -2.391,-0.327 -2.629,-2.255h-4.255c0.176,2.751 2.071,6.478 6.884,6.478h11.145c2.694,0 6.652,-1.741 6.88,-6.478H57.679z"></path>
      <path d="M41.227,47.437c0.106,-0.968 0.637,-2.21 2.653,-2.254h11.145c0.912,0 2.391,0.327 2.63,2.254h4.254c-0.176,-2.751 -2.071,-6.478 -6.884,-6.478H43.88c-2.693,0 -6.651,1.742 -6.879,6.478H41.227z"></path>
      <path d="M42.512,52.104H29.846c-0.933,0 -1.689,-0.757 -1.689,-1.69s0.756,-1.689 1.689,-1.689h12.666c0.933,0 1.689,0.756 1.689,1.689S43.445,52.104 42.512,52.104z"></path>
      <path d="M69.281,52.104H56.615c-0.933,0 -1.689,-0.757 -1.689,-1.69s0.757,-1.689 1.689,-1.689h12.666c0.933,0 1.689,0.756 1.689,1.689S70.213,52.104 69.281,52.104z"></path>
      <path d="M31.537,53.391L31.537,53.391c0,2.821 -2.375,3.085 -3.109,3.099H17.191c-2.828,0 -3.087,-2.382 -3.1,-3.098v-5.21c0,-2.829 2.383,-3.088 3.1,-3.101h11.246c2.152,0 2.816,1.379 3.016,2.355h3.413c-0.297,-2.539 -2.111,-5.734 -6.429,-5.734H17.191c-2.604,0 -6.479,1.726 -6.479,6.479v5.21c0,2.604 1.725,6.477 6.479,6.477h11.246c2.604,0 6.479,-1.725 6.479,-6.477v-0.001H31.537z"></path>
      <path d="M67.614,53.391L67.614,53.391c0,2.821 2.374,3.085 3.109,3.099H81.96c2.827,0 3.087,-2.382 3.1,-3.098v-5.21c0,-2.829 -2.383,-3.088 -3.1,-3.101H70.714c-2.152,0 -2.816,1.379 -3.017,2.355h-3.413c0.297,-2.539 2.111,-5.734 6.43,-5.734H81.96c2.604,0 6.479,1.726 6.479,6.479v5.21c0,2.604 -1.726,6.477 -6.479,6.477H70.714c-2.605,0 -6.479,-1.725 -6.479,-6.477v-0.001H67.614z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock23Icon {...props} forwardedRef={ref} />)