import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock0Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 24 24'} {...props} ref={forwardedRef}>
      <path d="M22,23h-1.5V2.5C20.5,1.673 19.827,1 19,1h-1.5V0.5C17.5,0.224 17.276,0 17,0H7C6.724,0 6.5,0.224 6.5,0.5V1H5C4.173,1 3.5,1.673 3.5,2.5V23H2c-0.276,0 -0.5,0.224 -0.5,0.5S1.724,24 2,24h8c0.276,0 0.5,-0.224 0.5,-0.5V17h3v6.5c0,0.276 0.224,0.5 0.5,0.5h8c0.276,0 0.5,-0.224 0.5,-0.5S22.276,23 22,23zM8.5,19.5C8.5,19.776 8.276,20 8,20H6c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3C5.5,16.224 5.724,16 6,16h2c0.276,0 0.5,0.224 0.5,0.5V19.5zM8.5,13.5C8.5,13.776 8.276,14 8,14H6c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3C5.5,10.224 5.724,10 6,10h2c0.276,0 0.5,0.224 0.5,0.5V13.5zM8.5,7.5C8.5,7.776 8.276,8 8,8H6C5.724,8 5.5,7.776 5.5,7.5v-3C5.5,4.224 5.724,4 6,4h2c0.276,0 0.5,0.224 0.5,0.5V7.5zM13.5,13.5c0,0.276 -0.224,0.5 -0.5,0.5h-2c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3c0,-0.276 0.224,-0.5 0.5,-0.5h2c0.276,0 0.5,0.224 0.5,0.5V13.5zM13.5,7.5C13.5,7.776 13.276,8 13,8h-2c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3C10.5,4.224 10.724,4 11,4h2c0.276,0 0.5,0.224 0.5,0.5V7.5zM18.5,19.5c0,0.276 -0.224,0.5 -0.5,0.5h-2c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3c0,-0.276 0.224,-0.5 0.5,-0.5h2c0.276,0 0.5,0.224 0.5,0.5V19.5zM18.5,13.5c0,0.276 -0.224,0.5 -0.5,0.5h-2c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3c0,-0.276 0.224,-0.5 0.5,-0.5h2c0.276,0 0.5,0.224 0.5,0.5V13.5zM18.5,7.5C18.5,7.776 18.276,8 18,8h-2c-0.276,0 -0.5,-0.224 -0.5,-0.5v-3C15.5,4.224 15.724,4 16,4h2c0.276,0 0.5,0.224 0.5,0.5V7.5z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock0Icon {...props} forwardedRef={ref} />)