import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import { useForm } from "react-hook-form";
import { trimUserIdentifier } from '../../utils/tableData'
import Cookies from "js-cookie";
import { logInUser } from '../../utils/api'

import AuthContainer from './AuthContainer'

export default function LegacySignIn({onLogin}) {
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit, errors, formState } = useForm();

  const logIn = async(values) => {
    const userCredentials = {...values}
    userCredentials.userIdentifier = trimUserIdentifier(userCredentials.userIdentifier)
    await logInUser(userCredentials.userIdentifier, userCredentials.password)
      .then((user) => {
        Cookies.set("sessionToken", user.sessionToken);
        sessionStorage.setItem("legacySessionToken", user.sessionToken);
        onLogin()
      })
      .catch(({ code, message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      })
  }

  return (
    <AuthContainer title="Sign In">
      <form onSubmit={handleSubmit(logIn)} noValidate>
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            validate: value => trimUserIdentifier(value) !== "" || 'A valid phone or email address is required.'
          })}
          label="Phone or Email Address"
          name="userIdentifier"
          autoComplete="tel"
          error={Boolean(errors.userIdentifier)}
          helperText={errors.userIdentifier ? errors.userIdentifier.message : `Your Email or Phone (ex. +1 415 333-4444)`}
          autoFocus
        />
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            required: {value: true, message: "A password is required"}, 
          })}
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          error={Boolean(errors.password)}
          helperText={errors.password ? errors.password.message : ``}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={formState.isSubmitting}
        >
          Sign In
        </Button>
      </form>
    </AuthContainer>
  );
}