import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock20Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 512 512'} {...props} ref={forwardedRef}>
      <path d="M171,130.53a5.85,5.85 0,0 0,3.73 -1.34c0.4,-0.34 0.81,-0.67 1.21,-1a5.86,5.86 0,0 0,-7.37 -9.11l-1.31,1.08A5.85,5.85 0,0 0,171 130.53Z"></path>
      <path d="M106.66,495.69L405.34,495.69a5.85,5.85 0,0 0,5.85 -5.85L411.19,459.9a20.55,20.55 0,0 0,-16.09 -20L395.1,227.3a138.38,138.38 0,0 0,-10.81 -53.76,5.37 5.37,0 0,0 -0.33,-0.78A139.31,139.31 0,0 0,256 88.2a137.6,137.6 0,0 0,-63.12 15.11,5.86 5.86,0 1,0 5.32,10.44A125.92,125.92 0,0 1,256 99.91a127.52,127.52 0,0 1,113.54 69.66L326.37,169.57a137,137 0,0 0,-33.84 -56.24,5.85 5.85,0 0,0 -8.27,8.29 126.25,126.25 0,0 1,36.65 88.85L320.91,439.36L261.86,439.36L261.86,383.44a5.86,5.86 0,0 0,-11.72 0v55.92h-59L191.14,367.67L306,367.67A5.86,5.86 0,1 0,306 356h-44.1L261.9,288.84a5.86,5.86 0,0 0,-11.72 0L250.18,356h-59v-83.4L306,272.6a5.86,5.86 0,1 0,0 -11.71h-44.1v-64.6a5.86,5.86 0,0 0,-11.72 0v64.6h-59L191.18,210.47a125.78,125.78 0,0 1,3.47 -29.19h105.3a5.86,5.86 0,0 0,0 -11.71h-38L261.95,120.16a5.86,5.86 0,0 0,-11.72 0v49.41L198,169.57a125.06,125.06 0,0 1,29.78 -47.95,5.85 5.85,0 1,0 -8.27,-8.29 138,138 0,0 0,-40.09 97.14L179.42,439.36L128.61,439.36L128.61,367.67h35.08a5.86,5.86 0,1 0,0 -11.71L128.61,355.96v-83.4h35.08a5.86,5.86 0,1 0,0 -11.71L128.61,260.85L128.61,227.3a127,127 0,0 1,8.52 -46h31a5.86,5.86 0,1 0,0 -11.71L142.4,169.59a128.38,128.38 0,0 1,12.88 -20.28,5.85 5.85,0 1,0 -9.25,-7.18 137.77,137.77 0,0 0,-29.14 85.19L116.89,439.86a20.55,20.55 0,0 0,-16.08 20v29.94A5.85,5.85 0,0 0,106.66 495.69ZM332.66,272.56h50.77L383.43,356L332.62,356ZM374.83,181.28a126.77,126.77 0,0 1,8.6 46v33.55L332.62,260.83L332.62,210.47a137.07,137.07 0,0 0,-3.16 -29.19ZM332.62,367.67h50.77v71.69L332.62,439.36ZM112.52,459.9a8.84,8.84 0,0 1,8.83 -8.83h269.3a8.84,8.84 0,0 1,8.83 8.83L399.48,484h-287Z"></path>
      <path d="M239.48,55.56a5.85,5.85 0,0 0,3.18 -7.64,14.47 14.47,0 0,1 -1.07,-5.87 14.63,14.63 0,0 1,13.7 -14,14.42 14.42,0 0,1 6.32,27.69A18.43,18.43 0,0 0,250.14 72.6a5.86,5.86 0,0 0,11.72 0,6.77 6.77,0 0,1 4.31,-6.1 26.11,26.11 0,1 0,-34.34 -14.12A5.86,5.86 0,0 0,239.48 55.56Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock20Icon {...props} forwardedRef={ref} />)