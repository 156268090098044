import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock5Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M84,54c0,1.1 -0.9,2 -2,2h-3c-1.1,0 -2,-0.9 -2,-2s0.9,-2 2,-2h3C83.1,52 84,52.9 84,54zM95,39v26c0,1.1 -0.9,2 -2,2h-4.3c-0.9,2.9 -3.5,5 -6.7,5s-5.8,-2.1 -6.7,-5H73c-1.1,0 -2,-0.9 -2,-2v-3H37.3c0.4,0.9 0.7,1.9 0.7,3c0,3.9 -3.1,7 -7,7s-7,-3.1 -7,-7c0,-1.1 0.3,-2.1 0.7,-3h-2.4c0.4,0.9 0.7,1.9 0.7,3c0,3.9 -3.1,7 -7,7s-7,-3.1 -7,-7c0,-1.1 0.3,-2.1 0.7,-3H7c-1.1,0 -2,-0.9 -2,-2V30c0,-1.1 0.9,-2 2,-2h66c1.1,0 2,0.9 2,2v3h14c0.5,0 1,0.2 1.4,0.6l4,4C94.8,38 95,38.5 95,39zM75,37v9h16v-6.2L88.2,37H75zM16,62c-1.7,0 -3,1.3 -3,3s1.3,3 3,3s3,-1.3 3,-3S17.7,62 16,62zM31,62c-1.7,0 -3,1.3 -3,3s1.3,3 3,3s3,-1.3 3,-3S32.7,62 31,62zM71,58V32H9v26H71zM85,65c0,-1.7 -1.3,-3 -3,-3s-3,1.3 -3,3s1.3,3 3,3S85,66.7 85,65zM91,63V50H75v10v3h0.3c0.9,-2.9 3.5,-5 6.7,-5s5.8,2.1 6.7,5H91z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock5Icon {...props} forwardedRef={ref} />)