import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Lock0Icon from '../../assets/images/svgIcons/Lock0Icon'
import Lock1Icon from '../../assets/images/svgIcons/Lock1Icon'
import Lock2Icon from '../../assets/images/svgIcons/Lock2Icon'
import Lock3Icon from '../../assets/images/svgIcons/Lock3Icon'
import Lock4Icon from '../../assets/images/svgIcons/Lock4Icon'
import Lock5Icon from '../../assets/images/svgIcons/Lock5Icon'
import Lock6Icon from '../../assets/images/svgIcons/Lock6Icon'
import Lock7Icon from '../../assets/images/svgIcons/Lock7Icon'
import Lock8Icon from '../../assets/images/svgIcons/Lock8Icon'
import Lock9Icon from '../../assets/images/svgIcons/Lock9Icon'
import Lock10Icon from '../../assets/images/svgIcons/Lock10Icon'
import Lock11Icon from '../../assets/images/svgIcons/Lock11Icon'
import Lock12Icon from '../../assets/images/svgIcons/Lock12Icon'
import Lock13Icon from '../../assets/images/svgIcons/Lock13Icon'
import Lock14Icon from '../../assets/images/svgIcons/Lock14Icon'
import Lock15Icon from '../../assets/images/svgIcons/Lock15Icon'
import Lock16Icon from '../../assets/images/svgIcons/Lock16Icon'
import Lock17Icon from '../../assets/images/svgIcons/Lock17Icon'
import Lock18Icon from '../../assets/images/svgIcons/Lock18Icon'
import Lock19Icon from '../../assets/images/svgIcons/Lock19Icon'
import Lock20Icon from '../../assets/images/svgIcons/Lock20Icon'
import Lock21Icon from '../../assets/images/svgIcons/Lock21Icon'
import Lock22Icon from '../../assets/images/svgIcons/Lock22Icon'
import Lock23Icon from '../../assets/images/svgIcons/Lock23Icon'
import Lock24Icon from '../../assets/images/svgIcons/Lock24Icon'
import Lock25Icon from '../../assets/images/svgIcons/Lock25Icon'
import Lock26Icon from '../../assets/images/svgIcons/Lock26Icon'
import Lock27Icon from '../../assets/images/svgIcons/Lock27Icon'
import Lock28Icon from '../../assets/images/svgIcons/Lock28Icon'
import Lock29Icon from '../../assets/images/svgIcons/Lock29Icon'
import Lock30Icon from '../../assets/images/svgIcons/Lock30Icon'
import Lock31Icon from '../../assets/images/svgIcons/Lock31Icon'
import Lock32Icon from '../../assets/images/svgIcons/Lock32Icon'
import Lock33Icon from '../../assets/images/svgIcons/Lock33Icon'
import Lock34Icon from '../../assets/images/svgIcons/Lock34Icon'
import Lock35Icon from '../../assets/images/svgIcons/Lock35Icon'

import { getPropertyValue } from '../../utils/tableData'

const icons = {
  1: Lock1Icon,
  2: Lock2Icon,
  3: Lock3Icon,
  4: Lock4Icon,
  5: Lock5Icon,
  6: Lock6Icon,
  7: Lock7Icon,
  8: Lock8Icon,
  9: Lock9Icon,
  10: Lock10Icon,
  11: Lock11Icon,
  12: Lock12Icon,
  13: Lock13Icon,
  14: Lock14Icon,
  15: Lock15Icon,
  16: Lock16Icon,
  17: Lock17Icon,
  18: Lock18Icon,
  19: Lock19Icon,
  20: Lock20Icon,
  21: Lock21Icon,
  22: Lock22Icon,
  23: Lock23Icon,
  24: Lock24Icon,
  25: Lock25Icon,
  26: Lock26Icon,
  27: Lock27Icon,
  28: Lock28Icon,
  29: Lock29Icon,
  30: Lock30Icon,
  31: Lock31Icon,
  32: Lock32Icon,
  33: Lock33Icon,
  34: Lock34Icon,
  35: Lock35Icon
}

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: (props) => props.iconFontSize
  },
  iconWrapper: {
    backgroundColor: '#ddd',
    borderRadius: '50%',
    padding: (props) => props.circlePadding,
    width: (props) => props.circleDiameter,
    height: (props) => props.circleDiameter,
    maxWidth: (props) => props.circleDiameter,
    maxHeight: (props) => props.circleDiameter
  }
}));

const LockIcon = ({lock,...props}) => {
  props.iconFontSize = props.iconFontSize || 24
  props.circlePadding = props.circlePadding || 10
  props.circleDiameter = props.circleDiameter || 60
  const classes = useStyles(props);
  const iconType = getPropertyValue(lock, "iconType")

  let iconCode = <Lock0Icon className={classes.icon} color="secondary" />
 
  const IconFile = iconType && icons.hasOwnProperty(iconType) && icons[iconType]
  if (IconFile) {
    iconCode = <IconFile className={classes.icon} color="secondary" />
  }

  return <Box display="flex" alignItems="center" justifyContent="center" className={classes.iconWrapper}>
    {iconCode}
  </Box>
}

export default LockIcon;
