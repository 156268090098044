import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import { useForm } from "react-hook-form";
import { forgotPassword } from '../../utils/api'

import AuthContainer from './AuthContainer'

export default function ForgotPassword({onSuccess,onClick}) {
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit, errors, formState } = useForm();
  
  const confirm = async(values) => {
    const email = values.email.trim()
    await forgotPassword(email)
      .then((user) => {
        onSuccess(email, user)
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      })
  }

  const handleClickSignin = (event) => {
    event.preventDefault();
    onClick("login")
  }

  return (
    <AuthContainer title="Forgot Password">
      <form onSubmit={handleSubmit(confirm)} noValidate>
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            required: {value: true, message: "Email is required."},
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address."
            }
          })}
          label="Email Address"
          name="email"
          autoComplete="tel"
          error={Boolean(errors.email)}
          helperText={errors.email ? errors.email.message : ``}
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={formState.isSubmitting}
        >
          Request Reset Code
        </Button>
        <Grid container>
          <Grid item xs>
            {' '}
          </Grid>
          <Grid item>
            <Link href="#" onClick={handleClickSignin} variant="body2">
              {"Already have an account? Sign In"}
            </Link>
          </Grid>
        </Grid>
      </form>
    </AuthContainer>
  );
}