import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock25Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M96.5,49.51a2.5,2.5 0,0 0,1.71 -4.32L81.38,29.37L81.38,12.12a2.5,2.5 0,0 0,-2.5 -2.5h-10a2.5,2.5 0,0 0,-2.5 2.5v3.2L57.25,6.69a2.5,2.5 0,0 0,-3.44 0L34.26,25.35A2.5,2.5 0,0 0,37.71 29L55.55,12l11.66,11a2.5,2.5 0,0 0,4.21 -1.82L71.42,14.62h5L76.42,30.45a2.5,2.5 0,0 0,0.79 1.82l13,12.24L83.43,44.51a2.5,2.5 0,0 0,-2.5 2.5L80.93,80.44L68.2,80.44L68.2,59a2.5,2.5 0,0 0,-2.5 -2.5h-15a2.5,2.5 0,0 0,-2.5 2.5L48.2,80.44L37.3,80.44a2.5,2.5 0,1 0,0 5L91.93,85.44a2.5,2.5 0,0 0,0 -5h-6L85.93,49.51ZM53.17,61.51h10v19h-10Z"></path>
      <path d="M14.68,70.84V91.48a2.5,2.5 0,0 0,5 0V68.31a2.5,2.5 0,0 0,-2.74 -2.49h-0.11c-0.28,0 -0.56,0.06 -0.84,0.06a10,10 0,0 1,-5 -18.63,2.5 2.5,0 0,0 1.25,-2.15 10,10 0,0 1,20 0,2.5 2.5,0 0,0 1.25,2.15 10,10 0,0 1,-5 18.63c-0.29,0 -0.56,0 -0.84,-0.06h-0.11a2.5,2.5 0,0 0,-2.74 2.49V91.48a2.5,2.5 0,0 0,5 0V70.84a15,15 0,0 0,7.36 -27.15,15 15,0 0,0 -29.82,0 15,15 0,0 0,7.36 27.15Z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock25Icon {...props} forwardedRef={ref} />)