import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import { useForm } from "react-hook-form";
import { trimUserIdentifier } from '../../utils/tableData'

import AuthContainer from './AuthContainer'

export default function ResetPassword({message,username,onSuccess}) {
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit, errors, formState } = useForm({
    defaultValues: {userIdentifier: username}
  });
  
  const confirm = async(values) => {
    await Auth.forgotPasswordSubmit(trimUserIdentifier(values.userIdentifier), values.code, values.password)
      .then(async(user) => {
        console.log("Auth.forgotPasswordSubmit user", JSON.stringify(user, null, 2))
        await onSuccess(values.userIdentifier, values.password)
      })
      .catch(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      })
  }

  return (
    <AuthContainer title="Password Reset">
      <form onSubmit={handleSubmit(confirm)} noValidate>
        {message && <Alert severity="info">{message}</Alert>}
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            validate: value => trimUserIdentifier(value) !== "" || 'A valid phone or email address is required.'
          })}
          label="Phone or Email Address"
          name="userIdentifier"
          autoComplete="tel"
          error={Boolean(errors.userIdentifier)}
          helperText={errors.userIdentifier ? errors.userIdentifier.message : `Your Email or Phone (ex. +1 415 333-4444)`}
          autoFocus={!Boolean(username)}
        />
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            validate: value => value.trim().length === 6 || 'Code must be six characters.'
          })}
          label="Code"
          name="code"
          error={Boolean(errors.code)}
          helperText={errors.code ? errors.code.message : ``}
          autoFocus={Boolean(username)}
        />
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            required: {value: true, message: "A new password is required"}, 
          })}
          name="password"
          autoComplete="new-password"
          label="New Password"
          type="password"
          error={Boolean(errors.password)}
          helperText={errors.password ? errors.password.message : ``}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={formState.isSubmitting}
        >
          Reset Password
        </Button>
      </form>
    </AuthContainer>
  );
}