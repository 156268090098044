import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

class Lock28Icon extends React.Component {
  render() {
    const { forwardedRef, ...props } = this.props;
    return <SvgIcon viewBox={'0 0 100 100'} {...props} ref={forwardedRef}>
      <path d="M92.5,5h-40C51.119,5 50,6.119 50,7.5V15c0,0.663 0.264,1.299 0.732,1.768l1.407,1.407l-2.011,6.034c-0.086,0.26 -0.116,0.526 -0.116,0.791H7.5C6.119,25 5,26.119 5,27.5S6.119,30 7.5,30h46.465l0.222,0.222l-9.103,34.133c-0.355,1.334 0.438,2.704 1.771,3.061c0.216,0.058 0.433,0.085 0.646,0.085c1.104,0 2.115,-0.738 2.414,-1.856l8.358,-31.343C58.746,34.754 59.365,35 60,35c0.265,0 0.531,-0.042 0.791,-0.128l6.034,-2.011l0.675,0.675V92.5c0,1.381 1.119,2.5 2.5,2.5h22.5c1.381,0 2.5,-1.119 2.5,-2.5v-85C95,6.119 93.881,5 92.5,5zM60.675,29.64l-5.314,-5.314l0.732,-2.197l6.779,6.779L60.675,29.64zM90,90H72.5V32.5c0,-0.663 -0.264,-1.299 -0.732,-1.768L55,13.965V10h35V90z"></path>
    </SvgIcon>
  }
}

export default React.forwardRef((props, ref) => <Lock28Icon {...props} forwardedRef={ref} />)