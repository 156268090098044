import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import RemoteOnlineIcon from '../assets/images/svgIcons/RemoteOnlineIcon'
import RemoteOfflineIcon from '../assets/images/svgIcons/RemoteOfflineIcon'

import SimpleMenu from './shared/SimpleMenu'
import LockIcon from './shared/LockIcon'

import { LockState } from '../utils/enums';
import { getPropertyValue, formatDate, formatLockName, businessHoursValidNow } from '../utils/tableData'
import * as Constants from '../utils/constants'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 15,
    borderRadius: 8
  },
  contentRoot: {
    paddingBottom: 16,
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  cardActions: {
    height: 50,
    paddingTop: 0
  },
  hubContainer: {
    borderBottom: "1px solid",
    borderBottomColor: "lightgray",
    "&:hover": {
      backgroundColor: "#eee"
    }
  },
  unlockButton: {
    // width: 80
  },
  offlineIcon: {
    opacity: .5
  },
  hubIcon: {
    marginTop: 2,
    marginRight: 10,
    fontSize: 32
  },
  unlockUntilButton: {
    marginLeft: 5
  },
  hubIconTooltip: {
    marginRight: 8
  },
  hubIconLoading: {
    marginRight: 8
  },
  hubTitleWrapper: {
    width: '100%',
    overflow: 'hidden',
    '& *': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  }
}));

const RemoteUnlockNode = ({lock, handleRemoteUnlock, remoteUnlockState, lockState}) => {
  const classes = useStyles();
  if (!lockState || !remoteUnlockState) {
    return <p>Loading...</p>
  }
  const lastStatusUpdatedAt = getPropertyValue(lock, "hub.statusUpdatedAt", null)
  const currentBusinessHours = businessHoursValidNow(lock)
  const unlockMenuItems = currentBusinessHours &&
  [
    {label: "Unlock", callback: () => handleRemoteUnlock(lock, true, false)},
    {label: `Unlock Until ${currentBusinessHours.endTime}`, callback: () => handleRemoteUnlock(lock, true, true)}
  ]

  const RemoteStateIcon = remoteUnlockState[lock.lockId] ? remoteUnlockState[lock.lockId].icon : CircularProgress

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.contentRoot}>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <LockIcon lock={lock} iconFontSize={28} circlePadding={8} circleDiameter={50} />
            </Box>
          </Box>
          <Box className={classes.hubTitleWrapper}>
            <Typography variant="h5">{formatLockName(lock)}</Typography>
            <span>{getPropertyValue(lock, "description")}</span>
          </Box>
        </Box>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Box display="flex" alignItems="center" width="100%">
          <Box width="50%" display="flex" alignItems="center" justifyContent="center">
          {/* <Box width="50%" display="flex" alignItems="center" justifyContent="flex-start" ml={2.5}> */}
            {lock.offline === true ? <Tooltip arrow title={`Last online: ${lastStatusUpdatedAt ? formatDate(lastStatusUpdatedAt) : `Never`}`}>
              <Box display="flex" alignItems="center"><RemoteOfflineIcon color="secondary" className={classes.hubIcon} />
               Offline</Box></Tooltip> :
              <React.Fragment>
                <RemoteOnlineIcon color="secondary" className={classes.hubIcon} />
               Online</React.Fragment>
            }
          </Box>
          <Box width="50%" display="flex" alignItems="center" justifyContent="center">
          {/* <Box width="50%" display="flex" alignItems="center" justifyContent="flex-end" mr={1}> */}
            {lock.offline === true ? <React.Fragment>
                  <Button
                    onClick={() => window.open(Constants.remoteLockSupportArticle, '_support')} 
                    className={classes.unlockButton}
                    size="medium" 
                    color="primary" 
                    variant="text">
                    Learn More
                  </Button>
                </React.Fragment> :
              remoteUnlockState[lock.lockId] ? <React.Fragment>
                  <RemoteStateIcon color="primary" size={24} fontSize="large" className={classes.hubIconLoading} /> {remoteUnlockState[lock.lockId].text}
                </React.Fragment> :
              lockState[lock.lockId] === LockState.LOCKED ? <React.Fragment>
                {!unlockMenuItems && <Button 
                  onClick={() => handleRemoteUnlock(lock, true, false)} 
                  className={classes.unlockButton}
                  size="medium"
                  color="primary"
                  variant="contained"
                  disabled={remoteUnlockState[lock.lockId]}>
                  Unlock
                </Button>}
                {unlockMenuItems && <SimpleMenu
                 label="Unlock" 
                 size="medium" 
                 variant="contained" 
                 classes={{button: classes.unlockButton}}>
                  {unlockMenuItems}
                </SimpleMenu>}
                </React.Fragment> : <React.Fragment>
                  <Button
                    onClick={() => handleRemoteUnlock(lock, false, false, true)} 
                    className={classes.unlockButton}
                    size="medium" 
                    color="primary" 
                    variant="contained" 
                    disabled={remoteUnlockState[lock.lockId]}>
                    Lock
                  </Button>
                </React.Fragment>
            }
          </Box>
        </Box>
        {/* <Box width="100%" py={.5}>
          <Box display="flex" alignItems="center" justifyContent="center" width="50%">
            hello
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" width="50%">
            {lock.offline === true ? null :
              remoteUnlockState[lock.lockId] ? <React.Fragment>
                  <RemoteStateIcon color="primary" size={24} fontSize="large" className={classes.hubIconLoading} /> {remoteUnlockState[lock.lockId].text}
                </React.Fragment> :
              lockState[lock.lockId] === LockState.LOCKED ? <React.Fragment>
                {!unlockMenuItems && <Button 
                  onClick={() => handleRemoteUnlock(lock, false)} 
                  className={classes.unlockButton}
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={remoteUnlockState[lock.lockId]}>
                  Unlock
                </Button>}
                {unlockMenuItems && <SimpleMenu label="Unlock" classes={{button: classes.unlockButton}}>
                  {unlockMenuItems}
                </SimpleMenu>}
                </React.Fragment> : <React.Fragment>
                  <Button
                    onClick={() => handleRemoteUnlock(lock, false)} 
                    className={classes.unlockButton}
                    size="small" 
                    color="primary" 
                    variant="contained" 
                    disabled={remoteUnlockState[lock.lockId]}>
                    Lock
                  </Button>
                </React.Fragment>
            }
          </Box>
        </Box> */}
      </CardActions>
    </Card>
  );
}

export default RemoteUnlockNode;
