import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import { useForm } from "react-hook-form";

import AuthContainer from './AuthContainer'

export default function Mfa({message,type,cognitoUser,newPassword,onConfirm,onNotAuthorized}) {
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit, errors, formState } = useForm();
  
  const confirm = async(values) => {
    await Auth.confirmSignIn(cognitoUser, values.code.trim(), type)
      .then((user) => {
        onConfirm(user, true, newPassword)
      })
      .catch(({ message, code }) => {
        if (code === "NotAuthorizedException") {
          onNotAuthorized()
        }
        else {
          enqueueSnackbar(message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          })
        }
      })
  }

  return (
    <AuthContainer>
      <form onSubmit={handleSubmit(confirm)} noValidate>
        {message && <Alert severity="info">{message}</Alert>}
        <TextField
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({ 
            validate: value => value.trim().length === 6 || 'Code must be six characters.'
          })}
          label="Code"
          name="code"
          error={Boolean(errors.code)}
          helperText={errors.code ? errors.code.message : ``}
          autoFocus
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={formState.isSubmitting}
        >
          Submit
        </Button>
      </form>
    </AuthContainer>
  );
}