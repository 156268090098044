import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import * as Constants from '../utils/constants'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    overflow: 'auto',
    maxHeight: '50vh',
    '& p': {
      marginTop: 0,
      // textAlign: "center"
    },
  },
  title: {
    flexGrow: 1,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center"
  },
  buttonWrapper: {
    '& button': {
      marginLeft: 10,
      marginRight: 10
    },
  }
}));

export default function RemoteUnlockLockDialog({onClose, lock, loading, open, ...props}) {
  const classes = useStyles();
  const handleCancel = () => {
    onClose();
  }

  const handleOk = (resumeSchedule) => {
    onClose(true, Boolean(resumeSchedule));
  }

  return lock && lock.lockId && (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth={"xs"}
      fullWidth={false}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Box className={classes.titleWrapper}>
          <Typography variant="h6" className={classes.title}>
            Remote Lock Confirmation
          </Typography>
          <IconButton style={{ marginRight: -15 }} onClick={handleCancel}>
            <ClearIcon color="primary" />
          </IconButton>
        </Box>
        {/* Remote Lock Confirmation */}
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Box>
          <p>Lock will only secure the entryway if the door is physically closed. Please make sure the door is securely shut.</p>
          <p>Lock will only suspend the current session of Business Hours. Business Hours will unlock the door at the next scheduled time. If you want to keep your door locked at all times, please <Link
              href={Constants.disableBusinessHoursSupportArticle}
              target="_support">
                disable Business Hours
            </Link>.</p>
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={20} />}
        <Button onClick={() => handleOk(false)} color="primary" variant="contained" disabled={loading}>
          Lock
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// RemoteUnlockDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
// };